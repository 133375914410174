import {
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_FULFILLED,
  FETCH_ACCOUNTS_REJECTED,
  SWITCH_ACCOUNT_START,
  SWITCH_ACCOUNT_FULFILLED,
  SWITCH_ACCOUNT_REJECTED,
} from './actionTypes';

const initialState = {
  isAccountLoading: false,
  isAccountSwitched: false,
  isAccountSwitching: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_START: {
      return {
        ...state,
        isAccountsLoading: true,
      };
    }
    case FETCH_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        isAccountsLoading: false,
      };
    }
    case FETCH_ACCOUNTS_REJECTED: {
      return {
        ...state,
        isAccountLoading: false,
      };
    }
    case SWITCH_ACCOUNT_START: {
      return {
        ...state,
        isAccountSwitching: true,
      };
    }
    case SWITCH_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isAccountSwitching: false,
        isAccountSwitched: true,
      };
    }
    case SWITCH_ACCOUNT_REJECTED: {
      return {
        ...state,
        isAccountLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
