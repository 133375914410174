import update from 'immutability-helper';

import {
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_FULFILLED,
  FETCH_CURRENT_USER_REJECTED,
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_REJECTED,
  OPEN_GLOBAL_MODAL,
  CLOSE_GLOBAL_MODAL,
  SET_SCREENS,
  TOGGLE_SCREENS,
  TOGGLE_SUPERSETS,
  TOGGLE_DARK_THEME,
  TOGGLE_WEIGHT_VALUE,
  SELECT_GROUP,
} from './actionTypes';

const initialState = {
  activeScreens: 4,
  currentUser: {},
  fetchedUsers: false,
  fetchedGroups: false,
  fetchedCurrentUser: false,
  isGlobalModalOpen: false,
  isDarkThemeEnabled: false,
  isWeightValueEnabled: true,
  groupFilter: '',
  users: {},
  userGroups: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_START: {
      return {
        ...state,
      };
    }
    case FETCH_CURRENT_USER_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload.result,
        fetchedCurrentUser: true,
        wrvSupersets: true,
        isWeightValueEnabled: action.payload.result.settings.wrv_placeholders_enabled === 0 ? true : false,
      };
    }
    case FETCH_CURRENT_USER_REJECTED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case FETCH_USERS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USERS_FULFILLED: {
      return {
        ...state,
        fetchedUsers: true,
        users: action.payload,
      };
    }
    case FETCH_USERS_REJECTED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case FETCH_USER_GROUPS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        fetchedGroups: true,
        userGroups: action.payload,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case TOGGLE_SCREENS: {
      return {
        ...state,
        activeScreens:
          state.activeScreens === 4
            ? state.activeScreens - 3
            : (
              state.activeScreens === 2 
                ? state.activeScreens + 2
                : state.activeScreens + 1
            )
      };
    }
    case SET_SCREENS: {
      return update(state, {
        activeScreens: {
          $set: action.payload,
        },
      });
    }
    case SELECT_GROUP: {
      return {
        ...state,
        groupFilter: action.payload,
      };
    }
    case OPEN_GLOBAL_MODAL: {
      return {
        ...state,
        isGlobalModalOpen: true,
      };
    }
    case CLOSE_GLOBAL_MODAL: {
      return {
        ...state,
        isGlobalModalOpen: false,
      };
    }
    case TOGGLE_SUPERSETS: {
      return update(state, {
        currentUser: {
          settings: {
            wrv_supersets_enabled: {
              $set: state.currentUser.settings.wrv_supersets_enabled === 1 ? 0 : 1,
            },
          },
        },
      });
    }
    case TOGGLE_DARK_THEME: {
      return update(state, {
        isDarkThemeEnabled: {
          $set: !state.isDarkThemeEnabled && true,
        },
      });
    }
    case TOGGLE_WEIGHT_VALUE: {
      return update(state, {
        isWeightValueEnabled: {
          $set: state.isWeightValueEnabled ? false : true,
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
