import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createRootReducer from './rootReducer';
// import Worker from 'worker-loader!../../../dist/worker';
import history from '../services/History/history';
// import { loadState } from '../services/LocalStorage';
// const worker = window.Worker ? new Worker() : null;

// const persistedState = loadState();
const middlewares = [
  routerMiddleware(history),
  thunk,
];

if (process.env.ENVIRONMENT === 'PRODUCTION') {
  const LogRocket = require('logrocket');
  middlewares.push(LogRocket.reduxMiddleware());
} else {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger());
}

const middleware = composeWithDevTools(
  applyMiddleware(...middlewares),
);

export const store = createStore(
  createRootReducer(history),
  // persistedState,
  middleware,
);

export const persistor = persistStore(store);
