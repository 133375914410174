import axios from 'axios';

const CONTENT_TYPE = 'application/json';
const { CLIENT_ID } = process.env;
const { CLIENT_SECRET } = process.env;
const { API_URL } = process.env;

axios.defaults.baseURL = API_URL;
// axios.defaults.baseURL = '/api/2.0/';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = CONTENT_TYPE;
axios.defaults.headers.common['client-id'] = CLIENT_ID;
axios.defaults.headers.common['client-secret'] = CLIENT_SECRET;

// Set Axios common headers.
export const axiosLogin = axios.create({
  baseURL: `https://${process.env.ENVIRONMENT === 'PRODUCTION' ? '' : 'staging.'}api.teambuildr.com/`,
  headers: {
    common: {
      Platform: 'Webapp',
      AppVersion: '1.1.0',
    },
  },
});

axiosLogin.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axiosLogin.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const axiosAuthed = axios.create({
  baseURL: `https://${process.env.ENVIRONMENT === 'PRODUCTION' ? '' : 'staging.'}api.teambuildr.com/`,
  headers: {
    common: {
      Platform: 'Webapp',
      AppVersion: '1.1.0',
    },
  },
});
