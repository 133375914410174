import React, {
  Fragment, useState, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import { animated, useTransition, useSpring } from 'react-spring';
import { push } from 'connected-react-router';

import iconSet from '../../images/teambuildr-selection.json';
import DropdownList, { DropdownListContainer, DropdownItem } from '../DropdownList/DropdownList';
import HeaderModals from '../HeaderModals';
import Button from '../Button/Button';
import Text from '../Text/Text';
import Badge from '../Badge/Badge';

// Styled Components
const HeaderContainer = styled('nav')`
  display: flex;
  align-items: center;
  position: fixed;
  height: 49px;
  width: 100%;
  background-color: ${props => props.theme.colorPrimary};
  box-shadow: 1px;
  z-index: 99;

  .dropdown-list {
    padding: 0px;
    height: auto;
    margin-left: 0px;
    transition: all 250ms ease-in-out;
  }

  @media screen and (max-width: 767px) {
    .mobile-dropdown {
      a {
        font-size: 14px;
        padding: 10px 20px;
      }
    }
    
    .mobile-subdropdown {
      a {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 15px;
      }
    }
  }
`;

const NavLink = styled('a')`
  display: flex;
  position: relative;
  height: 49px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 23px;
  text-decoration: none;
  color: ${props => (props.theme.isPrimaryDarkTheme ? props.theme.colors.text : 'white')};
  cursor: pointer;

  .dropdown-link {
    color: ${props => (props.theme.isAccentDarkTheme ? props.theme.colors.text : 'white')}
  }

  .dropdown-link:hover {
    color: 'white';
  }
  
  &.nav-links:hover {
    background-color: ${props => props.theme.colorSecondary};
    color: ${props => (props.theme.isSecondaryDarkTheme ? '#444444' : 'white')};
    
    span {  
      border-color: #444444 transparent transparent transparent;
    }
    svg {
      path {
        fill: ${props => (props.theme.isDarkTheme ? 'white' : props.theme.colors.text)}
      }
    }
  }

  img {
    width: 32px;
    cursor: pointer;
    object-fit: contain;
    &.img-circle {
      border-radius: 100%;
    }
  }

  &.image-link {
    padding: 9px!important;
  }

  svg {
    path {
      fill: ${props => (props.theme.isDarkTheme ? props.theme.colors.text : 'white')};
      transition: all 200ms ease-in-out;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 10px;
  }

  @media screen and (max-width: 767px) {
    .dropdown-list {
      width: 100%;
    }
  }
`;

const SubArrow = styled('span')`
  position: absolute;
  height: 0;
  margin-top: 16px;

  background: transparent;
  border-width: 5px;
  border-style: solid dashed dashed dashed;
  border-color: ${props => (
    props.theme.isDarkTheme ? '#444444 transparent transparent transparent' : '#ffffff transparent transparent transparent'
  )};
  border-radius: 0;
`;

const RightNav = styled('div')`
  display: flex;
  position: absolute;
  flex-direction: row;
  align-self: flex-end;
  right: 0px;
`;

const MainNav = styled('div')`
  display: flex;
  flex-direction: row;
`;

const LeftNav = styled('div')`
`;

const HamburgerIcon = styled('div')`
  width: 22px;
  height: 17px;
`;

const HamburgerLine = styled('div')`
  width: 100%;
  height: 2px;
  background-color: white;
  margin-bottom: 5px;
`;

const BillingNav = styled('div')`
  display: flex;
  align-items: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
  p {
    margin-right: 10px;
  }
`;

// Object styles for transitions
const defaultDropdownStyles = {
  position: 'absolute',
  height: 'auto',
  width: 'auto',
  top: '49px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const mainDropdownStyles = {
  left: '0px',
};

const accountDropdownStyles = {
  right: '0px',
};

const mobileDropdownStyles = {
  position: 'fixed',
  width: '100%',
  left: '0px',
};

const mobileSubDropdownStyles = {
  position: 'relative',
  width: '100%',
  height: '0',
  top: '0px',
  left: '0px',
};

// Main Component
const AppHeader = ({
  data,
  fetchUserAccounts,
  fetchUnreadMessages,
  handleLogOut,
  location,
  unreadMessages,
}) => {
  const [activeDropdown, setActiveDropdown] = useState('');
  const [activeSubDropdown, setActiveSubDropdown] = useState('');
  const [isLighthouseOpen, setLighthouseOpen] = useState(false);
  const [activeModal, setActiveModal] = useState('');

  const mobileDropdownRef = useRef(null);

  const theme = useTheme();

  const dispatch = useDispatch();

  // Dropdown transition animations.
  const workoutDropdownTransition = useTransition(activeDropdown === 'workout', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const coachDropdownTransition = useTransition(activeDropdown === 'coach', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const accountDropdownTransition = useTransition(activeDropdown === 'account', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const mobileDropdownTransition = useTransition(activeDropdown === 'mobile', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const mobileWorkoutDropdownTransition = useSpring({ height: activeSubDropdown === 'mobile-workout' ? 196 : 0 });
  const mobileCoachDropdownTransition = useSpring({ height: activeSubDropdown === 'mobile-coach' ? 196 : 0 });

  // Setup support help category based on pathname location.
  const pathname = location && location.replace(/\//g, '');
  let helpCategory = '';

  switch (pathname) {
    case 'feed':
      helpCategory = 'tag:page-feed';
      break;
    case 'calendar':
      helpCategory = 'tag:page-calendar';
      break;
    case 'display':
      helpCategory = 'tag:page-wrv';
      break;
    case 'workouts':
      helpCategory = 'tag:page-entry';
      break;
    case 'maxes':
      helpCategory = 'tag:page-maxes';
      break;
    case 'journal':
      helpCategory = 'tag:page-journal';
      break;
    case 'leaderboard':
      helpCategory = 'tag:page-leaderboard';
      break;
    case 'evaluation':
      helpCategory = 'tag:page-evaluations';
      break;
    case 'manage_users':
      helpCategory = 'tag:page-manageusers';
      break;
    case 'manage_calendars':
      helpCategory = 'tag:page-managecalendars';
      break;
    case 'exercises':
      helpCategory = 'tag:page-exercises';
      break;
    case 'goals':
      helpCategory = 'tag:page-goals';
      break;
    case 'documents':
      helpCategory = 'tag:page-docs';
      break;
    case 'packet':
    case 'printables':
    case 'weightroom-sheet':
      helpCategory = 'tag:page-packet';
      break;
    case 'attendance':
      helpCategory = 'tag:page-attendance';
      break;
    case 'reporting':
      helpCategory = 'tag:page-reporting';
      break;
    case 'messages':
      helpCategory = 'tag:page-messages';
      break;
    case 'dashboard':
      helpCategory = 'tag:page-dashboard';
      break;
    case 'billing':
      helpCategory = 'tag:page-billing';
      break;
    case 'settings':
      helpCategory = 'tag:page-settings';
      break;
    default:
      helpCategory = '';
  }

  const userSettings = data.userSettings !== undefined && data.userSettings;
  const userSkin = data.userSkin !== undefined && data.userSkin;

  // Dropdown Data
  const CoachToolsDropdown = [
    {
      id: 0,
      name: 'Manage Users',
      isVisible: userSettings.manageUsers,
      href: 'https://app.teambuildr.com/manage_users',
    },
    {
      id: 1,
      name: 'Manage Calendars',
      href: 'https://app.teambuildr.com/manage_calendars',
    },
    {
      id: 2,
      name: 'Exercises',
      isVisible: userSettings.exercises,
      href: 'https://app.teambuildr.com/exercises',
    },
    {
      id: 3,
      name: 'Goals',
      href: 'https://app.teambuildr.com/goals',
    },
    {
      id: 4,
      name: 'Documents & Links',
      href: '/documents',
      isReactLink: true,
    },
    {
      id: 5,
      name: 'Packets & Sheets',
      href: 'https://app.teambuildr.com/printables',
    },
    {
      id: 6,
      name: 'Attendance',
      isVisible: userSettings.attendance,
      href: 'https://app.teambuildr.com/attendance',
    },
    {
      id: 7,
      name: 'Reporting',
      isVisible: userSettings.reporting,
      href: 'https://app.teambuildr.com/reporting',
    },
  ];

  const WorkoutToolsDropdown = [
    {
      id: 0,
      name: 'Weightroom View',
      href: '/weightroom-view',
      isReactLink: true,
    },
    { id: 1, name: 'Whiteboard', href: 'https://app.teambuildr.com/whiteboard' },
    { id: 2, name: 'Workout Entry', href: 'https://app.teambuildr.com/workouts' },
    { id: 3, name: 'Maxes/Pr', href: 'https://app.teambuildr.com/maxes' },
    { id: 4, name: 'Journal', href: 'https://app.teambuildr.com/journal' },
    {
      id: 5,
      name: 'Leaderboard',
      isVisible: userSettings.leaderboard,
      href: '/leaderboard',
      isReactLink: true,
    },
    { id: 6, name: 'Evaluation', href: 'https://app.teambuildr.com/evaluation' },
  ];

  const MobileCoachNav = [
    {
      id: 0,
      name: 'Feed',
      isVisible: userSettings.feed,
      href: 'htttps://app.teambuildr.com/feed',
    },
    { id: 1, name: 'Calendar', href: 'https://app.teambuildr.com/calendar' },
    { id: 2, name: 'Workout Tools', href: '#' },
    { id: 3, name: 'Coach Tools', href: '#' },
    {
      id: 4,
      name: 'Messages',
      isVisible: userSettings.messaging,
      href: 'https://app.teambuildr.com/messages',
    },
  ];

  const MobileAthleteNav = [
    {
      id: 0,
      name: 'Feed',
      isVisible: userSettings.feed,
      href: 'https://app.teambuildr.com/feed',
    },
    { id: 1, name: 'Workouts', href: 'https://app.teambuildr.com/calendar' },
    { id: 2, name: 'Maxes/PRS', href: 'https://app.teambuildr.com/maxes' },
    { id: 3, name: 'Journal', href: 'https://app.teambuildr.com/journal' },
    {
      id: 4,
      name: 'Leaderboard',
      isVisible: userSettings.leaderboard,
      href: 'https://app.teambuildr.com/messages',
    },
    { id: 5, name: 'Docs & Links', href: 'https://app.teambuildr.com/documents' },
  ];

  const AccountDropdown = [
    {
      id: 0,
      icon: 'key',
      href: 'https://app.teambuildr.com/panel',
      name: 'Admin Panel',
      spacer: true,
    },
    {
      id: 1,
      name: 'Dashboard',
      icon: 'home',
      href: 'https://app.teambuildr.com/dashboard',
    },
    {
      id: 2,
      name: 'Settings',
      icon: 'settings',
      href: 'https://app.teambuildr.com/settings',
    },
    {
      id: 3,
      name: 'Billing',
      icon: 'dollar',
      href: 'https://app.teambuildr.com/billing',
    },
    {
      id: 4,
      name: 'Subscription',
      icon: 'credit-card',
      href: 'https://app.teambuildr.com/settings/subscription',
    },
    {
      id: 5,
      name: 'Education Center',
      icon: 'headset',
      href: 'https://app.teambuildr.com/education',
    },
    {
      id: 6,
      name: 'Support',
      icon: 'question-mark-circle',
      href: '#',
      modal: true,
    },
    {
      id: 7,
      name: 'Switch Accounts',
      icon: 'swap',
      spacer: true,
      href: '#',
      modal: true,
    },
    {
      id: 8,
      name: 'Log Out',
      icon: 'power',
      isLogOut: true,
      href: '#',
    },
  ];

  // Initiate Lighthouse Support Modal
  useLayoutEffect(() => {
    window.hdlh = {
      widget_key: '1n5kmv92ij0mb7twdi57',
      logo: false,
      primary_color: theme.colorPrimary,
      suggestions: helpCategory !== '' ? [`${helpCategory}`] : null,
      brand: 'Quick Tips',
      color_mode: 'auto',
      disable_contact_button: true,
      disable_authorship: true,
    };

    (function (h, d) { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `${h}?t=${new Date().getTime()}`; d.head.appendChild(s); }('https://lighthouse.helpdocs.io/load', document));
  }, [location]);

  // Fetch Unread Messages
  useLayoutEffect(() => {
    fetchUnreadMessages();
  }, [location]);

  // Click handle functions
  const handleSupportClick = (e) => {
    e.preventDefault();

    if (!isLighthouseOpen) {
      setLighthouseOpen(true);
      window.Lighthouse.show();
    } else {
      setLighthouseOpen(false);
      window.Lighthouse.hide();
    }
  };

  const handleActiveModal = (modal, e) => {
    e.preventDefault();
    setActiveModal(modal);
    setActiveDropdown('');
    if (modal === 'Switch Accounts') {
      fetchUserAccounts();
    }
  };

  const handleActiveDropdown = (dropdown, e) => {
    e.preventDefault();
    setActiveDropdown(dropdown);
  };

  const closeModal = () => {
    setActiveModal('');
  };

  const handleMobileClick = (e) => {
    e.preventDefault();
    activeDropdown === 'mobile'
      ? setActiveDropdown('')
      : setActiveDropdown('mobile');
  };

  const handleSubDropdownClick = (dropdown, e) => {
    e.preventDefault();
    setActiveSubDropdown(activeSubDropdown === dropdown ? '' : dropdown, e);
  };

  const handleReactLink = (path, e) => {
    e.preventDefault();
    dispatch(push(path));
  };

  const handleLogOutClick = (e) => {
    e.preventDefault();
    handleLogOut();
  };

  // Filter dropdown menu items based on account status.
  const {
    id,
    admin,
    organizationStripe,
    organizationSubscriptionStatus,
    organizationSubscriptionDaysRemaining,
    pic,
  } = data;

  const isAccountTBAdmin = process.env.TB_ADMINS.split(',').includes(`${id}`);
  const isAccountStripe = organizationStripe !== '';
  const isAccountAdmin = admin === 1;

  const filteredTBAdminAccountDropdown = AccountDropdown.filter(link => (
    (!isAccountTBAdmin ? link.id !== 0 : link.id < 20)
  ));
  const filteredAdminAccountDropdown = filteredTBAdminAccountDropdown.filter(link => (
    (!isAccountAdmin ? (link.id !== 3 && link.id !== 5 && link.id !== 7) : link.id !== 4)
  ));
  const filteredStripeAccountDropdown = filteredAdminAccountDropdown.filter(link => (
    (!isAccountStripe ? link.id !== 4 : link.id < 20)
  ));

  // const organizationSubscriptionStatus = 'IN_TRIAL';
  // const organizationSubscriptionStatus = 'TRIAL_ENDED';
  // const organizationSubscriptionStatus = 'ACTIVE';
  // const organizationSubscriptionStatus = 'EXPIRED';

  // const organizationSubscriptionDaysRemaining = 45;

  const isInTrial = organizationSubscriptionStatus === 'IN_TRIAL';
  const isTrialExpired = organizationSubscriptionStatus === 'TRIAL_ENDED';
  const isActive = organizationSubscriptionStatus === 'ACTIVE';
  const isExpired = organizationSubscriptionStatus === 'EXPIRED';

  return (
    <HeaderContainer>
      <LeftNav>
        <NavLink className='image-link'>
          <img
            src={userSkin.logo}
            alt=''
          />
        </NavLink>
      </LeftNav>

      <MainNav className='d-none d-md-flex'>
        {userSettings.feed === 1 && (
          <NavLink href='https://app.teambuildr.com/feed' className='nav-links'>
            Feed
          </NavLink>
        )}
        {isAccountAdmin ? (
          // Show Links for Admin Users
          <Fragment>
            <NavLink href='https://app.teambuildr.com/calendar' className='nav-links'>
              Calendar
            </NavLink>
            <NavLink
              href='#'
              className='nav-links'
              onMouseEnter={e => handleActiveDropdown('workout', e)}
              onMouseLeave={e => handleActiveDropdown('', e)}
              onClick={e => handleActiveDropdown(activeDropdown !== 'workout' ? 'workout' : '', e)}
            >
              Workout Tools
              <SubArrow />

              {/* Workout Dropdown Menu */}
              {workoutDropdownTransition.map(({ item, props }) => item && (
                <animated.div style={{ ...defaultDropdownStyles, ...mainDropdownStyles, ...props }}>
                  <DropdownList
                    componentClass='dropdown-list'
                    customBackground={theme.colorPrimary}
                    data={WorkoutToolsDropdown}
                    handleReactLink={handleReactLink}
                    small
                    smallPadding
                  />
                </animated.div>
              ))}
            </NavLink>
            <NavLink
              href='#'
              className='nav-links'
              onMouseEnter={e => handleActiveDropdown('coach', e)}
              onMouseLeave={e => handleActiveDropdown('', e)}
              onClick={e => handleActiveDropdown(activeDropdown !== 'coach' ? 'coach' : '', e)}
            >
              Coach Tools
              <SubArrow />

              {/* Coach Dropdown Menu */}
              {coachDropdownTransition.map(({ item, props }) => item && (
                <animated.div style={{ ...defaultDropdownStyles, ...mainDropdownStyles, ...props }}>
                  <DropdownList
                    componentClass='dropdown-list'
                    customBackground={theme.colorPrimary}
                    data={CoachToolsDropdown}
                    small
                    smallPadding
                    handleReactLink={handleReactLink}
                  />
                </animated.div>
              ))}
            </NavLink>
          </Fragment>
        ) : (
          // Show links for athletes
          <Fragment>
            <NavLink href='https://app.teambuildr.com/workouts' onClick={(e) => { e.preventDefault(); dispatch(push('/login')); }} className='nav-links'>
              Workouts
            </NavLink>
            <NavLink href='https://app.teambuildr.com/maxes' className='nav-links'>
              Maxes/PR
            </NavLink>
            <NavLink href='https://app.teambuildr.com/journal' className='nav-links'>
              Journal
            </NavLink>
            {userSettings.leaderboard === 1 && (
              <NavLink onClick={(e) => { e.preventDefault(); handleReactLink('/leaderboard'); }} href='https://app.teambuildr.com/leaderboard' className='nav-links'>
                Leaderboard
              </NavLink>
            )}
            <NavLink href='https://app.teambuildr.com/documents' onClick={(e) => { e.preventDefault(); handleReactLink('/documents'); }} className='nav-links'>
              Docs & Links
            </NavLink>
          </Fragment>
        )}
      </MainNav>

      <RightNav>
        {/* Billing Nav */}
        {isAccountAdmin && (
          <Fragment>
            <BillingNav>
              {(isInTrial || isTrialExpired) && (
                <Fragment>
                  {!isTrialExpired && (
                    <Text
                      color={theme.isPrimaryDarkTheme ? 'white' : '#444444'}
                      fontSize={theme.textFontSizes[0]}
                    >
                      {organizationSubscriptionDaysRemaining}
                      &nbsp;days left
                    </Text>
                  )}

                  <Button
                    cta='Upgrade'
                    customColor='#ff6600'
                    onClick={() => console.log('Upgrade')}
                    noBorder
                    primary
                    rounded
                  />
                  <Button
                    className='d-none d-sm-flex'
                    cta='Schedule Demo'
                    customColor='transparent'
                    onClick={() => console.log('Upgrade')}
                    primary
                    rounded
                  />
                </Fragment>
              )}
              {((isActive && organizationSubscriptionDaysRemaining <= 30) || (isExpired)) && (
                <Fragment>
                  {!isExpired && (
                    <Text
                      color={theme.isPrimaryDarkTheme ? 'white' : '#444444'}
                      fontSize={theme.textFontSizes[0]}
                    >
                      {organizationSubscriptionDaysRemaining}
                      &nbsp;days left
                    </Text>
                  )}
                  <Button
                    cta='Renew'
                    customColor='#c0392b'
                    onClick={() => console.log('Renew')}
                    noBorder
                    primary
                    rounded
                  />
                </Fragment>
              )}
            </BillingNav>
            <NavLink
              id='help-link'
              onClick={handleSupportClick}
              href='#'
              className='nav-links d-none d-md-flex'
            >
              <IcomoonReact
                iconSet={iconSet}
                // color={isPrimaryDarkTheme ? '#444444' : '#FFFFFF'}
                size={18}
                icon='lightning-bolt'
                className='align-self-end'
              />
            </NavLink>
          </Fragment>
        )}
        {userSettings.messaging === 1 && (
          <NavLink href='https://app.teambuildr.com/messages' className='nav-links d-none d-md-flex'>
            {unreadMessages > 0 && (
              <Badge
                color='red'
                small
                topRight
              />
            )}
            <IcomoonReact
              iconSet={iconSet}
              // color={isPrimaryDarkTheme ? '#444444' : '#FFFFFF'}
              size={18}
              icon='envelope-closed'
              className='align-self-end'
            />
          </NavLink>
        )}

        {/* Mobile Navigation */}
        <NavLink
          href='#'
          className='d-flex d-md-none'
          onClick={e => handleMobileClick(e)}
        >
          <HamburgerIcon>
            <HamburgerLine />
            <HamburgerLine />
            <HamburgerLine />
          </HamburgerIcon>
        </NavLink>

        {/* Mobile Dropdown Menu */}
        {mobileDropdownTransition.map(({ item, props }) => item && (
          <animated.div
            ref={mobileDropdownRef}
            style={{ ...defaultDropdownStyles, ...mobileDropdownStyles, ...props }}
          >
            <DropdownListContainer
              className='dropdown-list mobile-dropdown'
              customBackground={theme.colorPrimary}
            >
              {(isAccountAdmin ? MobileCoachNav : MobileAthleteNav).map(dropItem => (
                <Fragment>
                  {isAccountAdmin ? (
                    <DropdownItem
                      small
                      smallPadding
                      href={dropItem.href}
                      onClick={e => (
                        (dropItem.id === 2 || dropItem.id === 3)
                          && (handleSubDropdownClick(dropItem.id === 2 ? 'mobile-workout' : 'mobile-coach', e))
                      )}
                    >
                      {dropItem.name}
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      small
                      smallPadding
                      href={dropItem.href}
                    >
                      {dropItem.name}
                    </DropdownItem>
                  )}

                  {/* Mobile Sub-Dropdown */}
                  {(dropItem.id === 2 || dropItem.id === 3) && (
                    <animated.div
                      ref={mobileDropdownRef}
                      style={
                        dropItem.id === 2
                          ? {
                            ...defaultDropdownStyles,
                            ...mobileSubDropdownStyles,
                            ...mobileWorkoutDropdownTransition,
                          }
                          : {
                            ...defaultDropdownStyles,
                            ...mobileSubDropdownStyles,
                            ...mobileCoachDropdownTransition,
                          }
                      }
                    >
                      <DropdownList
                        componentClass='dropdown-list mobile-subdropdown'
                        customBackground={theme.colors.background[0]}
                        customHover='#000000'
                        data={dropItem.id === 2 ? WorkoutToolsDropdown : CoachToolsDropdown}
                        handleActiveModal={handleActiveModal}
                        light
                        extraSmallPadding
                      />
                    </animated.div>
                  )}
                </Fragment>
              ))}
            </DropdownListContainer>
          </animated.div>
        ))}

        <NavLink
          href='#'
          className='image-link'
          onMouseEnter={() => setActiveDropdown('account')}
          onMouseLeave={() => setActiveDropdown('')}
          onClick={() => (setActiveDropdown(activeDropdown !== 'account' ? 'account' : ''))}
        >
          <img
            src={pic}
            alt=''
            className='img-circle'
          />

          {/* Account Dropdown Menu */}
          {accountDropdownTransition.map(({ item, props }) => item && (
            <animated.div style={{ ...defaultDropdownStyles, ...accountDropdownStyles, ...props }}>
              <DropdownList
                componentClass='dropdown-list'
                customBackground={theme.colors.background[0]}
                customHover='#000000'
                data={filteredStripeAccountDropdown}
                handleActiveModal={handleActiveModal}
                handleLogOut={handleLogOutClick}
                light
                icons
                small
                extraSmallPadding
              />
            </animated.div>
          ))}

        </NavLink>
      </RightNav>

      <HeaderModals
        activeModal={activeModal}
        closeModal={closeModal}
        isModalOpen={activeModal !== ''}
        path={location}
        organizationId={data.organizationId}
      />
    </HeaderContainer>
  );
};

AppHeader.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  fetchUserAccounts: PropTypes.func.isRequired,
  fetchUnreadMessages: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number.isRequired,
};
export default AppHeader;
