export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_FULFILLED = 'FETCH_DOCUMENTS_FULFILLED';
export const FETCH_DOCUMENTS_REJECTED = 'FETCH_DOCUMENTS_REJECTED';

export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_FULFILLED = 'FETCH_DOCUMENT_FULFILLED';
export const FETCH_DOCUMENT_REJECTED = 'FETCH_DOCUMENT_REJECTED';

export const FETCH_FOLDER_START = 'FETCH_FOLDER_START';
export const FETCH_FOLDER_FULFILLED = 'FETCH_FOLDER_FULFILLED';
export const FETCH_FOLDER_REJECTED = 'FETCH_FOLDER_REJECTED';

export const FETCH_SEARCH_DOCUMENTS_START = 'FETCH_SEARCH_DOCUMENTS_START';
export const FETCH_SEARCH_DOCUMENTS_FULFILLED = 'FETCH_SEARCH_DOCUMENTS_FULFILLED';
export const FETCH_SEARCH_DOCUMENTS_REJECTED = 'FETCH_SEARCH_DOCUMENTS_REJECTED';

export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_FULFILLED = 'CREATE_DOCUMENT_FULFILLED';
export const CREATE_DOCUMENT_REJECTED = 'CREATE_DOCUMENT_REJECTED';

export const UPLOAD_DOCUMENT_START = 'UPLOAD_DOCUMENT_START';
export const UPLOAD_DOCUMENT_FULFILLED = 'UPLOAD_DOCUMENT_FULFILLED';
export const UPLOAD_DOCUMENT_REJECTED = 'UPLOAD_DOCUMENT_REJECTED';

export const DELETE_DOCUMENTS_START = 'DELETE_DOCUMENTS_START';
export const DELETE_DOCUMENTS_FULFILLED = 'DELETE_DOCUMENTS_FULFILLED';
export const DELETE_DOCUMENTS_REJECTED = 'DELETE_DOCUMENTS_REJECTED';

export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_FULFILLED = 'UPDATE_DOCUMENT_FULFILLED';
export const UPDATE_DOCUMENT_REJECTED = 'UPDATE_DOCUMENT_REJECTED';

export const FETCH_USER_GROUPS_START = 'FETCH_USER_GROUPS_START';
export const FETCH_USER_GROUPS_FULFILLED = 'FETCH_USER_GROUPS_FULFILLED';
export const FETCH_USER_GROUPS_REJECTED = 'FETCH_USER_GROUPS_REJECTED';

export const FETCH_FILE_START = 'FETCH_FILE_START';
export const FETCH_FILE_FULFILLED = 'FETCH_FILE_FULFILLED';
export const FETCH_FILE_REJECTED = 'FETCH_FILE_REJECTED';

export const FETCH_FOLDERS_START = 'FETCH_FOLDERS_START';
export const FETCH_FOLDERS_FULFILLED = 'FETCH_FOLDERS_FULFILLED';
export const FETCH_FOLDERS_REJECTED = 'FETCH_FOLDERS_REJECTED';

export const FETCH_FOLDER_SEARCH_DOCUMENTS_START = 'FETCH_FOLDER_SEARCH_DOCUMENTS_START';
export const FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED = 'FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED';
export const FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED = 'FETCH_SEARCH_DOCUMENTS_REJECTED';
