import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import AthleteAvatar from './AthleteAvatar';
import { ColorPrimary, TransitionGlobal } from '../../../../shared/GlobalStyles';
import AthleteName from './AthleteName';

const AthleteContainer = styled('div')`
  display: flex;
  padding: 10px 30px;
  /* transition: ${TransitionGlobal}; */
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colorAccent};
    h3 {
      color: ${props => (props.theme.isAccentDarkTheme ? ColorPrimary : 'white')};
    }
  }
  img {
    height: 30px;
    width: 30px;
  }

  h3 {
    /* transition: ${TransitionGlobal}; */
    color: ${props => props.theme.textColor};
  }

  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
`;

class Athlete extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { handleActive } = this.props;
    handleActive({
      name: e.currentTarget.dataset.name,
      id: parseInt(e.currentTarget.dataset.id, 10),
      avatar: e.currentTarget.dataset.avatar,
      pinEnabled: e.currentTarget.dataset.pinenabled,
    });
  }

  render() {
    const {
      avatar,
      name,
      id,
      listStyle,
      pinEnabled,
    } = this.props;

    return (
      <AthleteContainer
        onClick={this.handleClick}
        data-name={name}
        data-id={id}
        data-avatar={avatar}
        data-pinenabled={pinEnabled}
        style={listStyle}
      >
        <AthleteAvatar
          avatar={avatar}
        />
        <AthleteName
          name={name}
        />
      </AthleteContainer>
    );
  }
}

Athlete.propTypes = {
  avatar: PropTypes.string.isRequired,
  handleActive: PropTypes.instanceOf(Function).isRequired,
  id: PropTypes.number.isRequired,
  listStyle: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  pinEnabled: PropTypes.bool.isRequired,
};

export default Athlete;
