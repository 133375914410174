import React from 'react';
import Layout from '../../shared/components/Layout/Layout';
import HeaderText from '../../shared/components/HeaderText/HeaderText';

const TestPage = () => (
  <Layout>
    <HeaderText>
      Test Page!
    </HeaderText>
  </Layout>
);

export default TestPage;
