import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring/web.cjs';
// import { Transition } from 'react-spring/renderprops';
import IcomoonReact from 'icomoon-react';
import { useDispatch, useSelector } from 'react-redux';
import { componentWillAppendToBody } from 'react-append-to-body';

import { selectGroup } from '../../ducks/weightroomActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import DropdownList from './DropdownList';
import { ColorPrimary } from '../../../../shared/GlobalStyles';

const DropdownContainer = styled('div')`
  display: block;
  overflow: hidden;
  /* z-index: 10000;
  &:focus {
    outline: 0px;
  } */
`;

const DropdownLink = styled('a')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.4px;
  color: ${props => props.theme.textColor}!important;
  text-transform: Uppercase;
  cursor: pointer;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg {
    margin-left: 10px;
    path {
      fill: ${props => props.theme.textColor};
    }
  }
`;

const DropdownListWrapper = styled('div')`
  position: absolute;
  z-index: 10000;
  top: 0px;
  right: 0px;
  height: 300px;
  min-width: 300px;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
`;

const AppendDropdownList = componentWillAppendToBody(DropdownListWrapper);

const Dropdown = ({
  data,
}) => {
  const [active, setActive] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(false);
  const activeGroup = useSelector(state => state.weightRoom.groupFilter);

  const dispatch = useDispatch();

  const buttonRef = React.useRef();

  useEffect(() => {
    setActive(activeGroup);
  }, [activeGroup]);

  const handleActiveClick = (group) => {
    setActive(group);
    setActiveDropdown(false);
    dispatch(selectGroup(group));
  };

  const handleActiveDropdown = () => {
    setActiveDropdown(activeDropdown === false);
  };

  const isDropdownActive = activeDropdown === true;

  const transition = useTransition(isDropdownActive, null, {
    from: { opacity: 0, position: 'absolute', zIndex: '50000' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <DropdownContainer
      className='dropdown-container'
      // tabIndex='0'
      // onBlur={() => setActiveDropdown(false)}
    >
      <DropdownLink
        onClick={handleActiveDropdown}
        className='dropdown-link'
        ref={buttonRef}
      >
        {active === '' || active === 'All' ? 'All Athletes' : activeGroup}
        <IcomoonReact
          iconSet={iconSet}
          color={ColorPrimary}
          size={13}
          icon={activeDropdown ? 'up-arrow' : 'down-arrow'}
        />
      </DropdownLink>
      {transition.map(({ item, props }) => item && (
        <AppendDropdownList
          top={
            buttonRef.current && (
              buttonRef.current.getBoundingClientRect().bottom > 400
                ? buttonRef.current.getBoundingClientRect().bottom
                  - 300 - buttonRef.current.getBoundingClientRect().height
                : buttonRef.current.getBoundingClientRect().bottom
            )
          }
          left={buttonRef.current && (buttonRef.current.getBoundingClientRect().left)}
        >
          <animated.div style={props}>
            <DropdownList
              data={data}
              style={props}
              handleActive={handleActiveClick}
            />
          </animated.div>
        </AppendDropdownList>
      ))}
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default Dropdown;
