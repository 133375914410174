import { useDispatch, useSelector } from 'react-redux';
import { closeBoard, updateSetting } from '../../ducks/leaderboardActions';

const useBoard = (id) => {
  const leaderboardData = useSelector(state => state.leaderboard.data.leaderboards[id].leaderboardData);
  const leaderboardExercise = useSelector(state => state.leaderboard.data.leaderboards[id].leaderboardExercise);
  const leaderboardForm = useSelector(state => state.leaderboard.data.leaderboards[id].leaderboardForm);
  const leaderboardSettings = useSelector(state => state.leaderboard.data.leaderboards[id].settings);
  const isEndOfList = useSelector(state => state.leaderboard.data.leaderboards[id].isEndOfList);
  const isLeaderboardLoading = useSelector(state => state.leaderboard.data.leaderboards[id].isLeaderboardLoading);
  
  const dispatch = useDispatch();

  const handleCloseBoard = (board) => {
    dispatch(closeBoard(board));
  };

  const handleUpdateSetting = (board, key, value) => {
    dispatch(updateSetting(board, key, value));
  };

  return {
    handleCloseBoard,
    handleUpdateSetting,
    isEndOfList,
    isLeaderboardLoading,
    leaderboardData,
    leaderboardExercise,
    leaderboardForm,
    leaderboardSettings,
  };
};

export default useBoard;
