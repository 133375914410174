import React from 'react';
import AppHeader from '../../../AppHeader/AppHeader';
import useLayout from '../../hooks/useLayout';

const LayoutAppHeader = () => {
  const {
    currentUser,
    fetchUnread,
    fetchUserAccounts,
    handleLogOut,
    location,
    unreadMessages,
  } = useLayout();

  return (
    <AppHeader
      data={currentUser}
      handleLogOut={handleLogOut}
      fetchUnreadMessages={fetchUnread}
      fetchUserAccounts={fetchUserAccounts}
      location={location}
      unreadMessages={unreadMessages}
    />
  );
};

export default LayoutAppHeader;
