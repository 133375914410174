/** @jsx jsx */
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import EntryIcons from './EntryIcons';
import CoachNotes from './CoachNotes';
import HealthEntries from './WorkoutEntriesHealth';
import NoteEntries from './WorkoutEntriesNotes';
import SessionBreak from './SessionBreak';
import WorkoutEntries from './WorkoutEntries';
import WeightroomWorkoutEntriesTag from '../container/WeightroomWorkoutEntriesTag';
import Spinner from './Spinner';

import WeightroomQuestionList from '../container/WeightroomQuestionList';
import EntriesProgression from './WorkoutEntriesProgression';
import { ColorPrimary } from '../../../../shared/GlobalStyles';
import Timer from './Timer';
import OptedOut from './OptedOut';
import WeightroomModals from '../container/WeightroomModals';

const WorkoutEntryStyle = css`
  border-left: 1px solid rgba(104,104,104,0.2);
  min-height: 180px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 11px;
  
  .coach_notes {
    display: flex;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  
  & .reps_view {
    display: flex;
    flex-grow: 1;
    position: relative;
    &.with_notes {
      margin-top: 0px;
      .entry_icons {
        margin-top: 0px;
      }
    }
    &.without_notes {
      margin-top: 20px;
    }

    .entry_reps {
      /* margin-top: -15px; */
      align-self: center;
      padding: 2px 20px;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

    }

    & .entry_icons {
      display: flex;
      flex-direction: column;
      margin-left: -11px;
      
      button {
        margin-bottom: 10px;
      }
      
    }
    
    & .note_icons {
      margin-top: 0px!important;
    }
  }
`;

const CenterContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const EntriesContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  & .entry_row {
    display: flex;
    margin-bottom: 10px;
    & .title {
      display: block;
      min-width: 90px;
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 900;
      color: ${props => props.theme.textColor};
      text-transform: uppercase;
      letter-spacing: 1px;
      align-self: center; 
      
      &.with_label {
        margin-top: 30px;
      }
    }
  }

  & .form-group {
    margin: 0px 0px 0px 10px;
    text-align: center;
    width: 100%;
    min-width: 60px;
    position: relative;

    & label {
      font-family: 'Open Sans';
      font-weight: 900;
      font-size: 13px;
      align-self: center;
    }

    p {
      color: ${props => props.theme.textColor};
    }

    & input {
      font-family: 'Open Sans';
      font-weight: 900;
      background: ${props => props.theme.backgroundColorL3};
      border: 1px solid ${props => props.theme.noBorderColorForm};
      color: ${props => props.theme.textColor};
      &::placeholder {
        color: #aaa;
      }
    }
  }
  #timer_wrapper {
    margin-left: 20px;
    transform: scale(.95);
  }

  @media only screen and (max-width : ${props => props.maxWidth}) {
    &.isScrolling${props => props.numberOfSets} {
      .scrollable-field-container {
        padding-right: 60px;
      }
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background-image: ${props => props.theme.overflowGradient};
        pointer-events: none;
      }
    }
  }
`;


const WorkoutEntryView = ({
  activeScreens,
  athleteId,
  data,
  entriesContainerRef,
  entryRowRef,
  exerId,
  index,
  fetchDocument,
  fetchHistory,
  fetchExercises,
  fetchSimilarExercises,
  formikRef,
  isWorkoutLoading,
  isWorkoutActive,
  namespace,
  nextButton,
  openModal,
  scienceRef,
  scrollQuestions,
  settings,
  supersetIndex,
  updateWorkout,
  workoutsBySuperset,
}) => {
  const hasResult = data.result !== null;

  const isScienceEntry = data.exer_type === 'science';
  const isVideoEntry = data.exer_type === 'video';
  const isHealthEntry = data.exer_type === 'health';
  const isProgressionEntry = data.exer_type === 'progression';
  const isRestEntry = data.exer_type === 'rest';
  const isBreak = data.exer_type === 'break';
  const isTagEntry = data.exer_type === 'tag';

  const workId = data.work_id;

  const isOptedNoSub = (hasResult) && (data.result.opt_out > 0) && (data.result.substitution === 0);

  const entriesViewRef = useRef(null);

  const [entriesViewHeight, setEntriesViewHeight] = React.useState(0);

  const wrvSupersetsEnabled = settings.wrv_supersets_enabled;

  React.useEffect(() => {
    console.log(entriesViewRef.current);
    if (entriesViewRef.current) {
      setEntriesViewHeight(entriesViewRef.current.getBoundingClientRect().height);
    }
  }, []);

  const isMainWorkoutEntry = (
    (data.exer_type === 'lift')
    || (data.exer_type === 'cardio')
    || (data.exer_type === 'circuit')
    || (data.result && data.exer_type === 'tag')
  );

  const isTagWithoutResult = (data.exer_type === 'tag');

  const isNoteEntry = (
    data.exer_type === 'note'
    || data.exer_type === 'warmup'
    || data.exer_type === 'cooldown'
  );

  const hasIcons = (isMainWorkoutEntry || isHealthEntry || isProgressionEntry || isNoteEntry);

  const attributes = (hasResult && data.result.substitution > 0)
    ? data.result.sub_attributes
    : data.attributes;

  const exerName = (hasResult && data.result.substitution > 0)
    ? data.result.sub_exer_name
    : data.exer_name;

  const handleInfoClick = useCallback((modal) => {
    openModal(namespace, modal);
  }, []);

  const modalOpen = useCallback((modal) => {
    openModal(namespace, modal);
    if (modal === 'history') {
      const exerciseId = (hasResult && data.result.substitution > 0)
        ? data.result.substitution
        : exerId;
      fetchHistory(namespace, exerciseId, athleteId);
    }
    if (modal === 'attachment') {
      fetchDocument(namespace, attributes.doc);
    }
    if (modal === 'opt-out') {
      fetchExercises(namespace);
      fetchSimilarExercises(namespace, exerId);
    }
  }, []);

  const scrollQuestionsFunc = (question) => {
    scrollQuestions(question);
  };

  const isPortrait = window.innerHeight > window.innerWidth;
  const isLandscape = window.innerHeight < window.innerWidth;

  const numberOfSets = data.sets;
  let maxWidth = '';

  if (activeScreens <= 2 && isPortrait) {
    switch (numberOfSets) {
      case 10:
        maxWidth = '868px';
        break;
      case 9:
        maxWidth = '800px';
        break;
      case 8:
        maxWidth = '732px';
        break;
      case 7:
        maxWidth = '663px';
        break;
      case 6:
        maxWidth = '593px';
        break;
      case 5:
        maxWidth = '523px';
        break;
      case 4:
        maxWidth = '453px';
        break;
      case 3:
        maxWidth = '383px';
        break;
      default:
    }
  }

  if (activeScreens === 4 || (activeScreens === 2 && isLandscape)) {
    switch (numberOfSets) {
      case 10:
        maxWidth = '1800px';
        break;
      case 9:
        maxWidth = '1660px';
        break;
      case 8:
        maxWidth = '1500px';
        break;
      case 7:
        maxWidth = '1365px';
        break;
      case 6:
        maxWidth = '1220px';
        break;
      case 5:
        maxWidth = '1040px';
        break;
      case 4:
        maxWidth = '900px';
        break;
      case 3:
        maxWidth = '760px';
        break;
      default:
    }
  }

  return (
    <div
      className='workout_entry'
      css={WorkoutEntryStyle}
      // ref={measureRef}
    >
      { ((isMainWorkoutEntry) || (isHealthEntry) || (isTagWithoutResult)) && (
        <CoachNotes
          info={data.info}
          isTrackingActive={data.tracking.active}
          label={data.tracking.text}
          max={Number.isInteger(data.tracking.max)
            ? parseInt(data.tracking.max, 10)
            : data.tracking.max}
          restTime={data.rest_time}
          tempo={data.tempo}
        />
      )}
      <div
        // ref={entriesViewRef}
        className={`
          reps_view
          ${((isMainWorkoutEntry) || (isHealthEntry) || (isTagWithoutResult)) ? 'with_notes' : 'without_notes'}
        `}
      >
        { (hasIcons) && (
          <EntryIcons
            attributes={attributes}
            exerType={data.exer_type}
            isHealthEntry={isHealthEntry}
            isMainWorkoutEntry={isMainWorkoutEntry}
            isNoteEntry={isNoteEntry}
            modalOpen={modalOpen}
          />
        )}

        {/* WORKOUT ENTRIES */}
        {isWorkoutLoading ? (
          <Spinner />
        ) : (
          <EntriesContainer
            // ref={entriesRef}
            className={`
              ${(data.sets >= numberOfSets) && `isScrolling${numberOfSets}`}
            `}
            numberOfSets={numberOfSets}
            maxWidth={maxWidth}
          >
            {(!isOptedNoSub && isMainWorkoutEntry) && (
              <WorkoutEntries
                athleteId={athleteId}
                formikRef={formikRef}
                entriesContainerRef={entriesContainerRef}
                entriesViewRef={entriesViewRef}
                entriesViewHeight={entriesViewHeight}
                entryRowRef={entryRowRef}
                handleInfoClick={handleInfoClick}
                index={index}
                namespace={namespace}
                supersetIndex={supersetIndex}
                updateWorkout={updateWorkout}
                workout={data}
                workoutsBySuperset={workoutsBySuperset}
                workId={workId}
                wrvSupersetsEnabled={wrvSupersetsEnabled}
              />
            )}

            { isNoteEntry && (
              <NoteEntries info={data.info} />
            )}

            { isScienceEntry && (
              <WeightroomQuestionList
                data={data.questions}
                formikRef={formikRef}
                index={index}
                namespace={namespace}
                ref={scienceRef}
                scrollQuestions={scrollQuestionsFunc}
                workout={data}
              />
            )}

            { isVideoEntry && (
              <NoteEntries info={(
                <iframe
                  id='ytplayer'
                  type='text/html'
                  height='150'
                  allowFullScreen='true'
                  src={data.info}
                  frameBorder='0'
                  title='video'
                />
                )}
              />
            )}

            {(!isOptedNoSub && isHealthEntry) && (
              <HealthEntries
                athleteId={athleteId}
                exerType={data.exer_type}
                formikRef={formikRef}
                handleInfoClick={handleInfoClick}
                index={index}
                namespace={namespace}
                sets={data.sets}
                setList={data.setList}
                updateWorkout={updateWorkout}
                workout={data}
              />
            )}

            { isProgressionEntry && (
              <EntriesProgression
                athleteId={athleteId}
                formikRef={formikRef}
                info={data.info}
                index={index}
                namespace={namespace}
                updateWorkout={updateWorkout}
                workout={data}
              />
            )}

            { isBreak && (
              <SessionBreak
                title={data.exer_name}
              />
            )}

            { isRestEntry && (
              <CenterContainer>
                <Timer
                  autoplay
                  nextButton={nextButton}
                  seconds={data.sets}
                />
              </CenterContainer>
            )}

            {(isOptedNoSub) && (
              <OptedOut
                reason={data.result.opt_out}
                note={data.result.opt_text}
                subExerName={data.result.sub_exer_name}
              />
            )}

            { (isTagEntry && !data.result) && (
              <WeightroomWorkoutEntriesTag
                formikRef={formikRef}
                index={index}
                namespace={namespace}
                workout={data}
              />
            )}

          </EntriesContainer>
        )}
      </div>

      {isWorkoutActive && (
        <WeightroomModals
          data={data}
          exerName={exerName}
          index={index}
          namespace={namespace}
          workId={workId}
        />
      )}

    </div>
  );
};

WorkoutEntryView.propTypes = {
  activeScreens: PropTypes.number.isRequired,
  athleteId: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  exerId: PropTypes.number.isRequired,
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  fetchDocument: PropTypes.func.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  fetchExercises: PropTypes.func.isRequired,
  fetchSimilarExercises: PropTypes.func.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  isWorkoutLoading: PropTypes.bool.isRequired,
  isWorkoutActive: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  nextButton: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  scienceRef: PropTypes.instanceOf(Object).isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
  supersetIndex: PropTypes.number,
  updateWorkout: PropTypes.func.isRequired,
  workoutsBySuperset: PropTypes.instanceOf(Object).isRequired,
};

WorkoutEntryView.defaultProps = {
  supersetIndex: 0,
};

export default WorkoutEntryView;
