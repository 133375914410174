import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import { DateRangePicker } from 'react-dates';

import { ColorGreen, BoxShadow } from '../../../shared/GlobalStyles';
import Select from 'react-select';
import Toggle from '../../../shared/components/Toggle/Toggle';
import { FormGroup } from '../../../shared/components/FormHandler/FormHandler';
import FormLabel from '../../../shared/components/FormLabel/FormLabel';
import IconRound from '../../../shared/components/IconRound/IconRound';

import 'react-dates/lib/css/_datepicker.css';

const DatePickerContainer = styled('div')`
  tr:first-of-type {
    .CalendarDay, .CalendarDay__selected {
      border-top: 1px solid #e4e7e7;
    }
    .CalendarDay__outside {
      border-top: 0px;
      border-bottom: 1px solid #e4e7e7;
    }
  }
  & tr:last-of-type {
    border-right: 0px;
    .CalendarDay__outside {
      border-left: 1px solid #e4e7e7;
    }
    .CalendarDay__outside ~ .CalendarDay__outside{
      border-left: 0px solid #e4e7e7;
    }
  }

  .CalendarDay__selected {
    border-top: 0!important;
    border-right: 0px!important;
    border-color: #e4e7e7;
    .day-container {
      background-color: #0dcc8a!important;
    }
  }

  .DateRangePickerInput, .DateInput_input, .DateInput {
    border-radius: 4px;
  }

  .DateInput_input {
    padding: 5px 8px;
  }
  
  & button {
    border: 1px solid ${props => props.theme.noBorderColor};
    color: ${props => props.theme.lightTextColor};
    font-weight: 600;
  }

  & .CalendarMonth_caption {
    font-family: 'Nunito Sans';
    font-weight: 300;
  }

  /* & .CalendarDay {
    font-family: 'Nunito Sans';
    display: inline-flex;
    padding: 0;
    border-right: 0;
    border-top: 0;
    &:last-of-type:not(.CalendarDay__outside) {
      border-right: 1px solid #e4e7e7;
    }
  } */
  /*
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ${ColorGreen};
    border: 1px double ${ColorGreen};
    color: #fff;
  }

  & small {
    font-family: 'Nunito Sans';
  }

  .SingleDatePickerInput {
    background-color: transparent!important;
    height: 10px;
    border: 0px;
  
    svg {
      top: 10px!important;
      left: 16px!important;
    }
  }

  .SingleDatePicker {
    position: absolute;
  }

  .DayPicker__withBorder {
    box-shadow: ${BoxShadow}, 0 0 0 1px rgba(0, 0, 0, 0.07);
  }

  .SingleDatePicker_picker {
    top: 20px!important;
  }
  
  .DateInput__block {
      width: 100%;
      padding-right: 42px;
  }

  .SingleDatePickerInput_calendarIcon {
      position: absolute;
      right: 0;
      padding: 0 10px;
      line-height: 50px;
  }


  .CalendarMonth_caption {
    color: ${props => props.theme.textColor}
  }

  .DayPicker {
    background-color: ${props => props.theme.backgroundColorL2};
  }
  .CalendarMonthGrid, .CalendarMonth, .CalendarDay, .day-container {
    background: ${props => props.theme.backgroundColorL2}!important;
  }

  .CalendarDay__default {
    border-color: ${props => props.theme.borderColor}
  }

  .DayPicker tr:first-of-type .CalendarDay__outside {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker tr:last-of-type .CalendarDay__outside {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker tr:first-of-type .CalendarDay, .DayPicker tr:first-of-type .CalendarDay__selected {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker .CalendarDay:last-of-type:not(.CalendarDay__outside) {
    border-color: ${props => props.theme.borderColor};
  }

  .CalendarDay__default {
    color: ${props => props.theme.textColor};
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${props => props.theme.borderColor};
    background-color: ${props => props.theme.backgroundColorL3};
  } */
`;

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  :first-of-type {
    margin-right: 30px;
  }
`;

const genderOptions = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Undefined' },
];

const timeframeOptions = [
  { value: 1, label: 'All Time' },
  { value: 2, label: 'Last 30 Days' },
  { value: 3, label: 'Today' },
  { value: 4, label: 'Custom' },
];

const compareToOptions = [
  { value: 1, label: 'Current Max' },
  { value: 2, label: 'Last 30 Days' },
  { value: 3, label: 'Today' },
  { value: 4, label: 'Custom' },
];

const BoardFormSecondSlide = ({ formProps }) => {
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  return (
    <Fragment>
      <FormGroup>
        <FormLabel>Gender</FormLabel>
        <Select
          options={genderOptions}
          onChange={(options) => { formProps.setFieldValue('gender', options.value); }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Time Frame</FormLabel>
        <Select
          options={timeframeOptions}
          onChange={(options) => { formProps.setFieldValue('rangeType', options.value); }}
        />
      </FormGroup>
      {formProps.values.rangeType === 4 && (
        <FormGroup>
          <FormColumns>
            <ColumnContent>
              <FormLabel>Start</FormLabel>
              <DatePickerContainer>
                <DateRangePicker
                  // customInputIcon={() => (
                  //   <IconRound
                  //     icon='calendar'
                  //     noBorder
                  //   />
                  // )}
                  disabled={formProps.values.rangeType !== 4}
                  startDate={formProps.values.rangeDates.startDate} // momentPropTypes.momentObj or null,
                  startDateId="startDate" // PropTypes.string.isRequired,
                  endDate={formProps.values.rangeDates.endDate} // momentPropTypes.momentObj or null,
                  endDateId="endDate" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => formProps.setFieldValue('rangeDates', { startDate, endDate })} // PropTypes.func.isRequired,
                  focusedInput={focusedDateInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => setFocusedDateInput(focusedInput)} // PropTypes.func.isRequired,
                  hideKeyboardShortcutsPanel
                />
              </DatePickerContainer>
            </ColumnContent>
          </FormColumns>
        </FormGroup>
      )}
      <FormGroup>
        <FormLabel>Compare To</FormLabel>
        <Select
          options={compareToOptions}
          onChange={(options) => { formProps.setFieldValue('compareTo', options.value); }}
        />
      </FormGroup>
      {formProps.values.compareTo === 4 && (
        <FormGroup>
          <FormColumns>
            <ColumnContent>
              <FormLabel>Start</FormLabel>
              <DatePickerContainer>
                <DateRangePicker
                  // customInputIcon={() => (
                  //   <IconRound
                  //     icon='calendar'
                  //     noBorder
                  //   />
                  // )}
                  disabled={formProps.values.compareTo !== 4}
                  startDate={formProps.values.compareDates.startDate} // momentPropTypes.momentObj or null,
                  startDateId="startDate" // PropTypes.string.isRequired,
                  endDate={formProps.values.compareDates.endDate} // momentPropTypes.momentObj or null,
                  endDateId="endDate" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => formProps.setFieldValue('compareDates', { startDate, endDate })} // PropTypes.func.isRequired,
                  focusedInput={focusedDateInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => setFocusedDateInput(focusedInput)} // PropTypes.func.isRequired,
                  hideKeyboardShortcutsPanel
                />
              </DatePickerContainer>
            </ColumnContent>
          </FormColumns>
        </FormGroup>
      )}

    </Fragment>
  );
};

export default BoardFormSecondSlide;
