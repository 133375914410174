import React, {
  Fragment,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import styled from '@emotion/styled';

import useLeaderboard from './hooks/useLeaderboard';
import useLeaderboardModal from './hooks/useLeaderboardModal';

import Modal from '../../../shared/components/Modal/Modal';
import Button from '../../../shared/components/Button/Button';
import FormAddBoard from './FormAddBoard';
import FormEditBoard from './FormEditBoard';
import DownloadBoard from './DownloadBoard';
import BoardSettings from './BoardSettings';

const ModalWrapper = styled('div')`
  .ReactModal__Content {
    max-width: 710px;
  }
`;

const ModalContainer = styled('div')`
  width: 100%;
  padding: ${props => (props.activeModal === 'Leaderboard Settings' ? '0px' : '0px 90px 60px 90px')};

  button {
    position: absolute;
    bottom: 0px;
    margin-left: ${props => (props.activeModal === 'Leaderboard Settings' ? '-40px' : '-130px')};
  }
  
  .toggle {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    padding: ${props => (props.activeModal === 'Leaderboard Settings' ? '0px' : '0px 30px 60px 30px')};
    
    button {
      margin-left: ${props => (props.activeModal === 'Leaderboard Settings' ? '-40px' : '-70px')};
    }
  }

  
`;

const LeaderboardModal = () => {
  // const [isModalOpen, setModalOpen] = useState(false);
  // const [activeModal, setActiveModal] = useState();
  const {
    currentUser,
    handleFetchGroups,
  } = useLeaderboard();

  const {
    activeBoardId,
    activeModal,
    isModalOpen,
    handleSetModalOpen,
  } = useLeaderboardModal();

  const closeModal = () => {
    handleSetModalOpen(false, '');
  };

  const { accountCode } = currentUser;

  // Fetch groups with leaderboard enabled on initial form load.
  useLayoutEffect(() => {
    handleFetchGroups(accountCode);
  }, []);

  return (
    <ModalWrapper>
      <Modal
        onRequestClose={closeModal}
        isOpen={isModalOpen}
        customButton
        title={activeModal}
        titleIcon='trophy'
      >
        <ModalContainer
          activeModal={activeModal}
        >
          {activeModal === 'Add Leaderboard' && (
            <FormAddBoard
              activeModal={activeModal}
              closeModal={closeModal}
              leaderboardId={activeBoardId}
            />
          )}
          {activeModal === 'Edit Leaderboard' && (
            <FormEditBoard
              activeModal={activeModal}
              closeModal={closeModal}
              leaderboardId={activeBoardId}
            />
          )}
          {activeModal === 'Export Leaderboard' && (
            <DownloadBoard
              activeModal={activeModal}
              closeModal={closeModal}
              leaderboardId={activeBoardId}
            />
          )}
          {activeModal === 'Leaderboard Settings' && (
            <BoardSettings
              activeModal={activeModal}
              closeModal={closeModal}
              leaderboardId={activeBoardId}
            />
          )}
        </ModalContainer>
      </Modal>
    </ModalWrapper>
  );
};

export default LeaderboardModal;
