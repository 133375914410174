import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import getAllUrlParams from '../../shared/utils/getAllUrlParams';

const LoginRoute = ({
  component: Component,
  authed,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      props => (
        authed === false
          ? <Component {...props} />
          : (
            <Route
              path={path}
              component={() => {
                // window.location.href = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}app.teambuildr.com/login?rel=${path.replace('/', '')}&version=2`;
                // // window.location.href = `http://localhost:8080/login`;
                // if (process.env.ENVIRONMENT === 'LOCAL') {
                //   window.location.href = 'http://localhost:8080/login';
                // }
                const url = getAllUrlParams(window.location.href);
                const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
                window.location = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' ? 'beta' : 'app'}.teambuildr.com/${url.rel === undefined ? 'dashboard' : url.rel}`;
                return null;
              }}
            />
            // <Redirect to={`/login?rel=${path.replace('/', '')}&version=2`} />
          )
      )
    }
  />
);

export default LoginRoute;
