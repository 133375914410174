// LOGIN

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_AUTHORIZED = 'LOGIN_AUTHORIZED';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER_START = 'SET_CURRENT_USER_START';
export const SET_CURRENT_USER_FULFILLED = 'SET_CURRENT_USER_FULFILLED';
export const SET_CURRENT_USER_REJECTED = 'SET_CURRENT_USER_REJECTED';
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_FULFILLED = 'FETCH_CURRENT_USER_FULFILLED';
export const FETCH_CURRENT_USER_REJECTED = 'FETCH_CURRENT_USER_REJECTED';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';

// FETCH USERS


export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';

// WEIGHTROOM VIEW

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';

export const FETCH_USER_GROUPS_START = 'FETCH_USER_GROUPS_START';
export const FETCH_USER_GROUPS_FULFILLED = 'FETCH_USER_GROUPS_FULFILLED';
export const FETCH_USER_GROUPS_REJECTED = 'FETCH_USER_GROUPS_REJECTED';

export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_FULFILLED = 'FETCH_HISTORY_FULFILLED';
export const FETCH_HISTORY_REJECTED = 'FETCH_HISTORY_REJECTED';

export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_FULFILLED = 'FETCH_EXERCISES_FULFILLED';
export const FETCH_EXERCISES_REJECTED = 'FETCH_EXERCISES_REJECTED';

export const FETCH_SIM_EXERCISES_START = 'FETCH_SIM_EXERCISES_START';
export const FETCH_SIM_EXERCISES_FULFILLED = 'FETCH_SIM_EXERCISES_FULFILLED';
export const FETCH_SIM_EXERCISES_REJECTED = 'FETCH_SIM_EXERCISES_REJECTED';

export const FETCH_SIM_TYPE_EXERCISES_START = 'FETCH_SIM_TYPE_EXERCISES_START';
export const FETCH_SIM_TYPE_EXERCISES_FULFILLED = 'FETCH_SIM_TYPE_EXERCISES_FULFILLED';
export const FETCH_SIM_TYPE_EXERCISES_REJECTED = 'FETCH_SIM_TYPE_EXERCISES_REJECTED';

export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_FULFILLED = 'FETCH_DOCUMENT_FULFILLED';
export const FETCH_DOCUMENT_REJECTED = 'FETCH_DOCUMENT_REJECTED';

export const FETCH_WORKOUTS_START = 'FETCH_WORKOUTS_START';
export const FETCH_WORKOUTS_FULFILLED = 'FETCH_WORKOUTS_FULFILLED';
export const FETCH_WORKOUTS_REJECTED = 'FETCH_WORKOUTS_REJECTED';

export const FETCH_WORKOUT_START = 'FETCH_WORKOUT_START';
export const FETCH_WORKOUT_FULFILLED = 'FETCH_WORKOUT_FULFILLED';
export const FETCH_WORKOUT_REJECTED = 'FETCH_WORKOUT_REJECTED';

export const FETCH_CALENDAR_START = 'FETCH_CALENDAR_START';
export const FETCH_CALENDAR_FULFILLED = 'FETCH_CALENDAR_FULFILLED';
export const FETCH_CALENDAR_REJECTED = 'FETCH_CALENDAR_REJECTED';

export const FETCH_JOURNAL_START = 'FETCH_JOURNAL_START';
export const FETCH_JOURNAL_FULFILLED = 'FETCH_JOURNAL_FULFILLED';
export const FETCH_JOURNAL_REJECTED = 'FETCH_JOURNAL_REJECTED';

export const UPDATE_WORKOUTS_START = 'UPDATE_WORKOUTS_START';
export const UPDATE_WORKOUTS_FULFILLED = 'UPDATE_WORKOUTS_FULFILLED';
export const UPDATE_WORKOUTS_REJECTED = 'UPDATE_WORKOUTS_REJECTED';

export const UPDATE_SUBSTITUTE_WORKOUT = 'UPDATE_SUBSTITUTE_WORKOUT';

export const UPDATE_JOURNAL_START = 'UPDATE_JOURNAL_START';
export const UPDATE_JOURNAL_FULFILLED = 'UPDATE_JOURNAL_FULFILLED';
export const UPDATE_JOURNAL_REJECTED = 'UPDATE_JOURNAL_REJECTED';

export const SUBMIT_PIN_START = 'SUBMIT_PIN_START';
export const SUBMIT_PIN_FULFILLED = 'SUBMIT_PIN_FULFILLED';
export const SUBMIT_PIN_REJECTED = 'SUBMIT_PIN_REJECTED';

export const SEARCH_ATHLETE = 'SEARCH_ATHLETE';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_ATHLETE = 'SELECT_ATHLETE';
export const SELECT_DATE = 'SELECT_DATE';
export const CLOSE_PANEL = 'CLOSE_PANEL';
export const SELECT_VERIFIED_ATHLETE = 'SELECT_VERIFIED_ATHLETE';

export const SELECT_WORKOUT = 'SELECT_WORKOUT';
export const CLOSE_WORKOUT = 'CLOSE_WORKOUT';
export const NEXT_WORKOUT = 'NEXT_WORKOUT';
export const PREV_WORKOUT = 'PREV_WORKOUT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_PINPAD = 'OPEN_PINPAD';
export const CLOSE_PINPAD = 'CLOSE_PINPAD';
export const CANCEL_PINPAD = 'CANCEL_PINPAD';

export const COMPLETE_WORKOUT = 'COMPLETE_WORKOUT';

export const SELECT_QUESTION = 'SELECT_QUESTION';
export const CLOSE_QUESTION = 'CLOSE_QUESTION';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const PREV_QUESTION = 'PREV_QUESTION';
export const RESTART_QUESTIONS = 'RESTART_QUESTIONS';

export const TOGGLE_SCREENS = 'TOGGLE_SCREENS';
export const SET_SCREENS = 'SET_SCREENS';

export const STORE_PANEL_HEIGHT = 'STORE_PANEL_HEIGHT';

export const STORE_FORMIK_VALUES = 'STORE_FORMIK_VALUES';

export const STORE_WORKOUTS_BY_SUPERSET = 'STORE_WORKOUTS_BY_SUPERSET';
export const INCREASE_COMPLETED_SETS = 'INCREASE_COMPLETED_SETS';
export const DECREASE_COMPLETED_SETS = 'DECREASE_COMPLETED_SETS';
export const SET_IS_NAVIGATING_SUPERSETS = 'SET_IS_NAVIGATING_SUPERSETS';

export const OPEN_GLOBAL_MODAL = 'OPEN_GLOBAL_MODAL';
export const CLOSE_GLOBAL_MODAL = 'CLOSE_GLOBAL_MODAL';

export const TOGGLE_SUPERSETS = 'TOGGLE_SUPERSETS';

export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME';

export const TOGGLE_WEIGHT_VALUE = 'TOGGLE_WEIGHT_VALUE';
