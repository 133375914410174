import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import Text from '../../../../shared/components/Text/Text';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';

const ModalWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  .caveat-text {
    margin-top: 20px;
    font-size: 15px;
  }
`;

const LinkWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 30%;
  margin-top: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 5px;
`;

const ShareText = styled('div')`
  font-size: 22px;
`;

export const ModalContentContainer = styled('div')`
  padding: 30px 40px 60px 40px;
  flex-grow: 1;
  background-color: ${props => props.theme.backgroundColorL2};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    /* .form-group {
      padding: 15px 0px 5px 0px;
    } */
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }

`;

const ModalShareDocument = ({
  link,
}) => {
  return (
    <ModalWrapper>
      <h4>Copy the Following URL to share</h4>
      <LinkWrapper>
        <ShareText>
          {link}
        </ShareText>
      </LinkWrapper>
      <Text className='caveat-text'>*User must have proper group access to view this item</Text>
    </ModalWrapper>
  );
};

ModalShareDocument.propTypes = { link: PropTypes.string.isRequired };

export default ModalShareDocument;
