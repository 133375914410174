import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Title from './Title';

const BreakContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const SessionBreak = ({
  title,
}) => (
  <BreakContainer>
    <Title
      icon='window, break'
      noMargin
    >
      {title}
    </Title>
  </BreakContainer>
);

SessionBreak.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SessionBreak;
