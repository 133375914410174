import React from 'react';
import styled from '@emotion/styled';
import Modal from './Modal';
import ModalSupport from './ModalSupport';
import ModalAccountSwitcher from './ModalAccountSwitcher';

const HeaderModals = ({
  activeModal,
  closeModal,
  isModalOpen,
  organizationId,
  path,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
    >
      {activeModal === 'Support' && (
        <ModalSupport 
          path={path}
        />
      )}
      {activeModal === 'Switch Accounts' && (
        <ModalAccountSwitcher 
          organizationId={organizationId}
        />
      )}
    </Modal>
  );
};

export default HeaderModals;
