import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  authed,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      props => (
        authed
          ? <Component {...props} />
          : (
            <Route
              path={path}
              component={() => {
                if (process.env.ENVIRONMENT === 'LOCAL') {
                  window.location.href = 'http://localhost:8080/login';
                }
                if (process.env.ENVIRONMENT !== 'LOCAL') {
                  const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.beta' : 'app.';
                  window.location.href = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.beta.' : isDevelop}teambuildr.com/login?rel=${path.replace('/', '')}&version=2`;
                }
                // window.location.href = `http://localhost:8080/login`;
                // window.location.href = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}beta.teambuildr.com/login`;
                return null;
              }}
            />
            // <Redirect to={`/login?rel=${path.replace('/', '')}&version=2`} />
          )
      )
    }
  />
);

export default PrivateRoute;
