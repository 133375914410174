import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import LayoutAppHeader from './components/container/LayoutAppHeader';
import useLayout from './hooks/useLayout';

const LayoutContainer = styled('div')`
  background-color: #f5f5f5;
`;

const MainContent = styled('main')`
  position: relative;
  height: auto;
  height: 100%;
  min-height: 100%;
  padding: 64px 15px 15px 15px;
`;

const Layout = ({
  children,
}) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isAuthenticated === false) {
  //     dispatch(push('/login'));
  //   }
  // }, [isAuthenticated]);

  return (
    <LayoutContainer>
      {/* <AppHeader
        data={Data}
        location='/feed'
      /> */}
      <LayoutAppHeader />
      <MainContent className='container'>
        {children}
      </MainContent>
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
