import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';

import Button from '../Button/Button';
import Title from '../Title/Title';
import {
  BorderRadius,
  BoxShadow,
  TransitionGlobal,
  Gutter,
} from '../../GlobalStyles';

const ModalStyle = {
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    top: '0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    minHeight: '400px',
    minWidth: '530px',
    maxWidth: '710px',
    backgroundColor: `white`,
    borderRadius: `${BorderRadius}`,
    boxShadow: `${BoxShadow}`,
  },
};

export const ModalContentWrapper = styled('div')`
  display: flex;
  padding: 40px;
  display: flex;
  flex-grow: 1;
  background-color: ${props => props.theme.backgroundColorL2};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  /* .form-group {
    padding: ${Gutter} calc(${Gutter} * 3);
  } */
  /* .form-control {
    min-height: 150px;
  } */
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  /* @media only screen and (max-width: 1023px) {
    .form-group {
      padding: 20px 15px;
    }
  } */
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
`;

const TitleContainer = styled('div')`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.lightText[0]};
  padding-top: 40px;  
`;


const Modal = ({
  children,
  customButton,
  isOpen,
  onRequestClose,
  title,
  titleIcon,
}) => {
  // const { isDarkThemeEnabled } = useWeightroomSettings();
  const modalClose = () => {
    onRequestClose();
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);
            &:focus {
              outline: -webkit-focus-ring-color auto 0px!important;
            }
          }

          .ReactModal__Overlay {
            background-color: rgba(216, 216, 216, 0.5);
            /* background-color: ${isDarkThemeEnabled ? 'rgba(30, 30, 30, 0.7)' : 'rgba(216, 216, 216, 0.5)'}!important; */
            opacity: 0;
            z-index: 1000!important;
            justify-content: center;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .ReactModal__Content {
              width: 75vw!important;
            } 
          }

          @media only screen and (max-device-width: 767px) {
            .ReactModal__Content {
              min-width: 95%!important;
            }
          }
        `}
      />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={isOpen}
        onRequestClose={modalClose}
        style={ModalStyle}
        className='global-modal'
        overlayClassName='overlay'
      >
        {title && (
          <TitleContainer>
            <Title
              icon={titleIcon}
            >
              {title}
            </Title>
          </TitleContainer>
        )}
        <ModalContentWrapper>
          {children}
          {!customButton && (
            <Button
              bottom
              fullWidth
              cta='Close'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={modalClose}
            />
          )}
        </ModalContentWrapper>
      </ReactModal>
    </Fragment>
  );
};

if (process.env.STORYBOOK_RUNNING === undefined) {
  ReactModal.setAppElement('#app');
}

Modal.propTypes = {
  children: PropTypes.node,
  customButton: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

Modal.defaultProps = {
  children: '',
  customButton: false,
};

export default Modal;
