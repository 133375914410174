// modal that acts as a portal for creating any of the three document types

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .icon-text-wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
`;

const CreateObjectWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    :hover {
      background-color: teal;
      color: white;
      .create-doc-text {
        color: white;
      }
      svg {
        fill: rgb(255,255,255);
      }
    }
    .create-doc-text {
      margin-top: 25px;
      font-weight: 900;
      font-size: 13px;
    }
`;

const ItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  min-width: 400px;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
    margin-right: 10px;
  }
`;

const NavigationFormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ModalContentContainer = styled('div')`
  padding: 30px 40px 60px 40px;
  flex-grow: 1;
  background-color: ${props => props.theme.backgroundColorL2};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    /* .form-group {
      padding: 15px 0px 5px 0px;
    } */
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
`;

const ModalCreateDocument = ({
  setActiveModal,
  currentFolder,
  onRequestClose,
}) => (
  <ModalWrapper>
    <NavigationFormWrapper>
      <NavigationWrapper>
        <Text
          className='back-arrow-div'
          onClick={() => {
            onRequestClose();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <ItemsWrapper>
        {!currentFolder ? (
          <CreateObjectWrapper
            style={
              {
                width: '33.3%',
              }
            }
            onClick={() => setActiveModal('create_folder_modal')}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={75}
              icon='folder'
              className='ico-moon-icon'
            />
            <Text className='create-doc-text'>
              CREATE A FOLDER
            </Text>
          </CreateObjectWrapper>
        ) : null}
        <CreateObjectWrapper
          style={
              {
                width: !currentFolder ? '33.3%' : '50%',
              }
            }
          onClick={() => setActiveModal('upload_file_modal')}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={70}
            icon='file'
          />
          <Text className='create-doc-text'>
            UPLOAD A FILE
          </Text>
        </CreateObjectWrapper>
        <CreateObjectWrapper
          style={
              {
                width: !currentFolder ? '33.3%' : '50%',
              }
            }
          onClick={() => setActiveModal('add_link_modal')}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={70}
            icon='globe'
          />
          <Text className='create-doc-text'>
            ADD A LINK
          </Text>
        </CreateObjectWrapper>
      </ItemsWrapper>
    </NavigationFormWrapper>
  </ModalWrapper>
);

ModalCreateDocument.propTypes = {
  setActiveModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  currentFolder: PropTypes.oneOfType(
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ),
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalCreateDocument;
