import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocuments,
  fetchFile,
  fetchFolder,
  fetchDocumentsWithSearch,
  fetchDocumentsWithSearchAndFolder,
  createDocument,
  uploadDocument,
  deleteDocuments,
  updateDocument,
  fetchUserGroups,
  fetchFolders,
  fetchDocument,
} from '../../ducks/documentsActions';

const useDocuments = () => {
  const currentUser = useSelector(state => state.auth.data.currentUser);
  const isDocumentsLoading = useSelector(state => state.documents.ui.isDocumentsLoading);
  const isDocumentUploading = useSelector(state => state.documents.ui.isDocumentUploading);
  const documents = useSelector(state => state.documents.data.documents);
  const folderDocuments = useSelector(state => state.documents.data.folderDocuments);
  const uploadedDocument = useSelector(state => state.documents.data.uploadDocument);
  const updatedDocument = useSelector(state => state.documents.data.updateDocument);
  const userGroups = useSelector(state => state.documents.data.userGroups);
  const currentLocation = useSelector(state => state.router.location.pathname);
  const currentFile = useSelector(state => state.documents.data.file);
  const visibleFolders = useSelector(state => state.documents.data.folders);
  const uploadDocumentError = useSelector(state => state.documents.data.uploadDocumentError);
  const fetchedDocument = useSelector(state => state.documents.data.document);
  const fetchDocumentError = useSelector(state => state.documents.data.fetchDocumentError);

  const dispatch = useDispatch();

  const handleFetchDocuments = (values) => {
    dispatch(fetchDocuments(values));
  };

  const handleFetchDocument = (values) => {
    dispatch(fetchDocument(values));
  };

  const handleFetchFile = (values) => {
    dispatch(fetchFile(values));
  };

  const handleFetchFolder = (values) => {
    dispatch(fetchFolder(values));
  };

  const handleSearch = (values) => {
    dispatch(fetchDocumentsWithSearch(values));
  };

  const handleFolderSearch = (values) => {
    dispatch(fetchDocumentsWithSearchAndFolder(values));
  };

  const handleCreateDocument = (accountCode, values) => {
    dispatch(createDocument(accountCode, values));
  };

  const handleUploadDocument = (accountCode, file) => {
    dispatch(uploadDocument(accountCode, file));
  };

  const handleDeleteDocuments = (accountCode, docIdsArray) => {
    dispatch(deleteDocuments(accountCode, docIdsArray));
  };

  const handleUpdateDocument = (accountCode, docId, values) => {
    dispatch(updateDocument(accountCode, docId, values));
  };

  const handleFetchUserGroups = (accountCode) => {
    dispatch(fetchUserGroups(accountCode));
  };

  const handleFetchFolders = (accountCode) => {
    dispatch(fetchFolders(accountCode));
  };

  return {
    handleFetchDocuments,
    handleFetchDocument,
    handleFetchFile,
    handleFetchFolder,
    handleSearch,
    handleCreateDocument,
    handleUploadDocument,
    handleDeleteDocuments,
    handleUpdateDocument,
    handleFetchUserGroups,
    handleFetchFolders,
    handleFolderSearch,
    currentUser,
    documents,
    folderDocuments,
    isDocumentsLoading,
    uploadedDocument,
    updatedDocument,
    userGroups,
    currentLocation,
    currentFile,
    visibleFolders,
    isDocumentUploading,
    uploadDocumentError,
    fetchedDocument,
    fetchDocumentError,
  };
};

export default useDocuments;
