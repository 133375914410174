import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_FULFILLED,
  FETCH_DOCUMENTS_REJECTED,
  FETCH_FOLDER_START,
  FETCH_FOLDER_FULFILLED,
  FETCH_FOLDER_REJECTED,
  UPLOAD_DOCUMENT_START,
  UPLOAD_DOCUMENT_FULFILLED,
  UPLOAD_DOCUMENT_REJECTED,
} from './actionTypes';

const initialState = {
  isDocumentsLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_START: {
      return {
        ...state,
        isDocumentsLoading: true,
      };
    }
    case FETCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case FETCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case FETCH_FOLDER_START: {
      return {
        ...state,
        isDocumentsLoading: true,
      };
    }
    case FETCH_FOLDER_FULFILLED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case FETCH_FOLDER_REJECTED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case UPLOAD_DOCUMENT_START: {
      return {
        ...state,
        isDocumentUploading: true,
      };
    }
    case UPLOAD_DOCUMENT_FULFILLED: {
      return {
        ...state,
        isDocumentUploading: false,
      };
    }
    case UPLOAD_DOCUMENT_REJECTED: {
      return {
        ...state,
        isDocumentUploading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
