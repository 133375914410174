import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';

import Select from 'react-select';
import Button from '../../../../shared/components/Button/Button';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import useDocuments from '../hooks/useDocuments';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const RightWrapper = styled('div')`
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  .formik-form {
    width: 100%;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const LeftWrapper = styled('div')`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: right;
  height: 100%;

  .icon-text {
    font-weight: bold;
    margin-top: 10px;
    font-size: 16px;
  }
`;

const IconTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const URLWrapper = styled('div')`
  width: 90%;
  margin-bottom: 37px;
`;


const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const FormGroup = styled('div')`
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    /* margin-top: 30px; */
    margin-bottom: 15px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .folder-text {
    font-weight: bold;
  }
  .group-access-text {
    font-weight: bold;
    margin-top: 8px;
  }
`;

const ModalUpdateDocument = ({
  setActiveModal,
  handleUpdateDocument,
  currentUser,
  document,
  onRequestClose,
  currentFolder,
}) => {
  const {
    handleFetchUserGroups,
    handleFetchFolders,
    userGroups,
    visibleFolders,
  } = useDocuments();

  let defaultDocumentGroupIds;
  if (document && document.userGroupAccess && document.userGroupAccess) {
    defaultDocumentGroupIds = document.userGroupAccess.map(group => group.id);
  }

  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedGroups, setSelectedGroups] = useState(defaultDocumentGroupIds.length ? defaultDocumentGroupIds : []);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
    }
    if (!currentFolder && !visibleFolders.length) {
      handleFetchFolders(accountCode);
    }
  }, []);

  const iconResolver = () => {
    if (document.type === 0) {
      return 'folder';
    }
    if (document.type === 1) {
      return 'save';
    }
    if (document.type === 2) {
      return 'globe';
    }
    return null;
  };

  const updateText = () => {
    if (document.type === 0) {
      return 'FOLDER';
    }
    if (document.type === 1) {
      return 'FILE';
    }
    if (document.type === 2) {
      return 'LINK';
    }
    return null;
  };

  const handleSubmit = (values) => {
    const newValues = values;
    if (!newValues.groups.length && (currentFolder && currentFolder.userGroupAccess)) {
      newValues.groups = currentFolder.userGroupAccess;
    }
    if (!newValues.groups.length && (selectedFolder && selectedFolder.userGroupAccess)) {
      newValues.groups = selectedFolder.userGroupAccess;
    }
    if (!newValues.linkUrl) {
      delete newValues.linkUrl;
    }
    const groupIds = values.groups.map(group => group.id);
    newValues.groups = groupIds;
    handleUpdateDocument(currentUser.accountCode, document.id, newValues);
  };

  const groupsRefiner = () => {
    if (selectedFolder && selectedFolder.id === 0) {
      return userGroups;
    }
    if (currentFolder && userGroups) {
      if (currentFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const userGroupIdsArray = userGroups.map(group => group.id);
      const acceptedGroups = [];
      currentFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    if ((selectedFolder && Object.keys(selectedFolder).length) && userGroups) {
      if (selectedFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const userGroupIdsArray = userGroups.map(group => group.id);
      const acceptedGroups = [];
      selectedFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    return userGroups;
  };

  const foldersRefiner = () => {
    const returnFolders = [];
    if (selectedGroups.length) {
      visibleFolders.forEach((folder) => {
        const folderGroupIds = folder.userGroupAccess.map(group => group.id);
        let add = false;
        for (let i = 0; i < selectedGroups.length; i += 1) {
          if (!folderGroupIds.includes(selectedGroups[i])) {
            add = false;
            break;
          }
          add = true;
        }
        if (add) {
          returnFolders.push(folder);
        }
      });
      return returnFolders;
    }
    return visibleFolders;
  };

  const displayGroups = groupsRefiner();
  const displayFolders = currentFolder ? [currentFolder, { id: 0, name: 'Main Directory', type: 0 }] : foldersRefiner();

  const areGroupsEditable = () => {
    let editable = false;
    if (document && userGroups) {
      if (userGroups && !userGroups.length) {
        return editable;
      }
      const documentGroupIds = document.userGroupAccess.map(group => group.id);
      const accessibleGroupIds = userGroups.map(group => group.id);
      for (let i = 0; i < documentGroupIds.length; i += 1) {
        if (!accessibleGroupIds.includes(documentGroupIds[i])) {
          editable = false;
          break;
        }
        editable = true;
      }
    }
    return editable;
  };

  return (
    <ModalWrapper>
      <Formik
        initialValues={{
          name: document.name,
          description: document.description,
          groups: document.userGroupAccess,
          folderId: currentFolder ? currentFolder.id : 0,
          linkUrl: document.url,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values);
            setSubmitting(false);
            setActiveModal(false);
          });
        }}
        render={({
          submitForm,
          setFieldValue,
          values,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapper>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapper>
              <FormWrapper>
                <LeftWrapper>
                  <IconTextWrapper>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={75}
                      icon={iconResolver()}
                    />
                    <Text className='icon-text'>
                      UPDATE
                      {' '}
                      {updateText()}
                    </Text>
                  </IconTextWrapper>
                  {document.type === 2 ? (
                    <URLWrapper>
                      <FormGroup>
                        <TitlePlusErrorWrapper>
                          <Text><strong>URL</strong></Text>
                        </TitlePlusErrorWrapper>
                        <Field
                          className='form-control'
                          id='url-field'
                          name='linkUrl'
                          placeholder='https://domain.com'
                        />
                      </FormGroup>
                    </URLWrapper>
                  ) : null}
                </LeftWrapper>
                <RightWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <Text><strong>Name</strong></Text>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Document Name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text><strong>Description</strong></Text>
                      <Field
                        className='form-control'
                        id='description-field'
                        name='description'
                        placeholder='Document Description'
                      />
                    </FormGroup>
                    {document.type !== 0 ? (
                      <FormGroup>
                        <Text className='folder-text'>Folder</Text>
                        <Select
                          defaultValue={values.folderId}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          options={displayFolders}
                          onChange={(folder) => {
                            setSelectedFolder(folder);
                            if (folder) {
                              setFieldValue('folderId', folder.id);
                            }
                          }}
                          isOptionSelected={folder => values.folder === folder}
                          isClearable
                        />
                      </FormGroup>
                    ) : null}
                    <FormGroup>
                      <Text className='group-access-text'>Group Access</Text>
                      <Select
                        defaultValue={values.groups}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={displayGroups}
                        onChange={(options) => {
                          const groupIds = options.map(group => group.id);
                          setSelectedGroups(groupIds);
                          setFieldValue('groups', options);
                        }}
                        isDisabled={!areGroupsEditable()}
                        isOptionSelected={group => values.group === group}
                        isClearable
                        isMulti
                      />
                    </FormGroup>
                  </Form>
                </RightWrapper>
              </FormWrapper>
            </AnotherWrapper>
            <Button
              bottom
              fullWidth
              cta='Submit'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={() => submitForm()}
            />
          </ModalWrapper>
        )}
      />
    </ModalWrapper>
  );
};

ModalUpdateDocument.propTypes = {
  setActiveModal: PropTypes.func.isRequired,
  handleUpdateDocument: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  document: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  // eslint-disable-next-line react/require-default-props
  currentFolder: PropTypes.oneOfType(
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ),
};

export default ModalUpdateDocument;
