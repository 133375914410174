import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import authUiReducer from './loginUiReducer';
import authDataReducer from './loginDataReducer';

const authDataPersistConfig = {
  key: 'data',
  storage,
  stateReconciler: hardSet,
};

export default combineReducers({
  ui: authUiReducer,
  data: persistReducer(authDataPersistConfig, authDataReducer),
});
