import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import useInterval from '@use-it/interval';
import Logger from 'js-logger';

import LeaderboardTable from './BoardTable';
import Spinner from '../../../shared/components/Spinner/Spinner';
import SubText from '../../../shared/components/SubText/SubText';
import useBoard from './hooks/useBoard';
import useLeaderboard from './hooks/useLeaderboard';

const LoadMoreContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
`;

const BoardTableStyles = {
  overflow: 'scroll',
  flexGrow: 1,
  paddingBottom: '30px',
};

const LeaderboardTableWrapper = ({
  activeBoards,
  id,
  windowSize,
}) => {
  const tableRef = useRef();
  const tableContainerRef = useRef();
  const [tableHeight, setTableHeight] = useState();
  const [tableContainerHeight, setTableContainerHeight] = useState(0);
  const [tableScroll, setTableScroll] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [timesCalculatingHeight, setTimesCalculatingHeight] = useState(0);

  const {
    leaderboardData,
    leaderboardForm,
    leaderboardSettings,
    isEndOfList,
    isLeaderboardLoading,
  } = useBoard(id);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      (activeBoards === 1)
        ? leaderboardData.slice(3)
        : leaderboardData.slice(1),
    );
  }, [activeBoards, leaderboardData]);

  useEffect(() => {
    setData(
      (activeBoards !== 1 || windowSize.width <= 768)
        ? leaderboardData.slice(1)
        : leaderboardData.slice(3),
    );
  }, [windowSize]);

  const {
    autoScroll,
  } = leaderboardSettings;

  const {
    handleFetchLeaderboard,
  } = useLeaderboard();

  // React Spring animations for loading more, and auto scrolling.
  // Scrolling animation works by setting the scroll location from the tableScroll state
  const loadTransition = useSpring({
    position: 'absolute',
    opacity: isLeaderboardLoading ? 1 : 0,
  });

  const endTransition = useSpring({
    position: 'absolute',
    opacity: isEndOfList ? 1 : 0,
  });

  const autoScrollTransition = useSpring({
    scroll: tableScroll,
    from: { scroll: 0 },
  });

  // Auto Scroll functionality using custom useInterval hook.
  // The interval sets the next scroll location by adding the previous
  // scroll location (tableScroll) to the new one
  // (I.E. half of table container height) every 5 seconds.
  // The spring animation automatically goes to the updated scroll state.
  useInterval(() => {
    setTableScroll(tableScroll + (tableContainerHeight / 2));
  }, !autoScroll ? null : 5000);

  // Set table container height on component load.
  useEffect(() => {
    setTableContainerHeight(tableContainerRef.current && tableContainerRef.current.offsetHeight);
    setTimesCalculatingHeight(timesCalculatingHeight + 1);
    // Logger.debug('How many times am I changing the height?', timesCalculatingHeight + 1);
  }, [tableContainerRef.current && tableContainerRef.current.offsetHeight]);

  // INFINITE SCROLL VARIABLES:

  // Margins in the page
  const tableMargins = 30;

  // Initial Scroll location using react refs.
  const initialScrollLocation = tableRef.current ? tableRef.current.offsetTop : 0;

  // tableEnd is the location of the end of the list by subtracting the initial scroll location
  // from the table list height, then adding the container height and subtracting both margins.
  const tableEnd = (initialScrollLocation - tableHeight)
    + tableContainerHeight - (tableMargins * 2) + 10;

  // Load More function which fetches the next leaderboard page if it's not the end of the list.
  const loadMore = () => {
    if (!isEndOfList) {
      handleFetchLeaderboard(leaderboardForm, 'loadMore', id);
    }
  };

  // Contd. Auto Scroll
  // If the board is at the end of the list, move the auto scroll location to 0 or the top.
  useEffect(() => {
    if (autoScroll === true && isEndOfList && (currentScroll <= tableEnd)) {
      setTimeout(() => {
        setTableScroll(0);
        setCurrentScroll(0);
      }, 1500);
      Logger.debug('Were moving up!');
    }
  }, [tableScroll]);

  return (
    <animated.div
      ref={tableContainerRef}
      scrollTop={autoScrollTransition.scroll}
      style={BoardTableStyles}
    >
      <LeaderboardTable
        activeBoards={activeBoards}
        handleLoadMore={loadMore}
        leaderboardData={data}
        leaderboardForm={leaderboardForm}
        id={id}
        tableEnd={tableEnd}
        tableRef={tableRef}
        tableContainerHeight={tableContainerHeight}
        tableHeight={tableHeight}
        setTableHeight={setTableHeight}
        setCurrentScroll={setCurrentScroll}
        windowSize={windowSize}
      />
      <LoadMoreContainer>
        <animated.div style={loadTransition}>
          <Spinner
            saving
            darkTheme
          />
        </animated.div>
        <animated.div style={endTransition}>
          <SubText>
            End of Leaderboard
          </SubText>
        </animated.div>
      </LoadMoreContainer>
    </animated.div>
  );
};

LeaderboardTableWrapper.propTypes = {
  activeBoards: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default LeaderboardTableWrapper;
