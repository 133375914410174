import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring, useChain } from 'react-spring';
import { useSelector } from 'react-redux';

import useLeaderboard from './hooks/useLeaderboard';

import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import SubText from '../../../shared/components/SubText/SubText';
import IconRound from '../../../shared/components/IconRound/IconRound';
import Button from '../../../shared/components/Button/Button';
import { BoxShadow } from '../../../shared/GlobalStyles';

const DefaultViewContainer = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  .icon {
    margin-right: 115px;

    &:last-of-type {
      margin-right: 0px;
    }
    &.middle {
      transform: scale(6);
    }
  }
  @media screen and (max-width: 540px) {
    height: auto;
    .icon {
      margin-right: 95px;
      transform: scale(4);
      
      &.middle {
        transform: scale(5);
      }
    }
  }
  @media screen and (max-width: 375px) {
    .icon {
      margin-right: 75px;
      transform: scale(3);
      
      &.middle {
        transform: scale(4);
      }
    }
  }
`;

const IconContainer = styled('div')`
  display: flex;
  flex-direction: row;
  height: 150px;
  justify-content: center;
  margin-bottom: 30px;

  .logo-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
  }
`;

const DefaultContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: auto;
  align-self: center;
  padding: 60px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  border-radius: 4px;
  min-width: 698px;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    padding: 30px;
  }
`;

const TextContainer = styled('div')`
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }

  button {
    margin: 0 auto;
    margin-top: 30px;
  }
`;

const RecentLeaderboards = styled('div')`
  margin-top: 60px;

  h1 {
    text-transform: capitalize;
  }
`;

const BoardList = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  flex-wrap: wrap;
`;

const RecentBoardCard = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex: none; */
  width: 200px;
  max-width: 200px;
  min-width: 150px;
  height: 150px;
  /* max-width: 200px; */
  margin-right: 15px;
  background: linear-gradient(134deg, #FF9A44 -8%, #FC6076 200%);
  border-radius: 4px;
  padding: 15px;
  flex-wrap: none;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: ${BoxShadow};
  flex: 1;

  @media screen and (max-width: 768px) {
    flex: none;
    width: 180px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }
  p {
    font-size: 22px;
  }
`;

const LeaderboardDefaultView = ({
  addBoard,
  leaderboardsSelector,
}) => {
  const {
    handleFetchLeaderboard,
  } = useLeaderboard();

  const recentLeaderboards = useSelector(state => state.leaderboard.data.recentLeaderboards);

  const firstIconRef = useRef();
  const firstAnimatedIcon = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: firstIconRef,
  });

  const secondIconRef = useRef();
  const secondAnimatedIcon = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: secondIconRef,
  });

  const thirdIconRef = useRef();
  const thirdAnimatedIcon = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: thirdIconRef,
  });

  useChain([firstIconRef, secondIconRef, thirdIconRef], [0.1, 0.4, 0.7]);

  const loadRecentBoard = (values, actionType) => {
    handleFetchLeaderboard(values, actionType);
  };

  return (
    <DefaultViewContainer>
      <DefaultContent>
        <animated.div className='logo-container' style={{ ...firstAnimatedIcon }}>
          <IconContainer>
            <IconRound
              icon='ribbon'
              transparent
              jumbo
            />
            <IconRound
              icon='trophy'
              className='icon middle'
              transparent
              jumbo
            />
            <IconRound
              icon='ribbon'
              transparent
              jumbo
            />
          </IconContainer>
        </animated.div>

        <animated.div className='logo-container' style={{ ...secondAnimatedIcon }}>
          <TextContainer>
            <HeaderText
              // fontWeight='300'
              letterSpacing='normal'
            >
              Leaderboards
            </HeaderText>
            <Text>
              {/* Create up to 3 athlete leaderboards from specific exercises. */}

              Select an exercise below to start creating leaderboards.
            </Text>
            <Button
              cta='Add Board'
              customColor='transparent'
              onClick={addBoard}
            />
          </TextContainer>
        </animated.div>
        <animated.div className='logo-container' style={{ ...thirdAnimatedIcon }}>
          {leaderboardsSelector(recentLeaderboards).length !== 0 && (
            <RecentLeaderboards>
              <SubText
                // fontWeight={300}
                // fontSize={22}
              >
                Recent Leaderboards
              </SubText>
              <BoardList>
                {leaderboardsSelector(recentLeaderboards).map(board => (
                  <RecentBoardCard
                    onClick={() => loadRecentBoard(board.leaderboardForm, 'add')}
                  >
                    <SubText
                      color='white'
                    >
                      {board.leaderboardForm.category === 'L' && ('Lift')}
                      {board.leaderboardForm.category === 'C' && ('Saq + C')}
                      {board.leaderboardForm.category === 'R' && ('Circuit')}
                    </SubText>
                    <Text
                      color='white'
                    >
                      {board.leaderboardForm.exerciseName}
                    </Text>
                  </RecentBoardCard>
                ))}
              </BoardList>
            </RecentLeaderboards>
          )}
        </animated.div>
      </DefaultContent>
    </DefaultViewContainer>
  );
};

LeaderboardDefaultView.propTypes = {
  addBoard: PropTypes.func.isRequired,
  leaderboardsSelector: PropTypes.func.isRequired,
};

export default LeaderboardDefaultView;
