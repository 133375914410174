import {
  CLEAR_USER,
  FETCH_USER_FULFILLED,
  SUBMIT_LOGOUT_FULFILLED,
} from './actionTypes';

const initialState = {
  currentUser: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case FETCH_USER_START: {
    //   return {
    //     ...state,
    //     isLoginLoading: true,
    //   };
    // }
    case FETCH_USER_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case SUBMIT_LOGOUT_FULFILLED: {
      return {
        ...state,
        currentUser: {},
      };
    }
    case CLEAR_USER: {
      return {
        ...state,
        currentUser: {},
      };
    }
    // case FETCH_USER_REJECTED: {
    //   return {
    //     ...state,
    //     isLoginLoading: false,
    //     isAuthenticated: false,
    //   };
    // }
    default: {
      return {
        ...state,
      };
    }
  }
}
