import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import fitvids from 'fitvids';

import ButtonRound from './ButtonRound';

const NoteContainer = styled('div')`
  padding: 0px 30px;

  /* @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 160px;
    overflow: scroll;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
      pointer-events: none;
    }
  } */
`;

const NoteCopy = styled('p')`
  font-size: 13px;
  color: ${props => props.theme.textColor};
  white-space: pre-line;
`;

fitvids();

const NoteEntries = ({
  info,
}) => (
  <NoteContainer>
    <NoteCopy>
      {info}
    </NoteCopy>
  </NoteContainer>
);

NoteEntries.propTypes = {
  info: PropTypes.string.isRequired,
};

export default NoteEntries;
