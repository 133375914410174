import update from 'immutability-helper';

import {
  CLOSE_PANEL,
  CLOSE_WORKOUT,
  CLOSE_MODAL,
  COMPLETE_WORKOUT,
  FETCH_HISTORY_START,
  FETCH_HISTORY_FULFILLED,
  FETCH_HISTORY_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_SIM_EXERCISES_START,
  FETCH_SIM_EXERCISES_FULFILLED,
  FETCH_SIM_EXERCISES_REJECTED,
  FETCH_SIM_TYPE_EXERCISES_START,
  FETCH_SIM_TYPE_EXERCISES_FULFILLED,
  FETCH_SIM_TYPE_EXERCISES_REJECTED,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_START,
  FETCH_WORKOUTS_REJECTED,
  FETCH_WORKOUT_FULFILLED,
  FETCH_WORKOUT_START,
  FETCH_WORKOUT_REJECTED,
  NEXT_QUESTION,
  NEXT_WORKOUT,
  OPEN_MODAL,
  PREV_QUESTION,
  PREV_WORKOUT,
  RESTART_QUESTIONS,
  SEARCH_ATHLETE,
  SELECT_GROUP,
  SELECT_ATHLETE,
  SELECT_VERIFIED_ATHLETE,
  SELECT_QUESTION,
  SELECT_WORKOUT,
  SELECT_DATE,
  STORE_PANEL_HEIGHT,
  UPDATE_JOURNAL_FULFILLED,
  UPDATE_WORKOUTS_START,
  UPDATE_WORKOUTS_FULFILLED,
  UPDATE_WORKOUTS_REJECTED,
  UPDATE_SUBSTITUTE_WORKOUT,
  FETCH_CALENDAR_REJECTED,
  FETCH_CALENDAR_FULFILLED,
  FETCH_JOURNAL_START,
  FETCH_JOURNAL_FULFILLED,
  FETCH_JOURNAL_REJECTED,
  SUBMIT_PIN_START,
  SUBMIT_PIN_FULFILLED,
  SUBMIT_PIN_REJECTED,
  OPEN_PINPAD,
  CLOSE_PINPAD,
  CANCEL_PINPAD,
  STORE_FORMIK_VALUES,
  STORE_WORKOUTS_BY_SUPERSET,
  INCREASE_COMPLETED_SETS,
  DECREASE_COMPLETED_SETS,
  SET_IS_NAVIGATING_SUPERSETS,
} from './actionTypes';


const initialState = {
  athleteChosen: false,
  athleteSearch: '',
  activeAthlete: '',
  activeModal: '',
  activeDate: {},
  activeWorkout: null,
  activeExerId: 0,
  activeExerType: '',
  activeQuestionId: '',
  activeQuestionIndex: 0,
  activeWorkoutIndex: null,
  athleteId: '',
  athleteAvatar: '',
  exercises: {},
  groupFilter: '',
  isDimensionSet: false,
  isLoading: false,
  isLoadingHistory: false,
  isLoadingExercises: false,
  isModalOpen: false,
  isNavigatingSuperset: false,
  isPinPadOpen: false,
  isPinRejected: false,
  isPinVerified: false,
  isWorkoutActive: false,
  isWorkoutComplete: false,
  isWorkoutLoading: false,
  isWorkoutSaving: false,
  panelDimensions: {},
  totalFormikValues: 0,
  workoutCalendar: [],
  workoutDocument: '',
  workoutHistory: {},
  workouts: {},
  workoutsError: '',
  workoutsBySuperset: {},
};

// export default function reducer(state = initialState, action) {
//   switch (action.type) {
//     case `panel1/${SELECT_ATHLETE}`: {
//       return {
//         ...state,
//         athleteChosen: true,
//         activeAthlete: action.payload,
//         athleteId: action.payload.id,
//       };
//     }
//     default: {
//       return {
//         ...state,
//       };
//     }
//   }
// }

export default function createPanelReducer(namespace = '') {
  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `${namespace}/${SELECT_ATHLETE}`: {
        return {
          ...state,
          athleteChosen: true,
          activeAthlete: action.payload.athlete,
          activeDate: action.payload.today,
          athleteId: action.payload.id,
          athleteAvatar: action.payload.avatar,
          isPinVerified: true,
        };
      }
      case `${namespace}/${SELECT_VERIFIED_ATHLETE}`: {
        return {
          ...state,
          athleteChosen: true,
        };
      }
      case `${namespace}/${SEARCH_ATHLETE}`: {
        return {
          ...state,
          athleteSearch: action.payload,
        };
      }
      // case `${namespace}/${SELECT_GROUP}`: {
      //   return {
      //     ...state,
      //     groupFilter: action.payload,
      //   };
      // }
      case `${namespace}/${FETCH_WORKOUTS_START}`: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case `${namespace}/${FETCH_WORKOUTS_FULFILLED}`: {
        return {
          ...state,
          isLoading: false,
          workoutData: action.payload,
          workouts: action.payload.result.dates[0].items,
          workoutsError: '',
          workoutCount: action.payload.result.dates[0].info.count,
        };
      }
      case `${namespace}/${FETCH_WORKOUTS_REJECTED}`: {
        return {
          ...state,
          isLoading: false,
          workouts: [],
          workoutsError: action.payload.headers.message,
        };
      }
      case `${namespace}/${FETCH_WORKOUT_START}`: {
        return {
          ...state,
          isWorkoutLoading: true,
        };
      }
      case `${namespace}/${FETCH_WORKOUT_FULFILLED}`: {
        return update(state, {
          workouts: {
            [action.payload.index]: {
              set_list: {
                $set: action.payload.data.set_list,
              },
              tracking: {
                $set: action.payload.data.tracking,
              },
            },
          },
          isWorkoutLoading: {
            $set: false,
          },
        });
      }
      case `${namespace}/${FETCH_CALENDAR_REJECTED}`: {
        return {
          ...state,
          workoutCalendar: action.payload,
        };
      }
      case `${namespace}/${FETCH_CALENDAR_FULFILLED}`: {
        return {
          ...state,
          workoutCalendar: action.payload.result.dates,
        };
      }
      case `${namespace}/${FETCH_HISTORY_START}`: {
        return {
          ...state,
          isLoadingHistory: true,
        };
      }
      case `${namespace}/${FETCH_HISTORY_FULFILLED}`: {
        return {
          ...state,
          isLoadingHistory: false,
          workoutHistory: action.payload,
        };
      }
      case `${namespace}/${FETCH_HISTORY_REJECTED}`: {
        return {
          ...state,
          isLoadingHistory: false,
          workoutHistory: action.payload,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_START}`: {
        return {
          ...state,
          isLoadingDocument: true,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_FULFILLED}`: {
        return {
          ...state,
          isLoadingDocument: false,
          workoutDocument: action.payload,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_REJECTED}`: {
        return {
          ...state,
          isLoadingDocument: false,
          workoutDocument: action.payload,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingExercises: true,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingExercises: false,
          exercises: action.payload.result.options,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingExercises: false,
          exercises: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingSimExercises: true,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exerciseSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingSimExercises: true,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_JOURNAL_START}`: {
        return {
          ...state,
          isLoadingJournal: true,
        };
      }
      case `${namespace}/${FETCH_JOURNAL_FULFILLED}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              journalEntry: {
                $set: action.payload.data,
              },
            },
          },
        });
      }
      case `${namespace}/${FETCH_JOURNAL_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${SELECT_WORKOUT}`: {
        return {
          ...state,
          activeQuestionIndex: 0,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: action.payload.index,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
          isWorkoutActive: true,
        };
      }
      case `${namespace}/${NEXT_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: state.activeWorkoutIndex + 1,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
        };
      }
      case `${namespace}/${PREV_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: state.activeWorkoutIndex - 1,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
        };
      }
      case `${namespace}/${UPDATE_WORKOUTS_START}`: {
        return {
          ...state,
          isWorkoutSaving: true,
        };
      }
      case `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              $merge: action.payload.data,
            },
          },
        });
      }
      case `${namespace}/${UPDATE_WORKOUTS_REJECTED}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workoutsError: {
            $set: action.payload.data,
          },
        });
      }
      case `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`: {
        return update(state, {
          workouts: {
            [action.payload.index]: {
              exer_name: {
                $set: action.payload.subExerName,
              },
              exer_id: {
                $set: action.payload.subId,
              },
              attributes: {
                $merge: action.payload.subAttributes,
              },
            },
          },
        });
      }
      case `${namespace}/${UPDATE_JOURNAL_FULFILLED}`: {
        return update(state, {
          workouts: {
            [action.payload.index]: {
              result: {
                opt_text: {
                  $set: action.payload.data.result.entry_text,
                },
              },
            },
          },
        });
      }
      case `${namespace}/${COMPLETE_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: null,
          activeWorkoutIndex: null,
          isWorkoutActive: false,
          isWorkoutComplete: true,
        };
      }
      case `${namespace}/${SUBMIT_PIN_START}`: {
        return {
          ...state,
          isPinLoading: true,
          isPinVerified: action.payload,
        };
      }
      case `${namespace}/${SUBMIT_PIN_FULFILLED}`: {
        return {
          ...state,
          isPinLoading: false,
          isPinVerified: action.payload.verified,
        };
      }
      case `${namespace}/${SUBMIT_PIN_REJECTED}`: {
        return {
          ...state,
          isPinLoading: false,
          isPinRejected: true,
          isPinVerified: false,
        };
      }
      case `${namespace}/${SELECT_DATE}`: {
        return {
          ...state,
          activeDate: action.payload,
          activeWorkout: null,
          workoutsBySuperset: {},
        };
      }
      case `${namespace}/${CLOSE_WORKOUT}`: {
        return {
          ...state,
          activeQuestionIndex: null,
          activeWorkout: null,
          activeWorkoutIndex: null,
          isWorkoutActive: false,
          isWorkoutComplete: false,
        };
      }
      case `${namespace}/${CLOSE_PANEL}`: {
        return {
          ...state,
          athleteChosen: false,
          activeAthlete: '',
          activeQuestionIndex: null,
          activeWorkout: null,
          activeWorkoutIndex: null,
          athleteId: '',
          athleteAvatar: '',
          isWorkoutComplete: false,
          isWorkoutActive: false,
          workoutCount: {},
          workoutCalendar: [],
          // workoutsBySuperset: {},
        };
      }
      case `${namespace}/${OPEN_MODAL}`: {
        return {
          ...state,
          isModalOpen: true,
          activeModal: action.payload,
        };
      }
      case `${namespace}/${CLOSE_MODAL}`: {
        return {
          ...state,
          isModalOpen: false,
        };
      }
      case `${namespace}/${OPEN_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: true,
          activeAthlete: action.payload.name,
          activeDate: action.payload.today,
          athleteId: action.payload.id,
          athleteAvatar: action.payload.avatar,
        };
      }
      case `${namespace}/${CLOSE_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: false,
        };
      }
      case `${namespace}/${CANCEL_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: false,
          activeAthlete: '',
          athleteId: '',
          athleteAvatar: '',
        };
      }
      case `${namespace}/${SELECT_QUESTION}`: {
        return {
          ...state,
          activeQuestionId: action.payload.id,
          activeQuestionIndex: action.payload.index,
        };
      }
      case `${namespace}/${NEXT_QUESTION}`: {
        return {
          ...state,
          activeQuestionIndex: state.activeQuestionIndex + 1,
        };
      }
      case `${namespace}/${PREV_QUESTION}`: {
        return {
          ...state,
          activeQuestionIndex: state.activeQuestionIndex - 1,
        };
      }
      case `${namespace}/${RESTART_QUESTIONS}`: {
        return {
          ...state,
          activeQuestionIndex: 0,
          activeQuestionId: null,
        };
      }
      case `${namespace}/${STORE_PANEL_HEIGHT}`: {
        return {
          ...state,
          panelDimensions: {
            height: action.payload.height,
            width: action.payload.width,
          },
          isDimensionSet: true,
        };
      }
      case `${namespace}/${STORE_FORMIK_VALUES}`: {
        return {
          ...state,
          totalFormikValues: action.payload,
        };
      }
      case `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`: {
        return {
          ...state,
          workoutsBySuperset: action.payload,
        };
      }
      case `${namespace}/${INCREASE_COMPLETED_SETS}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workoutsBySuperset: {
            [action.payload.group]: {
              [action.payload.workoutIndex]: {
                completed_sets: {
                  $set: state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets !== state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].sets
                    ? state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets + 1
                    : state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets,
                },
              },
            },
          },
        });
      }
      case `${namespace}/${DECREASE_COMPLETED_SETS}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workoutsBySuperset: {
            [action.payload.group]: {
              [action.payload.workoutIndex]: {
                completed_sets: {
                  $set: state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets > 0
                    ? state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets - 1
                    : 0,
                },
              },
            },
          },
        });
      }
      case `${namespace}/${SET_IS_NAVIGATING_SUPERSETS}`: {
        return {
          ...state,
          isNavigatingSuperset: action.payload,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };
}
