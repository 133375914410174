import update from 'immutability-helper';
import generateUniqueId from '../../../shared/utils/generateUniqueId';

import {
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_LEADERBOARD_FULFILLED,
  FETCH_LEADERBOARD_REJECTED,
  FETCH_NEXT_PAGE_FULFILLED,
  FETCH_NEXT_PAGE_START,
  FETCH_NEXT_PAGE_REJECTED,
  EDIT_LEADERBOARD_FULFILLED,
  EDIT_LEADERBOARD_REJECTED,
  CLOSE_BOARD,
  UPDATE_SETTING,
} from './actionTypes';

const initialState = {
  exercises: [],
  exercisesError: '',
  groups: [],
  groupsError: '',
  leaderboards: {},
  recentLeaderboards: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        exercises: action.payload,
      };
    }
    case FETCH_EXERCISES_REJECTED: {
      return {
        ...state,
        exercisesError: action.payload,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groupsError: action.payload,
      };
    }
    case FETCH_LEADERBOARD_FULFILLED: {
      const leaderboardId = generateUniqueId();

      return update(state, {
        leaderboards: {
          $merge: {
            [leaderboardId]: {
              id: leaderboardId,
              leaderboardData: action.payload.data,
              leaderboardExercise: action.payload.exerciseName,
              leaderboardForm: action.payload.values,
              page: 1,
              settings: {
                autoScroll: false,
                autoLoad: false,
                loadTime: { value: 60000, label: 'One Minute' },
              },
            },
          },
        },
        recentLeaderboards: {
          $merge: {
            [leaderboardId]: {
              id: leaderboardId,
              leaderboardData: [],
              leaderboardExercise: action.payload.exerciseName,
              leaderboardForm: action.payload.values,
              page: 1,
              settings: {
                autoScroll: false,
                autoLoad: false,
                loadTime: { value: 60000, label: 'One Minute' },
              },
            },
          },
        },
      });
    }
    case EDIT_LEADERBOARD_FULFILLED: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            $set: {
              id: action.payload.id,
              leaderboardData: action.payload.data,
              leaderboardExercise: action.payload.exerciseName,
              leaderboardForm: action.payload.values,
              page: 1,
              settings: {
                ...state.leaderboards[action.payload.id].settings,
              },
            },
          },
        },
      });
    }
    case FETCH_NEXT_PAGE_START: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            isLeaderboardLoading: {
              $set: true,
            },
          },
        },
      });
    }
    case FETCH_NEXT_PAGE_FULFILLED: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            leaderboardData: {
              $push: action.payload.data,
            },
            page: {
              $set: action.payload.page,
            },
            isEndOfList: {
              $set: action.payload.data.length === 0,
            },
            isLeaderboardLoading: {
              $set: false,
            },
          },
        },
      });
    }
    case CLOSE_BOARD: {
      return update(state, {
        leaderboards: {
          $unset: [action.payload.id],
        },
      });
    }
    case UPDATE_SETTING: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            settings: {
              [action.payload.setting]: {
                $set: action.payload.value,
              },
            },
          },
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
