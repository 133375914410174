import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import LogRocket from 'logrocket';
import Logger from 'js-logger';
import { Helmet } from 'react-helmet';

import hexToHSL from '../../../shared/utils/hexToHSL';
import Header from './presentational/Header';
import Spinner from './presentational/Spinner';
import WeightroomPanel from './container/WeightroomPanel';
import PayGate from './presentational/PayGate';
import PanelSettings from './presentational/PanelSettings';

const WeightRoomContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const PanelsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const SpinnerContainer = styled('div')`
  height: 100vh;
`;

const WeightroomView = ({
  activeScreens,
  closeGlobalModal,
  currentUser,
  fetchUsers,
  fetchedGroups,
  fetchedCurrentUser,
  handleScreenToggle,
  isDarkThemeEnabled,
  openGlobalModal,
  skin,
  userInfo,
}) => {
  const darkTheme = {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    backgroundColorL2: 'rgb(65, 65, 65)',
    backgroundColorL3: 'rgb(95, 95, 95)',
    borderColor: 'rgb(100, 100, 100)',
    buttonBorderColor: 'rgb(65, 65, 65)',
    buttonBorderColor2: 'rgb(65, 65, 65)',
    focusColor: '#0ab3cd',
    noBorderColor: 'rgb(58, 58, 58)',
    textColor: 'rgba(255, 255, 255, 0.80)',
    lightTextColor: 'rgba(255, 255, 255, 0.75)',
    lightTextColor2: 'rgba(255, 255, 255, 0.65)',
    overlayColor: 'rgba(30, 30, 30, 0.6)',
    noBorderColorForm: 'rgb(95, 95, 95)',
    iconBorderColor: 'rgba(65, 65, 65)',
    overflowGradient: 'linear-gradient(to right,rgba(36,36,36,0),rgba(36,36,36,1))',
  };

  const lightTheme = {
    backgroundColor: 'white',
    backgroundColorL2: 'white',
    backgroundColorL3: 'white',
    buttonBorderColor: '#979797',
    buttonBorderColor2: '#acacac',
    borderColor: '#e4e7e7',
    noBorderColor: '#979797',
    focusColor: '#0dcc8a',
    textColor: '#444444',
    lightTextColor: '#979797',
    lightTextColor2: '#686868',
    overlayColor: 'rgba(216, 216, 216, 0.5)',
    noBorderColorForm: 'hsl(0,0%,80%)',
    iconBorderColor: '#444444',
    overflowGradient: 'linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1))',
  };

  const [theme, setTheme] = useState(isDarkThemeEnabled ? darkTheme : lightTheme);

  useEffect(() => {
    setTheme(isDarkThemeEnabled ? darkTheme : lightTheme);
  }, [isDarkThemeEnabled]);

  const colorPrimary = skin && (isDarkThemeEnabled ? hexToHSL(skin.primary) : skin.primary);
  const colorAccent = skin && (isDarkThemeEnabled ? hexToHSL(skin.accent) : (skin.accent));

  const Theme = {
    colorAccent: skin && colorAccent,
    colorPrimary: skin && colorPrimary,
    isAccentDarkTheme: skin && skin.accent_dark_theme,
    isPrimaryDarkTheme: skin && skin.dark_theme,
    ...theme,
  };

  if (process.env.ENVIRONMENT === 'PRODUCTION' && (currentUser.settings && currentUser.settings.session_logging_enabled === 1)) {
    LogRocket.init('v2xspp/tb-weightroom-view');
    Logger.debug('Logrocket Enabled v1.1.0');
    LogRocket.identify(userInfo && userInfo.id, {
      name: userInfo && `${userInfo.first} ${userInfo.last}`,
      email: userInfo && userInfo.email,
      organizationId: userInfo && userInfo.organization_id,
      organizationAccessLevel: userInfo && userInfo.organization_access_level,
      subscriptionId: userInfo && userInfo.organization_subscription_id,
      appVersion: '1.1.0',
    });
  }

  useEffect(() => {
    fetchUsers();
    Logger.debug(process.env.ENVIRONMENT);
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVhProperty();
    window.addEventListener('resize', setVhProperty);
    // window.addEventListener('ondeviceorientation', setVhProperty);
    return () => {
      window.removeEventListener('resize', setVhProperty);
      // window.removeEventListener('ondeviceorientation', setVhProperty);
    };
  }, []);

  const panelComponentRef = useRef();

  const handleScreenClick = () => {
    handleScreenToggle();
  };

  const isFetchComplete = (fetchedGroups === true && fetchedCurrentUser === true);
  const hasAccess = userInfo && (userInfo.organization_access_level >= 5);
  const isPrimaryDarkTheme = skin && (skin.dark_theme);

  return (
    <ThemeProvider theme={Theme}>
      <Helmet>
        <title>Weightroom View | TeamBuildr</title>
      </Helmet>
      <WeightRoomContainer>
        {isFetchComplete ? (
          <Fragment>
            <Header
              isPrimaryDarkTheme={isPrimaryDarkTheme}
              logo={skin.logo}
              openGlobalModal={openGlobalModal}
              closeGlobalModal={closeGlobalModal}
              screens={activeScreens}
              handleScreenToggle={handleScreenClick}
            />
            <PanelSettings />
            <PanelsContainer>
              {Array(activeScreens).fill().map((set, i) => (
                <WeightroomPanel
                  key={i}
                  ref={panelComponentRef[i]}
                  namespace={`panel${i + 1}`}
                  handleScreenClick={handleScreenClick}
                />
              ))}
            </PanelsContainer>
            {!hasAccess && (
              <PayGate
                accountRequired='Platinum'
              />
            )}
          </Fragment>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </WeightRoomContainer>
    </ThemeProvider>
  );
};

WeightroomView.propTypes = {
  activeScreens: PropTypes.number.isRequired,
  closeGlobalModal: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchedGroups: PropTypes.bool.isRequired,
  fetchedCurrentUser: PropTypes.bool.isRequired,
  handleScreenToggle: PropTypes.func.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
  openGlobalModal: PropTypes.func.isRequired,
  skin: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

WeightroomView.defaultProps = {
  skin: {},
  userInfo: {},
};

export default WeightroomView;
