import React, { useState, useEffect } from 'react';
// import { Provider } from 'react-redux';
// import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'emotion-theming';
import hexToHSL from '../utils/hexToHSL';
import useSettings from '../hooks/useSettings';
import AppRoutes from '../../services/Routes/routes';
// import { lightTheme, darkTheme, defaultTheme } from '../theme/theme';
// import store from '../../redux_setup/store';
import mainTheme from '../theme/theme';
import useLayout from './Layout/hooks/useLayout';

const AppWrapper = () => {
  const { currentUser } = useLayout();
  const skin = currentUser.userSkin !== undefined && currentUser.userSkin;
  // const [theme, setTheme] = useState(isDarkThemeEnabled ? darkTheme : lightTheme);

  // useEffect(() => {
  //   setTheme(isDarkThemeEnabled ? darkTheme : lightTheme);
  // }, [isDarkThemeEnabled]);

  // const colorPrimary = skin && (isDarkThemeEnabled ? hexToHSL(skin.primary) : skin.primary);
  // const colorAccent = skin && (isDarkThemeEnabled ? hexToHSL(skin.accent) : (skin.accent));

  // const mainTheme = {
  //   colorAccent: skin && colorAccent,
  //   colorPrimary: skin && colorPrimary,
  //   isAccentDarkTheme: skin && skin.accent_dark_theme,
  //   isPrimaryDarkTheme: skin && skin.dark_theme,
  //   ...defaultTheme,
  //   ...theme,
  // };

  const theme = {
    colorAccent: skin && skin.accent,
    colorPrimary: skin && skin.primary,
    colorSecondary: skin && skin.secondary,
    isDarkMode: skin && skin.darknessMode,
    isAccentDarkTheme: skin && skin.accentDarkTheme,
    isPrimaryDarkTheme: skin && skin.darkTheme,
    isSecondaryDarkTheme: skin && skin.secondaryDarkTheme,
    // ...defaultTheme,
    ...mainTheme,
  };

  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default AppWrapper;
