import {
  SUBMIT_LOGIN_START,
  SUBMIT_LOGIN_FULFILLED,
  SUBMIT_LOGIN_REJECTED,
  SUBMIT_LOGOUT_FULFILLED,
  SUBMIT_GOOGLE_START,
  SUBMIT_GOOGLE_FULFILLED,
  SUBMIT_GOOGLE_REJECTED,
  FETCH_USER_START,
  FETCH_USER_FULFILLED,
} from './actionTypes';

const initialState = {
  isAuthenticated: false,
  isLoginLoading: false,
  loginError: null,
  googleError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_LOGIN_START: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case SUBMIT_LOGIN_FULFILLED: {
      return {
        ...state,
        loginError: null,
      };
    }
    case SUBMIT_LOGIN_REJECTED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
        loginError: action.payload,
      };
    }
    case SUBMIT_GOOGLE_START: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case SUBMIT_GOOGLE_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: true,
        googleError: null,
      };
    }
    case SUBMIT_GOOGLE_REJECTED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
        googleError: action.payload,
      };
    }
    case FETCH_USER_START: {
      return {
        ...state,
        isLoginLoading: true,
        isAuthenticated: false,
      };
    }
    case FETCH_USER_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: true,
      };
    }
    case SUBMIT_LOGOUT_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
