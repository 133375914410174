import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// import { namespaced } from 'redux-subspace';

// import { reducer as formReducer } from 'redux-form'

// import auth from './authReducer';
// import usersList from './usersReducer';

import createPanelReducer from '../modules/weightroom-view/ducks/panelReducer';
import weightroomReducer from '../modules/weightroom-view/ducks/weightroomReducer';
import authReducer from '../modules/login/ducks';
import layoutReducer from '../shared/components/Layout/ducks';
import documentsReducer from '../modules/documents/ducks';
import leaderboardReducer from '../modules/leaderboard/ducks';

const weightroomPersistConfig = {
  key: 'weightRoom',
  storage,
};

// const authPersistConfig = {
//   key: 'auth',
//   storage,
//   stateReconciler: hardSet,
// };

// const reducer = history => combineReducers({
//   auth: authReducer,
//   router: connectRouter(history),
//   panel1: createPanelReducer('panel1'),
//   panel2: createPanelReducer('panel2'),
//   panel3: createPanelReducer('panel3'),
//   panel4: createPanelReducer('panel4'),
//   settings: layoutReducer,
//   weightRoom: weightroomReducer,
// });

export default history => combineReducers({
  auth: authReducer,
  documents: documentsReducer,
  leaderboard: leaderboardReducer,
  router: connectRouter(history),
  panel1: createPanelReducer('panel1'),
  panel2: createPanelReducer('panel2'),
  panel3: createPanelReducer('panel3'),
  panel4: createPanelReducer('panel4'),
  settings: layoutReducer,
  // weightRoom: weightroomReducer,
  weightRoom: persistReducer(weightroomPersistConfig, weightroomReducer),
});

// export default persistReducer(rootPersistConfig, reducer);
