import axios from 'axios';
import { batch } from 'react-redux';

import {
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_FULFILLED,
  FETCH_CURRENT_USER_REJECTED,
  TOGGLE_SCREENS,
  TOGGLE_SUPERSETS,
  SELECT_GROUP,
  OPEN_GLOBAL_MODAL,
  CLOSE_GLOBAL_MODAL,
  SET_SCREENS,
  TOGGLE_DARK_THEME,
  TOGGLE_WEIGHT_VALUE,
} from './actionTypes';

import '../../../shared/utils/setCommonHeaders';

export const fetchCurrentUser = () => (
  (dispatch) => {
    dispatch({ type: FETCH_CURRENT_USER_START });
    axios.get('/users/me', { crossDomain: true })
      .then((response) => {
        dispatch({ type: FETCH_CURRENT_USER_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CURRENT_USER_REJECTED, payload: err });
      });
  }
);

export const fetchUsers = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USERS_START });
    axios.get('/users?type=1', { crossDomain: true })
      .then((response) => {
        dispatch({ type: FETCH_USERS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_USERS_REJECTED, payload: err });
      });
  }
);

export const fetchUserGroups = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_GROUPS_START });
    axios.get('/sports?filter=0', { crossDomain: true })
      .then((response) => {
        dispatch({ type: FETCH_USER_GROUPS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_USER_GROUPS_REJECTED, payload: err });
      });
  }
);

export const fetchInitial = () => (
  dispatch => batch(() => {
    dispatch(fetchCurrentUser());
    dispatch(fetchUsers());
    dispatch(fetchUserGroups());
  })
);

export const selectGroup = group => (
  (dispatch) => {
    dispatch({
      type: SELECT_GROUP,
      payload: group,
    });
  }
);

export const setScreens = screenAmount => ({
  type: SET_SCREENS,
  payload: screenAmount,
});

export const toggleScreens = () => ({
  type: TOGGLE_SCREENS,
});

export const openGlobalModal = () => ({
  type: OPEN_GLOBAL_MODAL,
});

export const closeGlobalModal = () => ({
  type: CLOSE_GLOBAL_MODAL,
});

export const toggleSupersets = () => ({
  type: TOGGLE_SUPERSETS,
});

export const toggleDarkTheme = () => ({
  type: TOGGLE_DARK_THEME,
});

export const toggleWeightValue = () => ({
  type: TOGGLE_WEIGHT_VALUE,
});
