import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ModalContentWrapper } from './ModalNote';
import Title from './Title';

const ModalText = styled('p')`
  white-space: pre-wrap;
`;

const ModalInfo = (props) => {
  const {
    instruction,
    name,
  } = props;

  return (
    <ModalContentWrapper>
      <Title
        icon='info'
      >
        {name}
      </Title>
      <ModalText>
        {instruction}
      </ModalText>
    </ModalContentWrapper>
  );
};

ModalInfo.propTypes = {
  instruction: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ModalInfo;
