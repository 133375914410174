import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAccounts,
  fetchUnreadMessages,
  submitSupport,
  switchAccounts,
} from '../ducks/layoutActions';
import { logOut } from '../../../../modules/login/ducks/loginActions';

const useLayout = () => {
  const isAccountsLoading = useSelector(state => state.settings.ui.isAccountsLoading);
  const isAuthenticated = useSelector(state => state.auth.ui.isAuthenticated);
  const currentUserAccounts = useSelector(state => state.settings.data.currentUserAccounts);
  const currentUser = useSelector(state => state.auth.data.currentUser);
  const unreadMessages = useSelector(state => state.settings.data.unreadMessages.count);

  const location = useSelector(state => state.router.location.pathname);

  const dispatch = useDispatch();

  const handleSupportSubmission = (values) => {
    dispatch(submitSupport(values));
  };

  const fetchUserAccounts = () => {
    dispatch(fetchAccounts());
  };

  const fetchUnread = () => {
    dispatch(fetchUnreadMessages());
  };

  const handleAccountSwitch = (accountCode) => {
    dispatch(switchAccounts(accountCode));
  };

  const handleLogOut = () => {
    dispatch(logOut());
  };

  return {
    currentUser,
    currentUserAccounts,
    fetchUserAccounts,
    fetchUnread,
    handleSupportSubmission,
    handleAccountSwitch,
    handleLogOut,
    isAccountsLoading,
    isAuthenticated,
    location,
    unreadMessages,
  };
};

export default useLayout;
