// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Dropzone from 'react-dropzone';
import * as Yup from 'yup';

import Select from 'react-select';
import IcomoonReact from 'icomoon-react';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import Text from '../../../../shared/components/Text/Text';
import Checkmark from '../../../../shared/components/Checkmark/Checkmark';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Button from '../../../../shared/components/Button/Button';
import NoHoverButton from './NoHoverButton';
import useDocuments from '../hooks/useDocuments';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextTypeWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content:space-between;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const NavigationWrapperFolder = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const NavigationWrapperFile = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  margin-bottom: -20px;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FileFormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const RightWrapper = styled('div')`
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;

  .formik-form {
    width: 90%;
  }

`;

const LeftWrapper = styled('div')`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;
// chombo kong was here
const GlobeWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .globe-text {
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
  }
`;

const URLWrapper = styled('div')`
  width: 90%;
  margin-bottom: 26px;
`;

const FileIconTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .file-icon-text {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .file-icon-text-uploaded {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 14px;
    color: green;
  }
  .file-icon-text-types {
    margin-top: 10px;
    font-size: 11px;
    text-align: center;
  }
  .file-icon-text-uploaded-fail {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 14px;
    color: red;
    margin-top: 20px;
  }
  .file-icon-text-uploaded-fail-restart {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 14px;
    color: red;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
  .file-icon-text-uploaded-success-again {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 13px;
    color: red;
    margin-top: 10px;
    cursor: pointer;
  }
  .file-icon-text-uploaded-name {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 14px;
    color: green;
    margin-top: 15px;
    }
  .file-icon-text-or {
    font-weight: 900;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
  }
  .file-icon {
    margin-bottom: 10px;
  }
`;

const DropZoneDiv = styled('div')`
  display: flex;
  border: dotted;
  border-color: grey;
  border-width: 2px;
  width: 90%;
  height: 75%;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const DropZoneDivUploaded = styled('div')`
  display: flex;
  border: solid;
  border-color: green;
  border-width: 2px;
  width: 90%;
  height: 75%;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const DropZoneDivUploadedFail = styled('div')`
  display: flex;
  border: solid;
  border-color: red;
  border-width: 2px;
  width: 90%;
  height: 75%;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: 20px;

  .folder-icon-text {
    font-size: 16px;
    font-weight: bold;
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -20px;
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-top: 8px;
    margin-bottom: 5px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;

const ModalCreateForm = ({
  currentUser,
  modalType,
  createDocument,
  onRequestClose,
  setActiveModal,
  currentFolder,
  setCreatedDocumentType,
}) => {
  const {
    handleUploadDocument,
    uploadedDocument,
    handleFetchUserGroups,
    handleFetchFolders,
    userGroups,
    visibleFolders,
    isDocumentUploading,
    uploadDocumentError,
  } = useDocuments();

  /**
   * three hooks for our use -
   * selectedFolder is the folder that the user intends on creating the document in,
   * if that intention exists.  isDocumentUploaded is for keeping track of whether the
   * user has uploaded a file.  The uploadedDocumentName is for displaying the name
   * of the uploaded file after successful upload
   */
  const [selectedFolder, setSelectedFolder] = useState({});
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [uploadedDocumentName, setUploadedDocumentName] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  /**
   * this function is for refining the groups that can be selected from the drop down
   * based on several variables.  Essentially, it takes the overlap between the user's
   * own access and the access of either the selected folder or the current folder and
   * lists those as the options in the dropdown.  If no folders are current or selected,
   * it just lists the groups that the user has access to.
   */
  const groupsRefiner = () => {
    if (currentFolder && userGroups) {
      if (currentFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const userGroupIdsArray = userGroups.map(group => group.id);
      const acceptedGroups = [];
      currentFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    if ((selectedFolder && Object.keys(selectedFolder).length) && userGroups) {
      if (selectedFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const userGroupIdsArray = userGroups.map(group => group.id);
      const acceptedGroups = [];
      selectedFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    return userGroups;
  };

  const foldersRefiner = () => {
    const returnFolders = [];
    if (selectedGroups.length) {
      visibleFolders.forEach((folder) => {
        const folderGroupIds = folder.userGroupAccess.map(group => group.id);
        let add = false;
        for (let i = 0; i < selectedGroups.length; i += 1) {
          if (!folderGroupIds.includes(selectedGroups[i])) {
            add = false;
            break;
          }
          add = true;
        }
        if (add) {
          returnFolders.push(folder);
        }
      });
      return returnFolders;
    }
    return visibleFolders;
  };

  const displayGroups = groupsRefiner();
  const displayFolders = foldersRefiner();

  // a handler for documents being submitted and created.  different processes depending on doc type
  const handleSubmit = (
    values,
    flip,
  ) => {
    const newValues = values;
    // if no groups selected and creating in folder, adds all groups folder has access to
    if (!newValues.groups && (currentFolder && currentFolder.userGroupAccess)) {
      newValues.groups = currentFolder.userGroupAccess.map(group => group.id);
    }
    if (!newValues.groups && (selectedFolder && selectedFolder.userGroupAccess)) {
      newValues.groups = selectedFolder.userGroupAccess.map(group => group.id);
    }
    // sets the type based on the doc type
    if (flip === 'link') {
      if (currentFolder) {
        newValues.folderId = currentFolder.id;
      }
      newValues.type = 2;
      createDocument(currentUser.accountCode, newValues);
    }
    if (flip === 'folder') {
      newValues.type = 0;
      createDocument(currentUser.accountCode, newValues);
    }
    if (flip === 'file') {
      if (currentFolder) {
        newValues.folderId = currentFolder.id;
      }
      newValues.type = 1;
      newValues.filePath = uploadedDocument.filePath;
      createDocument(currentUser.accountCode, newValues);
    }
    setCreatedDocumentType(flip);
    setActiveModal('confirm_create_modal');
  };

  // handler for file dropped (uploaded)
  const handleDroppedFile = (acceptedFile) => {
    handleUploadDocument(currentUser.accountCode, acceptedFile);
    setUploadedDocumentName(acceptedFile);
    setIsDocumentUploaded(true);
  };

  // two watchers, here: fetches list of potential folders;
  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
    }
    if (!currentFolder && !visibleFolders.length) {
      handleFetchFolders(accountCode);
    }
  }, []);

  const fileAccepterSwitch = () => {
    if (!isDocumentUploaded) {
      return (
        <Dropzone onDrop={acceptedFile => handleDroppedFile(acceptedFile)}>
          {({ getRootProps, getInputProps }) => (
            <DropZoneDiv {...getRootProps()}>
              <input {...getInputProps()} />
              <FileIconTextWrapper>
                <Text className='file-icon-text'>DRAG &amp; DROP FILE</Text>
                <IcomoonReact
                  className='file-icon'
                  iconSet={iconSet}
                  size={65}
                  icon='file'
                />
                <Text className='file-icon-text-or'>OR</Text>
                <Text className='file-icon-text'>CLICK TO UPLOAD</Text>
                <TextTypeWrapper>
                  <Text className='file-icon-text-types'>
                    Accepted file types:
                    {' '}
                    <br />
                    .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx
                  </Text>
                </TextTypeWrapper>
              </FileIconTextWrapper>
            </DropZoneDiv>
          )}
        </Dropzone>
      );
    }
    if (isDocumentUploading) {
      return <Spinner />;
    }
    if (isDocumentUploaded) {
      if (!uploadDocumentError) {
        return (
          <DropZoneDivUploaded>
            <FileIconTextWrapper>
              <Text className='file-icon-text-uploaded'>UPLOADED</Text>
              <Checkmark />
              <Text className='file-icon-text-uploaded-name'>{uploadedDocumentName[0].name}</Text>
              <Text
                onClick={() => setIsDocumentUploaded(false)}
                className='file-icon-text-uploaded-success-again'
              >
                CHANGE FILE
              </Text>
            </FileIconTextWrapper>
          </DropZoneDivUploaded>
        );
      }
      if (uploadDocumentError) {
        return (
          <DropZoneDivUploadedFail>
            <FileIconTextWrapper>
              <IcomoonReact
                className='file-icon'
                iconSet={iconSet}
                size={75}
                icon='remove'
                color='red'
              />
              <Text className='file-icon-text-uploaded-fail'>Something went wrong</Text>
              <Text
                onClick={() => setIsDocumentUploaded(false)}
                className='file-icon-text-uploaded-fail-restart'
              >
                Try Again
              </Text>
            </FileIconTextWrapper>
          </DropZoneDivUploadedFail>
        );
      }
    }
  };

  const urlSchema = Yup.object().shape({
    linkUrl: Yup.string()
      .url('Invalid URL')
      .required('URL is required'),
    name: Yup.string()
      .required('Name is required'),
  });

  const folderSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
  });

  const fileSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
  });

  if (modalType === 'add_link_modal') {
    return (
      <Formik
        initialValues={{
          linkUrl: '',
          name: '',
          description: '',
        }}
        validationSchema={urlSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values, 'link');
            setSubmitting(false);
          });
        }}
        render={({
          setFieldValue,
          submitForm,
          values,
          errors,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapper>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    setActiveModal('backwards_create_doc_modal');
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='left-arrow'
                  />
                </Text>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapper>
              <FormWrapper>
                <LeftWrapper>
                  <GlobeWrapper>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={75}
                      icon='globe'
                    />
                    <Text className='globe-text'>ADD A LINK</Text>
                  </GlobeWrapper>
                  <URLWrapper>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='formgroup-text'><strong>URL</strong></Text>
                        <FieldError className='error-text'>{errors.linkUrl}</FieldError>
                      </TitlePlusErrorWrapper>
                      <Field
                        className='form-control'
                        id='url-field'
                        name='linkUrl'
                        placeholder='https://domain.com'
                      />
                    </FormGroup>
                  </URLWrapper>
                </LeftWrapper>
                <RightWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='formgroup-text'><strong>Name</strong></Text>
                        <FieldError className='error-text'>{errors.name}</FieldError>
                      </TitlePlusErrorWrapper>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Title for link'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Description</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Field
                        className='form-control'
                        id='description-field'
                        name='description'
                        placeholder='Link description'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Folder</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Select
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={displayFolders}
                        onChange={(folder) => {
                          setSelectedFolder(folder);
                          if (folder) {
                            setFieldValue('folderId', folder.id);
                          }
                        }}
                        isOptionSelected={folder => values.folder === folder}
                        defaultValue={currentFolder}
                        isDisabled={currentFolder}
                        isClearable
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'><strong>Group Access</strong></Text>
                      <Select
                        defaultValue={values.groups}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={displayGroups}
                        onChange={(options) => {
                          const groupIds = options.map(group => group.id);
                          setSelectedGroups(groupIds);
                          setFieldValue('groups', groupIds);
                        }}
                        isOptionSelected={group => values.group === group}
                        isMulti
                        isClearable
                      />
                      <Text className='caveat-text'>*Leave empty to give access to all available groups</Text>
                    </FormGroup>
                  </Form>
                </RightWrapper>
              </FormWrapper>
            </AnotherWrapper>
            <Button
              bottom
              fullWidth
              cta='Submit'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={() => submitForm()}
            />
          </ModalWrapper>
        )}
      />
    );
  }
  if (modalType === 'create_folder_modal') {
    return (
      <Formik
        initialValues={{
          name: '',
          description: '',
        }}
        validationSchema={folderSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values, 'folder');
            setSubmitting(false);
          });
        }}
        render={({
          values,
          setFieldValue,
          submitForm,
          errors,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapperFolder>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    setActiveModal('backwards_create_doc_modal');
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='left-arrow'
                  />
                </Text>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapperFolder>
              <OuterFolderFormWrapper>
                <FolderTextWrapper>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={75}
                    icon='folder'
                  />
                  <Text className='folder-icon-text'>CREATE A FOLDER</Text>
                </FolderTextWrapper>
                <InnerFolderFormWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='formgroup-text'><strong>Name</strong></Text>
                        <FieldError className='error-text'>{errors.name}</FieldError>
                      </TitlePlusErrorWrapper>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Folder Name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Description</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Field
                        className='form-control'
                        id='description-field'
                        name='description'
                        placeholder='Folder Description'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Group Access</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Select
                        defaultValue={values.groups}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={userGroups}
                        onChange={(options) => {
                          const groupIds = options.map(group => group.id);
                          setFieldValue('groups', groupIds);
                        }}
                        isOptionSelected={group => values.group === group}
                        isMulti
                      />
                      <Text className='caveat-text'>*Leave empty to give access to all available groups</Text>
                    </FormGroup>
                  </Form>
                </InnerFolderFormWrapper>
              </OuterFolderFormWrapper>
            </AnotherWrapper>
            <Button
              bottom
              fullWidth
              cta='Submit'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={() => submitForm()}
            />
          </ModalWrapper>
        )}
      />
    );
  }
  if (modalType === 'upload_file_modal') {
    return (
      <Formik
        initialValues={{
          name: '',
          description: '',
        }}
        validationSchema={fileSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values, 'file');
            setSubmitting(false);
          });
        }}
        render={({
          values,
          setFieldValue,
          submitForm,
          errors,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapperFile>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    setActiveModal('backwards_create_doc_modal');
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='left-arrow'
                  />
                </Text>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapperFile>
              <FileFormWrapper>
                <LeftWrapper>
                  {fileAccepterSwitch()}
                </LeftWrapper>
                <RightWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='formgroup-text'><strong>Name</strong></Text>
                        <FieldError className='error-text'>{errors.name}</FieldError>
                      </TitlePlusErrorWrapper>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Document Name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Description</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Field
                        className='form-control'
                        id='description-field'
                        name='description'
                        placeholder='Document Description'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Folder</strong>
                        {' '}
                        (Optional)
                      </Text>
                      <Select
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={displayFolders}
                        onChange={(folder) => {
                          setSelectedFolder(folder);
                          if (folder) {
                            setFieldValue('folderId', folder.id);
                          }
                        }}
                        isOptionSelected={folder => values.folder === folder}
                        defaultValue={currentFolder}
                        isDisabled={currentFolder}
                        isClearable
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'><strong>Group Access</strong></Text>
                      <Select
                        defaultValue={values.groups}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={displayGroups}
                        onChange={(options) => {
                          const groupIds = options.map(group => group.id);
                          setSelectedGroups(groupIds);
                          setFieldValue('groups', groupIds);
                        }}
                        isOptionSelected={group => values.group === group}
                        isMulti
                      />
                      <Text className='caveat-text'>*Leave empty to give access to all available groups</Text>
                    </FormGroup>
                  </Form>
                </RightWrapper>
              </FileFormWrapper>
            </AnotherWrapper>
            <NoHoverButton
              bottom
              fullWidth
              cta='Submit'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={() => submitForm()}
              disabled={isDocumentUploading || !isDocumentUploaded || uploadDocumentError}
              noHover
            />
          </ModalWrapper>
        )}
      />
    );
  }
};

ModalCreateForm.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  modalType: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
};

export default ModalCreateForm;
