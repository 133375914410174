import styled from '@emotion/styled';
import { typography, color } from 'styled-system';

const Link = styled('a')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  color: #444444;
  ${props => props.allCaps && (`
    letter-spacing: 1.3px;
    text-transform: uppercase;
    font-weight: 900;
  `)}
  display: inline-block;
  ${typography}
  ${color}
`;

export default Link;
