import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { FastField, Field } from 'formik';
import styled from '@emotion/styled';
import { ColorPrimary } from '../../../../shared/GlobalStyles';
import CustomInputText from './FormikCustomInput';

/* `${title === 'Mins' ? '00' : ''}${title === 'reps' ? set.assigned_reps : ''}${title === 'weight' ? (!set.suggested_weight ? '' : set.suggested_weight) : ''}` */

const EntryTitles = styled('div')`
  display: block;
  min-width: 90px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: center; 
`;

const FieldsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  .form-control {
    /* margin-left: ${props => (props.isHealth ? '0px' : '5px')}; */
  }
`;

const FieldsWrapper = styled('div')`
  display: flex;
`;


const FieldsDefault = ({
  exerType,
  exerType2,
  handleBlur,
  handleChange,
  id,
  isHealth,
  isWeightValuesEnabled,
  labels,
  name,
  sets,
  setList,
  submitForm,
  title,
  weightUnit,
}) => {
  const handleAutoSave = (e) => {
    handleChange(e);
    setTimeout(submitForm, 0);
  };

  return (
    <Fragment>
      <div className='entry_row'>
        {exerType !== 'health' && (
          <EntryTitles>
            <div className={`title ${labels ? 'with_label' : ''}`}>
              {title}
            </div>
          </EntryTitles>
        )}

        <FieldsWrapper>
          <FieldsContainer
            className='scrollable-field-container'
            isHealth={isHealth}
          >
            {exerType === 'lift' || exerType === 'tag' ? (
              sets > 0 && (
                setList.map((set, i) => (
                  <div className='form-group' key={i}>
                    {labels && <label>{i + 1}</label>}
                    <FastField
                      className='form-control'
                      // component={CustomInputText}
                      name={`result[${i}].${name}${(name === 'power' || name === 'value') ? `[${id}]` : ''}`}
                      // onBlur={handleBlur}
                      onChange={handleAutoSave}
                      // onChange={handleChange}
                      placeholder={`${title === 'weight' ? (isWeightValuesEnabled ? weightUnit : set.suggested_weight === null ? weightUnit : set.suggested_weight) : ''}${title === 'reps' && set.suggested_reps !== null ? set.suggested_reps : ''}${(title === 'reps' && set.assigned_reps === 0) ? 'AMAP' : ''}`}
                      tabIndex={i + 1}
                      type='number'
                    />
                  </div>
                ))
              )
            ) : (
              (sets > 0 && (
                Array(sets).fill().map((set, i) => (
                  <div className='form-group' key={i}>
                    {labels && <label>{i + 1}</label>}
                    <FastField
                      className='form-control'
                      name={`result[${i}].${name}${name === 'value' ? `[${id}]` : ''}`}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={exerType2 === 'T' ? '00' : ''}
                      tabIndex={i + 1}
                      type='number'
                    />
                  </div>
                ))
              ))
            )}
            {exerType === 'health' && (
              <div className='form-group'>
                <FastField
                  className='form-control'
                  component={CustomInputText}
                  name='result[0].value[0]'
                  // onBlur={handleBlur}
                  onChange={handleChange}
                  type='number'
                />
              </div>
            )}
          </FieldsContainer>
        </FieldsWrapper>
      </div>
    </Fragment>
  );
};

FieldsDefault.propTypes = {
  exerType: PropTypes.string,
  exerType2: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  isHealth: PropTypes.bool,
  labels: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sets: PropTypes.number.isRequired,
  setList: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
};

FieldsDefault.defaultProps = {
  exerType: '',
  exerType2: '',
  handleBlur: null,
  id: '',
  isHealth: false,
  setList: {},
};

export default memo(FieldsDefault);
