import React, { Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import GoogleLogin from 'react-google-login';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';

import Button from '../../../../shared/components/Button/Button';
import Checkmark from '../../../../shared/components/Checkmark/Checkmark';
import Text from '../../../../shared/components/Text/Text';
import Link from '../../../../shared/components/Link/Link';
import GoogleIcon from '../../../../shared/images/google_icon.svg';
import encode from '../../../../shared/utils/encodeToUrl';
import useLogin from '../hooks/useLogin';
import Spinner from '../../../../shared/components/Spinner/Spinner';

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  margin-left: -54px;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
  }
`;

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
  password: Yup.string()
    .required('Password Required'),
});

const LoginForm = () => {
  const {
    handleGoogleAuth,
    handleLogin,
    isLoginLoading,
    isAuthenticated,
    loginError,
    googleError,
  } = useLogin();

  const formAnimation = useSpring({
    opacity: isLoginLoading || isAuthenticated ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoginLoading ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isAuthenticated ? 1 : 0 });

  const theme = useTheme();

  const handleGoogleResponse = (response) => {
    const values = {
      grant_type: 'google',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      tokenid: response.tokenId,
      scope: 'offline_access',
    };

    handleGoogleAuth(encode(values));
  };


  return (
    <Formik
      initialValues={{
        grant_type: 'password',
        username: '',
        password: '',
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        scope: 'offline_access',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleLogin(encode(values));
          // alert(JSON.stringify(encode(values), null, 2));
          setSubmitting(false);
        });
      }}
      validationSchema={LoginSchema}
      render={({
        errors,
        touched,
        handleSubmit,
      }) => (
        <Fragment>
          <animated.div style={formAnimation}>
            <Form
              name='login'
              id='login'
            >
              <FormGroup className='form-group'>
                <label>
                  <Text
                    fontSize={theme.textFontSizes[0]}
                  >
                    Email Address
                  </Text>
                  <Field
                    className='form-control'
                    id='email-field'
                    type='email'
                    name='username'
                    placeholder='Email'
                  />
                </label>
                {errors.username && touched.username && <FieldError className='text-center'>{errors.username}</FieldError>}
              </FormGroup>
              <FormGroup className='form-group'>
                <label>
                  <Text
                    fontSize={theme.textFontSizes[0]}
                  >
                    Password
                  </Text>
                  <Field
                    className='form-control'
                    type='password'
                    name='password'
                    placeholder='Password'
                  />
                </label>
                {errors.password && touched.password && <FieldError className='text-center'>{errors.password}</FieldError>}
                {loginError && <FieldError className='text-center'>Your email address or password is incorrect! Please try again.</FieldError>}
                {googleError && <FieldError className='text-center'>This google account does not seem to be associated with a TeamBuildr account.</FieldError>}
              </FormGroup>
              <FormGroup className='text-center'>
                <Button
                  cta='Sign In'
                  customColor='#ff6600'
                  disabled={errors.password || errors.username}
                  name='submit'
                  id='submit'
                  onClick={handleSubmit}
                  fullWidth
                  large
                  noBorder
                  rounded
                  primary
                />
                <GoogleLogin
                  clientId='849504945663-2a20rmtkv13kkrs7idoafi82fvoko52l.apps.googleusercontent.com'
                  buttonText='Login with Google'
                  onSuccess={handleGoogleResponse}
                  onFailure={handleGoogleResponse}
                  cookiePolicy='single_host_origin'
                  render={renderProps => (
                    <Button
                      cta='Login with Google'
                      customColor='#ffffff'
                      customIcon={GoogleIcon}
                      disabled={renderProps.disabled}
                      noHover
                      onClick={renderProps.onClick}
                      fullWidth
                      large
                      rounded
                    />
                  )}
                />
                <Link className='easy-join' allCaps color='lightText.1' href='https://app.teambuildr.com/signup?ej=true'>
                  Have an Easy Join Code? Click Here
                </Link>
                <Link allCaps color='lightText.2' href='https://app.teambuildr.com/forgot'>
                  Forgot Your Password?
                </Link>
              </FormGroup>
            </Form>
          </animated.div>
          <animated.div style={spinnerAnimation}>
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          </animated.div>
          <animated.div style={completeAnimation}>
            <SpinnerContainer>
              <Checkmark />
            </SpinnerContainer>
          </animated.div>
        </Fragment>
      )}
    />
  );
};

export default LoginForm;
