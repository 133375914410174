import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
// import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import Logger from 'js-logger';
import { PersistGate } from 'redux-persist/integration/react';
// import { ThemeProvider } from 'emotion-theming';

import AppWrapper from './shared/components/AppWrapper';
// import AppRoutes from './services/Routes/routes';
import { store, persistor } from './redux_setup/store';

Logger.useDefaults();
const app = document.getElementById('app');

if (process.env.ENVIRONMENT === 'PRODUCTION') {
  const trackingId = 'UA-25847119-8';
  ReactGA.initialize(trackingId);
}

if (process.env.ENVIRONMENT === 'PRODUCTION') {
  Logger.debug('Teambuildr Webapp v1.1.0');
}

if (app.hasChildNodes()) {
  hydrate(
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppWrapper />
        </PersistGate>
      </Provider>
    </CookiesProvider>,
    app,
  );
} else {
  render(
    <CookiesProvider>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </CookiesProvider>,
    app,
  );
}
