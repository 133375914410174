import React, { PureComponent, memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from '@emotion/styled';
import { TransitionGlobal, ColorGreen } from '../../../../shared/GlobalStyles';

const StaticText = styled('p')`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  /* margin: ${props => (props.lifts ? '.80em 0px 0px 0em' : '.80em 0px 10px .80em')}; */
  margin: ${props => (props.lifts ? '.80em 0px 0px 0em' : '.80em 0px 10px 0em')};
  justify-content: center;
`;

const SupersetIndicator = styled('div')`
  position: absolute;
  border: 1px solid ${ColorGreen};
  display: block;
  height: 
  ${props => (
    props.entriesViewHeight
      ? props.completion ? (
        `calc(${props.entriesViewHeight + 5}px - 46px)`
        ) : (`${props.entriesViewHeight + 5}px`)
      : '144px'
  )};
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-top: 1px;
  border-radius: 0.25em;
  transition: ${TransitionGlobal};
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 139, 0.25);
  opacity: ${props => props.activeSuperset ? '1' : '0'};
`;

const PercText = styled('span')`
  font-size: 12px;
  color: #6f6f6f;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const EntryRow = styled('div')`
  display: flex;
  margin-bottom: ${props => (props.lifts ? '-3px!important' : '10px!important')};
`;

class FieldsStatic extends PureComponent {
  constructor(props) {
    super(props);
    this.entryFieldRef = [];
  }

  render() {
    const {
      completion,
      entriesViewHeight,
      exerType,
      exerType2,
      grouping,
      isWrvSupersetsEnabled,
      labels,
      lifts,
      name,
      perc,
      sets,
      setList,
      supersetWorkout,
      title,
    } = this.props;

    return (
      <EntryRow className='entry_row' lifts={lifts}>
        <div className={`title ${labels ? 'with_label' : ''}`}>
          {name === 'perc' ? 'Set' : title}
        </div>
        {exerType === 'lift' || exerType === 'tag' ? (
          sets > 0 && (
            setList.map((set, i) => (
              <div
                className='form-group'
                key={i}
                ref={(el) => { this.entryFieldRef[i] = el; }}
              >
                {labels && <label>{i + 1}</label>}
                <SupersetIndicator
                  // className={`
                  //   exercise_icon
                  //   ${grouping ? `grouping_${grouping}` : ''}
                  // `}
                  activeSuperset={(isWrvSupersetsEnabled && supersetWorkout) && (supersetWorkout.completed_sets === i)}
                  entriesViewHeight={entriesViewHeight}
                  completion={completion}
                />
                <StaticText>
                  {name === 'perc' && (`${i + 1} `)}
                  {(name === 'perc' && set.assigned_perc !== null) && (
                    <PercText>{`(${set.assigned_perc}%)`}</PercText>
                  )}
                  {(name === 'perc' && set.assigned_perc === null) && (
                    ''
                  )}
                  {name === 'weight' && (`${set.suggested_weight} lb.`)}
                  {name === 'reps' && (`${set.assigned_reps}`)}
                </StaticText>
              </div>
            ))
          )
        ) : (
          sets > 0 && (
            Array(sets).fill().map((set, i) => (
              <div className='form-group' key={i}>
                {labels && <label>{i + 1}</label>}
                <SupersetIndicator
                  // className={`
                  //   exercise_icon
                  //   ${grouping ? `grouping_${grouping}` : ''}
                  // `}
                  activeSuperset={(isWrvSupersetsEnabled && supersetWorkout) && (supersetWorkout.completed_sets === i)}
                  entriesViewHeight={entriesViewHeight}
                  completion={completion}
                />
                <StaticText>
                  {name === 'perc' && (`${set.assigned_perc}%`)}
                  {name === 'sets' && i + 1}
                </StaticText>
              </div>
            ))
          )
        )}
      </EntryRow>
    );
  }
}

FieldsStatic.propTypes = {
  exerType: PropTypes.string,
  labels: PropTypes.bool,
  name: PropTypes.string.isRequired,
  sets: PropTypes.number.isRequired,
  setList: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

FieldsStatic.defaultProps = {
  exerType: '',
  labels: false,
};

export default memo(FieldsStatic);
