import React, { useLayoutEffect, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useCookies } from 'react-cookie';
import setAuthToken from '../../shared/utils/setAuthToken';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import history from '../History/history';
// import { createHashHistory } from 'history';

// const history = createHashHistory();
import Login from '../../modules/login/login';
import WeightroomView from '../../modules/weightroom-view/components/container/WeightroomViewContainer';
import TestPage from '../../modules/test-page/TestPage';
import Documents from '../../modules/documents/Documents';
import Leaderboard from '../../modules/leaderboard/Leaderboard';

const Routes = () => {
  const [cookies, setCookies] = useCookies(['v2token']);
  // const { isAuthenticated } = this.props.auth;
  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
    && cookies.accessToken !== undefined && cookies.accessToken !== '0'
    && cookies.session_id !== undefined && cookies.session_id !== '0'
    && cookies.session_key !== undefined && cookies.session_key !== '0';

  const isAuthenticated = cookies.v2token && (cookies.v2token !== '0');

  useLayoutEffect(() => {
    if (cookies.v2token) {
      if (cookies.v2token !== '0') {
        setAuthToken(cookies.v2token);
      }
    }
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {/* <Route path='/login' exact component={Login} /> */}

        <PrivateRoute exact path='/weightroom-view' component={WeightroomView} authed={isAuthenticated} />
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (<PrivateRoute exact path='/test-page' component={TestPage} authed={isAuthenticated} />)}
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (<PrivateRoute path='/documents' component={Documents} authed={isAuthenticated} />)}
        <LoginRoute exact path='/login' component={Login} authed={isAuthenticatedv3} />
        <Route
          path='/'
          exact
          render={() => (
            <Redirect to='/weightroom-view' />
          )}
        />
        {/* <Route path='/login' exact component={Login} /> */}
        <PrivateRoute exact path='/weightroom-view' component={WeightroomView} authed={isAuthenticated} />
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (<PrivateRoute exact path='/test-page' component={TestPage} authed={isAuthenticated} />)}
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (<PrivateRoute exact path='/documents' component={Documents} authed={isAuthenticated} />)}
        <PrivateRoute exact path='/leaderboard' component={Leaderboard} authed={isAuthenticated} />
        <LoginRoute exact path='/login' component={Login} authed={isAuthenticatedv3} />
      </Switch>
    </ConnectedRouter>
  );
};

export default Routes;
