import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import Text from '../Text/Text';

const FormLabel = (props) => {
  const theme = useTheme();
  return (
    <label>
      <Text
        fontSize={theme.textFontSizes[0]}
      >
        {props.children}
      </Text>
    </label>
  );
};

export default FormLabel;
