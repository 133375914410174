import { useDispatch, useSelector } from 'react-redux';
import {
  submitLogin,
  submitGoogleAuth,
  fetchLogin,
  clearUser,
} from '../../ducks/loginActions';

const useLogin = () => {
  const isLoginLoading = useSelector(state => state.auth.ui.isLoginLoading);
  const isAuthenticated = useSelector(state => state.auth.ui.isAuthenticated);
  const loginError = useSelector(state => state.auth.ui.loginError);
  const googleError = useSelector(state => state.auth.ui.googleError);
  const currentUser = useSelector(state => state.auth.data.currentUser);

  const dispatch = useDispatch();

  const handleLogin = (values) => {
    dispatch(submitLogin(values));
  };

  const handleGoogleAuth = (values) => {
    dispatch(submitGoogleAuth(values));
  };

  const handleFetchUser = () => {
    dispatch(fetchLogin());
  };

  const clearCurrentUser = () => {
    dispatch(clearUser());
  };

  return {
    clearCurrentUser,
    currentUser,
    handleGoogleAuth,
    handleLogin,
    handleFetchUser,
    googleError,
    isLoginLoading,
    isAuthenticated,
    loginError,
  };
};

export default useLogin;
