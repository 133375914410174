import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FieldArray, Formik } from 'formik';
import moment from 'moment';

import CompletionEntry from './WorkoutEntriesCompletion';
import Conditional from './Conditional';
import WorkoutFields from './WorkoutFields';
import FieldsStatic from './WorkoutFieldsStatic';
import EntryTitle from './EntryTitle';
import useWeightroomSettings from '../hooks/useWeightroomSettings';

const LiftEntries = ({
  athleteId,
  entriesViewHeight,
  entriesViewRef,
  entryRowRef,
  formikRef,
  index,
  namespace,
  settings,
  supersetWorkout,
  updateWorkout,
  workout,
}) => {
  const [ lastSaved, setLastSaved ] = useState(null);
  const { isWeightValueEnabled } = useWeightroomSettings();
  const wrvSupersetsEnabled = settings.wrv_supersets_enabled;
  
  // const wrvSupersetsEnabled = true;

  // Lift Render Conditions
  const isCompletion = workout.additional_options.completion === 1;

  const isLiftDefault = workout.additional_options.completion === 0;

  const isLiftPower = workout.additional_options.peak_power === 1;

  const isLiftPowerRows = workout.additional_options.peak_power === 2;

  const isLiftRepsOnly = workout.additional_options.reps_only === 1;

  const isLiftSpeedRows = workout.additional_options.bar_speed === 1;

  const hasPercent = workout.additional_options.has_percent === 1;

  const hasSuggestedWeight = workout.additional_options.has_suggested_weight === 1;

  const isEachSide = workout.additional_options.each_side;

  const isRepType = workout.rep_type === 1;

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={
        isCompletion ? (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                complete: workout.result.completed,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              complete: '0',
            });
          })()
        ) : (
          {
            work_id: workout.work_id,
            user_id: athleteId,
            result:
            workout.result ? (
              workout.result.data.map((set, i) => (
                {
                  set_id: i + 1,
                  weight: set.weight === null ? (
                    isWeightValueEnabled
                      ? workout.set_list[i].suggested_weight
                      : ''
                  ) : (
                    set.weight
                  ),
                  reps: set.reps === null ? (
                    workout.set_list[i].assigned_reps
                  ) : (
                    set.reps
                  ),
                  speed: set.speed,
                  power:
                    set.power,
                }
              ))
            ) : (
              workout.set_list.map((set, i) => ({
                set_id: i + 1,
                reps: (set.assigned_reps === null || set.assigned_reps === 0) ? '' : set.assigned_reps,
                weight: set.suggested_weight === null ? '' : isWeightValueEnabled ? set.suggested_weight : '',
              }))
            ),
          }
        )
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        actions.setSubmitting(false);
        setLastSaved(moment(new Date().toISOString()).format('MMMM Do YYYY, h:mm:ss a'));
      }}
      render={props => (
        <Form
          ref={entriesViewRef}
        >
          <FieldArray
            name='result'
            render={() => (
              <Fragment>
                {isLiftDefault && (
                  <Fragment>
                    <FieldsStatic
                      title='Perc.'
                      name='perc'
                      entriesViewHeight={entriesViewHeight}
                      ref={entryRowRef}
                      sets={workout.sets}
                      setList={workout.set_list}
                      exerType={workout.exer_type}
                      grouping={workout.grouping}
                      supersetWorkout={supersetWorkout}
                      isWrvSupersetsEnabled={wrvSupersetsEnabled}
                    />
                    {!isLiftRepsOnly && (
                      <WorkoutFields
                        title={`weight${isEachSide ? ' ea.' : ''}`}
                        name='weight'
                        sets={workout.sets}
                        setList={workout.set_list}
                        labels={false}
                        exerType={workout.exer_type}
                        isWeightValueEnabled={isWeightValueEnabled}
                        submitForm={props.handleSubmit}
                        weightUnit={settings.weight}
                        handleChange={props.handleChange}
                        value={props.values}
                      />
                    )}

                    <WorkoutFields
                      title={`${isRepType ? 'secs' : 'reps'}${isEachSide ? 'ea.' : ''}`}
                      name='reps'
                      sets={workout.sets}
                      setList={workout.set_list}
                      labels={false}
                      exerType={workout.exer_type}
                      submitForm={props.handleSubmit}
                      handleChange={props.handleChange}
                      value={props.values}
                    />

                    <FieldArray
                      name='power'
                      render={() => (
                        <Fragment>
                          {isLiftPowerRows && (
                            <Fragment>
                              <WorkoutFields
                                title='power (L)'
                                name='power'
                                id='0'
                                sets={workout.sets}
                                setList={workout.set_list}
                                labels={false}
                                exerType={workout.exer_type}
                                submitForm={props.handleSubmit}
                                handleChange={props.handleChange}
                                value={props.values}
                              />
                              <WorkoutFields
                                title='power (R)'
                                name='power'
                                id='1'
                                sets={workout.sets}
                                setList={workout.set_list}
                                labels={false}
                                exerType={workout.exer_type}
                                submitForm={props.handleSubmit}
                                handleChange={props.handleChange}
                                value={props.values}
                              />
                            </Fragment>
                          )}
                          {isLiftPower && (
                            <WorkoutFields
                              title='power'
                              name='power'
                              id='0'
                              sets={workout.sets}
                              setList={workout.set_list}
                              labels={false}
                              exerType={workout.exer_type}
                              submitForm={props.handleSubmit}
                              handleChange={props.handleChange}
                              value={props.values}
                            />
                          )}
                        </Fragment>
                      )}
                    />

                    {isLiftSpeedRows && (
                      <WorkoutFields
                        title='speed (m/s)'
                        name='speed'
                        sets={workout.sets}
                        setList={workout.set_list}
                        labels={false}
                        exerType={workout.exer_type}
                        submitForm={props.handleSubmit}
                        handleChange={props.handleChange}
                        value={props.values}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          />
          {isCompletion && (
            <Fragment>
              <FieldsStatic
                title='Perc.'
                name='perc'
                entriesViewHeight={entriesViewHeight}
                sets={workout.sets}
                setList={workout.set_list}
                labels={false}
                lifts
                completion={isCompletion}
                exerType={workout.exer_type}
                supersetWorkout={supersetWorkout}
                isWrvSupersetsEnabled={wrvSupersetsEnabled}
              />
              <Fragment>
                {hasSuggestedWeight && (
                  <FieldsStatic
                    title='Weight'
                    name='weight'
                    sets={workout.sets}
                    setList={workout.set_list}
                    labels={false}
                    weightUnit={settings.weight}
                    lifts
                    exerType={workout.exer_type}
                  />
                )}
              </Fragment>
              <FieldsStatic
                title='Reps'
                name='reps'
                sets={workout.sets}
                setList={workout.set_list}
                labels={false}
                lifts
                exerType={workout.exer_type}
                exer
              />
              <CompletionEntry
                handleChange={props.handleChange}
                handleSubmit={props.handleSubmit}
                exerType={workout.exer_type}
                lifts
                setFieldValue={props.setFieldValue}
                values={props.values}
              />
            </Fragment>
          )}
          {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
          {/* <small style={{ color: 'gray', fontSize: 11 }}>
            {!!props.isSubmitting
              ? 'saving...'
              : lastSaved !== null
              ? `Last Saved: ${lastSaved}`
              : null}
          </small> */}
        </Form>
      )}
    />
  );
};

LiftEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
  supersetWorkout: PropTypes.instanceOf(Object),
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

LiftEntries.defaultProps = {
  supersetWorkout: {},
};

export default LiftEntries;
