import React, { Component, Fragment } from 'react';
import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import Select, { components } from 'react-select';
import IconRound from './IconRound';
import Modal from './Modal';

import ButtonRound from './ButtonRound';
import { ColorLightestGray, ColorSecondary, TransitionGlobal, Gutter } from '../../../../shared/GlobalStyles';

import { ModalContentWrapper } from './ModalNote';
import Title from './Title';
import Spinner from './Spinner';
import OptionField from './OptionField';

const ModalText = styled('p')`
  white-space: pre-wrap;
`;

const OptOutLinks = styled('div')`
  border-top: 1px solid ${ColorLightestGray};
  display: flex;
  height: 40px;
`;

const OptOutButton = styled('button')`
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  width: 50%;
  color: ${props => (props.active ? (
    ColorSecondary
  ) : (
    ColorLightestGray
  ))};
  transition: ${TransitionGlobal};

  &:first-of-type {
    border-right: 1px solid ${ColorLightestGray};
  }
`;

const FormGroup = styled('div')`
  padding: 20px calc(30px * 3);
  @media only screen and (max-height: 767px) {
    padding: 10px 30px; 
    .form-control {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 1023px) {
    padding: 20px 15px;
  }
`;

const MessageContainer = styled('p')`
  text-align: center;
  margin-top: 5px;
  color: ${props => props.theme.textColor};
`;

const OptionContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const VideoButton = styled('div')`
  position: absolute;
  right: 80px;
  margin: 0px 5px 0px 30px;
  cursor: pointer;
  margin-top: -30px;
`;

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  min-height: 420px;
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

// const ClearIndicator = (props) => {
//   const ClearValue = () => {
//     console.log('Clicked Video');
//   };

//   const {
//     getStyles,
//     innerProps: { ref, ...restInnerProps },
//   } = props;

//   return (
//     <div
//       {...restInnerProps}
//       onClick={() => (console.log(props.children.data))}
//       ref={ref}
//       style={getStyles('clearIndicator', props)}
//     >
//       <div
//         style={{ padding: '0px 5px' }}
//       >
//         <IconRound
//           icon='video'
//           large
//           noBorder
//         />
//       </div>
//     </div>
//   );
// };

const Option = (props) => {
  const {
    data,
    children,
    selectProps,
  } = props;
  return (
    <OptionContainer>
      <components.Option {...props}>
        <p>{children}</p>
      </components.Option>
      {data.data.video != null && (
        <VideoButton onClick={() => selectProps.selectProps.handleModalOpen(data.data.video)}>
          <IconRound
            icon='video'
            large
            noBorder
          />
        </VideoButton>
      )}
    </OptionContainer>
  );
};

const validate = (values, props) => {
  let errors = {};

  if (values.opt_out === 0) {
    errors.optOut = 'Reason Required';
  }

  if (values.opt_out === 4 && !values.notes) {
    errors.notes = 'Note Required';
  }

  return errors;
};

// const ClearIndicatorStyles = (base, state) => ({
//   ...base,
//   cursor: 'pointer',
//   color: state.isFocused ? 'blue' : 'black',
// });

class ModalOptOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exerciseView: 'similar',
      isVidModalOpen: false,
      previewVideo: '',
    };
    this.handleAllClick = this.handleAllClick.bind(this);
    this.handleSimilarClick = this.handleSimilarClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.selectRef = React.createRef();
  }

  handleAllClick() {
    this.setState({
      exerciseView: 'all',
      previewVideo: '',
    });
  }

  handleSimilarClick() {
    this.setState({
      exerciseView: 'similar',
      previewVideo: '',
    });
  }

  handleModalOpen() {
    this.setState({
      isVidModalOpen: true,
    });
  }

  handleModalClose() {
    this.setState({
      isVidModalOpen: false,
    });
  }

  storeVideoPreview(video) {
    this.setState({
      previewVideo: video,
    });
  }

  render() {
    const {
      athleteId,
      activeDate,
      activeExerType,
      exercises,
      exercisesSimilar,
      fetchWorkout,
      instruction,
      index,
      isLoadingExercises,
      isLoadingSimExercises,
      modalClose,
      name,
      namespace,
      optOutWorkout,
      workId,
      workout,
    } = this.props;

    const {
      exerciseView,
      isVidModalOpen,
      previewVideo,
    } = this.state;

    const isExerciseSimilar = exerciseView === 'similar';
    const isLiftType = activeExerType === 'lift';
    const isTagType = activeExerType === 'tag';
    const { handleModalClose, handleModalOpen } = this;
    
    return (
      <ModalContentWrapper>
        <Title
          icon='opt-out'
        >
          {name}
        </Title>
        { isLoadingSimExercises === false && isLoadingExercises === false ? (
          <Formik
            initialValues={
              workout.result ? (
                {
                  work_id: workId,
                  user_id: athleteId,
                  opt_out: workout.result.opt_out,
                  new_exercise: 0,
                  notes: workout.result.opt_text,
                }
              ) : (
                {
                  work_id: workId,
                  user_id: athleteId,
                  opt_out: '0',
                  new_exercise: 0,
                  notes: '',
                }
              )
            }
            isInitialValid={false}
            validate={validate}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                optOutWorkout(athleteId, activeDate, index, namespace, values);
                modalClose(namespace);
                // if (values.new_exercise > 0) {
                //   fetchWorkout(athleteId, index, namespace, activeDate);
                // }
                // alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false);
              }, 1000);
            }}
            render={(props) => {
              const similarExercisesPicker = exercisesSimilar ? (
                <Select
                  classNamePrefix='select'
                  onChange={(options) => { props.setFieldValue('new_exercise', options.value); this.storeVideoPreview(options.data.video); }}
                  options={exercisesSimilar.result.options}
                  value={props.values.new_exercise.value}
                />
              ) : (
                <MessageContainer>
                  No Similar Exercises
                </MessageContainer>
              );

              const isPreviewVideoEmpty = (previewVideo !== '') && (previewVideo !== null);

              return (
                <Form>
                  <FormGroup>
                    <label>Select Reason for Opt Out</label>
                    {/* <OptionField
                      name='opt_out'
                      options={[
                        'Select Reason',
                        'Injury',
                        'Prohibited',
                        'Lack of Equipment',
                        'Other (explain below)',
                      ]}
                      values={props.values.opt_out}
                      handleChange={props.handleChange}
                    /> */}
                    <Select
                      // components={{ Option }}
                      classNamePrefix='select'
                      onChange={(options) => { props.setFieldValue('opt_out', options.value); }}
                      options={[
                        { value: 1, label: 'Injury' },
                        { value: 2, label: 'Prohibited' },
                        { value: 3, label: 'Lack of Equipment' },
                        { value: 4, label: 'Other (explain below)'},
                      ]}
                      value={props.values.opt_out.value}
                    />
                    {props.errors.optOut && <FieldError>{props.errors.optOut}</FieldError>}
                    
                  </FormGroup>

                  { (isLiftType || isTagType) && (
                    <FormGroup>
                      <label>Substitute with Another Exercise</label>
                      <OptOutLinks>
                        <OptOutButton
                          active={exerciseView === 'similar'}
                          onClick={this.handleSimilarClick}
                          type='button'
                        >
                          Similar
                        </OptOutButton>
                        <OptOutButton
                          active={exerciseView === 'all'}
                          onClick={this.handleAllClick}
                          type='button'
                        >
                          All Exercises
                        </OptOutButton>
                      </OptOutLinks>

                      { isExerciseSimilar ? (
                        similarExercisesPicker
                      ) : (
                        <Fragment>
                          <Select
                            // components={{ Option }}
                            classNamePrefix='select'
                            onChange={(options) => { props.setFieldValue('new_exercise', options.value); this.storeVideoPreview(options.data.video); }}
                            options={exercises}
                            ref={(ref) => { this.selectRef = ref; }}
                            selectProps={{ handleModalClose, handleModalOpen }}
                            value={props.values.new_exercise.value}
                          />
                          
                        </Fragment>
                      )}
                      {isPreviewVideoEmpty && (
                        <VideoButton onClick={() => this.handleModalOpen()}>
                          <IconRound
                            icon='video'
                            large
                          />
                        </VideoButton>
                      )}
                    </FormGroup>
                  )}

                  <FormGroup>
                    <label>Note</label>
                    <textarea
                      className='form-control'
                      onChange={props.handleChange}
                      name='notes'
                      type='text'
                      value={props.values.notes}
                    />
                    {props.errors.notes && props.touched.notes && <FieldError>{props.errors.notes}</FieldError>}
                  </FormGroup>
                  
                  {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
                  <ButtonRound
                    bottom
                    fullWidth
                    cta='Submit'
                    className='modal-button'
                    large
                    noBorder
                    primary
                    square
                    onClick={props.onSubmit}
                  />
                </Form>
              )}}
          />
        ) : (
          <Spinner />
        )}
        <ModalText>
          {instruction}
        </ModalText>
        <Modal
          isOpen={isVidModalOpen}
          onRequestClose={this.handleModalClose}
        >
          <StyledIframe
            className='video-iframe'
            src={previewVideo}
          />
        </Modal>
      </ModalContentWrapper>
    );
  }
}

export default ModalOptOut;
