import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import lightenDarkenColor from '../../utils/lightenDarkenColor';

import { ColorGreen, TransitionGlobal } from '../../GlobalStyles';
import iconSet from '../../images/teambuildr-selection.json';

const ButtonContainer = styled('div')`
  display: flex;
  height: ${props => (!props.small ? '56px' : '40px')};
  width: ${props => (!props.small ? '56px' : '40px')};
  background-color: ${props => (props.customColor ? props.customColor : ColorGreen)};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${TransitionGlobal};

  :hover {
    background-color: ${props => (lightenDarkenColor(props.customColor ? props.customColor : ColorGreen, -25))}
  }
  
  svg {
    path {
      fill: white;
    }
  }
`;

const ActionButton = ({
  customColor,
  onClick,
  icon,
  small,
}) => {
  const handleClickEvent = () => {
    onClick();
  };

  return (
    <ButtonContainer
      customColor={customColor}
      onClick={handleClickEvent}
      small={small}
    >
      <IcomoonReact
        iconSet={iconSet}
        size={20}
        icon={icon}
      />
    </ButtonContainer>
  );
};

ActionButton.propTypes = {
  customColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
};

ActionButton.defaultProps = {
  customColor: '',
  small: false,
};

export default ActionButton;
