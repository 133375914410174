import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useInterval from '@use-it/interval';
import { animated, useSpring } from 'react-spring';
import { useDispatch } from 'react-redux';

import BoardData from './BoardData';
import SubText from '../../../shared/components/SubText/SubText';
import Button from '../../../shared/components/Button/Button';
// import LeaderboardTable from './LeaderboardTable';
import LeaderboardTableWrapper from './BoardTableWrapper';
import LeaderboardPodiums from './BoardPodiums';
import useBoard from './hooks/useBoard';
import useLeaderboard from './hooks/useLeaderboard';
import useLeaderboardModal from './hooks/useLeaderboardModal';
import Title from '../../../shared/components/Title/Title';
// import IconRound from '../../../shared/components/IconRound/IconRound';
// import Spinner from '../../../shared/components/Spinner/Spinner';
import { setModalOpen } from '../ducks/leaderboardActions';

const BoardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.theme.colors.background[0])};
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-right: 15px;
  min-width: 0;
  min-height: 1;
  :last-of-type {
    margin-right: 0px;
  }
`;

const BoardLoadingBar = styled('div')`
  height: 4px;
  background-color: orange;
  width: 100%;
`;

const BoardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 25px;
`;

const BoardBody = styled('div')`
  display: flex;
  overflow: visible;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const BoardButtons = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Board = ({
  activeBoards,
  id,
}) => {
  const {
    handleCloseBoard,
    leaderboardExercise,
    leaderboardForm,
    leaderboardSettings,
  } = useBoard(id);

  const {
    autoLoad,
    loadTime,
  } = leaderboardSettings;

  const {
    handleFetchLeaderboard,
  } = useLeaderboard();

  // const {
  //   openModal,
  // } = useLeaderboardModal();

  const dispatch = useDispatch();

  const handleSetModalOpen = (modalOpen, modal, boardId) => {
    dispatch(setModalOpen(modalOpen, modal, boardId));
  };

  const openModal = (modal, boardId) => {
    handleSetModalOpen(true, modal, boardId);
  };

  useInterval(() => {
    handleFetchLeaderboard(leaderboardForm, 'edit', id);
  }, !autoLoad ? null : loadTime.value);

  const BoardTimerAnimation = useSpring({
    width: !autoLoad ? '0%' : '100%',
    from: {
      width: '0%',
    },
    config: { duration: loadTime.value },
    reset: true,
  });

  const editBoard = () => {
    openModal('Edit Leaderboard', id);
  };

  const closeBoard = () => {
    handleCloseBoard(id);
  };

  const downloadBoard = () => {
    openModal('Export Leaderboard', id);
  };

  const boardSettings = () => {
    openModal('Leaderboard Settings', id);
  };

  return (
    <BoardContainer>
      <animated.div
        style={BoardTimerAnimation}
      >
        <BoardLoadingBar />
      </animated.div>
      <BoardHeader>
        <SubText>{leaderboardExercise}</SubText>
        <BoardButtons>
          <Button
            icon='settings'
            iconOnly
            onClick={boardSettings}
          />
          <Button
            icon='download'
            iconOnly
            onClick={downloadBoard}
          />
          <Button
            icon='pencil'
            iconOnly
            onClick={editBoard}
          />
          <Button
            icon='remove'
            iconOnly
            onClick={closeBoard}
          />
        </BoardButtons>
      </BoardHeader>
      <BoardBody>
        <BoardData
          activeBoards={activeBoards}
          id={id}
        />
      </BoardBody>
    </BoardContainer>
  );
};

Board.propTypes = {
  activeBoards: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default Board;
