// modal for confirming deletion of selected documents

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import Button from '../../../../shared/components/Button/Button';
import Text from '../../../../shared/components/Text/Text';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    background-color: darkred;
  }

`;

const TextWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
`;

const ModalConfirmDelete = ({
  handleDeleteDocuments,
  setSelectedRows,
  selectedRows,
  currentUser,
  selectedRowNumber,
  setSelectedRowNumber,
  setActiveModal,
}) => {
  const groupDeleteDocuments = () => {
    handleDeleteDocuments(currentUser.accountCode, selectedRows);
    setSelectedRows([]);
    setSelectedRowNumber(0);
    setActiveModal(false);
  };
  const theme = useTheme();
  return (
    <ModalWrapper>
      <ModalWrapper>
        <TextWrapper>
          <Text
            fontWeight='600'
            fontSize={theme.textFontSizes[2]}
          >
            Are you sure you want to delete
            {' '}
            {selectedRowNumber === 1 ? 'this' : 'these'}
            {' '}
            {selectedRowNumber === 1 ? null : selectedRowNumber}
            {' '}
            {selectedRowNumber === 1 ? 'item?' : 'items?'}
          </Text>
        </TextWrapper>
        <Button
          bottom
          fullWidth
          cta='Delete'
          className='modal-button'
          large
          noBorder
          primary
          square
          onClick={() => (groupDeleteDocuments())}
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalConfirmDelete.propTypes = {
  handleDeleteDocuments: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  setSelectedRowNumber: PropTypes.func.isRequired,
  selectedRowNumber: PropTypes.number.isRequired,
  setActiveModal: PropTypes.func.isRequired,
};

export default ModalConfirmDelete;
