export const SUBMIT_SUPPORT_START = 'SUBMIT_SUPPORT_START';
export const SUBMIT_SUPPORT_FULFILLED = 'SUBMIT_SUPPORT_FULFILLED';
export const SUBMIT_SUPPORT_REJECTED = 'SUBMIT_SUPPORT_REJECTED';

export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START';
export const FETCH_ACCOUNTS_FULFILLED = 'FETCH_ACCOUNTS_FULFILLED';
export const FETCH_ACCOUNTS_REJECTED = 'FETCH_ACCOUNTS_REJECTED';

export const SWITCH_ACCOUNT_START = 'SWITCH_ACCOUNT_START';
export const SWITCH_ACCOUNT_FULFILLED = 'SWITCH_ACCOUNT_FULFILLED';
export const SWITCH_ACCOUNT_REJECTED = 'SWITCH_ACCOUNT_REJECTED';

export const FETCH_UNREAD_MESSAGES_START = 'FETCH_UNREAD_MESSAGES_START';
export const FETCH_UNREAD_MESSAGES_FULFILLED = 'FETCH_MESSAGES_FULFILLED';
export const FETCH_UNREAD_MESSAGES_REJECTED = 'FETCH_UNREAD_MESSAGES_REJECTED';
