import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import IcomoonReact from 'icomoon-react';
import ArrowButton from './ButtonArrow';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  BoxShadow,
  ColorBlack,
  ColorGreen,
  ColorWhite,
  ColorGray,
} from '../../../../shared/GlobalStyles';

const isPortrait = window.innerHeight > window.innerWidth;
// const isLandscape = window.innerHeight < window.innerWidth;

const WorkoutContainer = styled('div')`
  font-family: 'Open Sans';
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${props => props.theme.textColor};
  position: relative;
  padding: 9px 10px;
  transition: all 250ms ease-in-out;

  .exercise_text {
    display: flex;
    width: 100%;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    background-color: ${ColorGray};
    height: 16px;
    position: absolute;
    margin-top: -16px;
    margin-left: 11px;
  }
  .exercise_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border: 1px solid ${props => props.theme.iconBorderColor};
    background: ${props => props.theme.backgroundColorL2};
    border-radius: 20px;
    box-sizing: border-box;
    align-self: center;
    transition: all 250ms ease-in-out;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1000;

    svg {
      path {
        fill: ${props => props.theme.textColor}!important;
      }
    }

    &.complete {
      background-color: ${ColorGreen};
      border: 1px solid ${ColorGreen};
      color: white;
      svg {
        path {
          fill: rgb(255, 255, 255)!important;
        }
      }
    }

    .grouping_letter {
      height: 14px;
      width: 14px;
      line-height: 14px;
      font-size: 9px;
      color: #fff;
      border-radius: 100%;
      position: absolute;
      align-self: flex-end;
      margin-left: 12px;
      margin-top: -12px;
      text-align: center;
    }
  }

  &.break {
    &:before {
      display: none;
    }
  }

  &.active {
    padding: 20px 10px 0px 10px;
    transition: all 250ms ease-in-out;
    a {
      height: 32px;
    }
    .exercise_icon {
      transition: all 250ms ease-in-out;
      transform: scale(1.3);
    }

    .exercise_sets_reps {
      display: flex;
      font-size: 10px;
      flex-shrink: 0;
      button {
        font-size: 9px;
        padding: 6px 11px;
      }
      /* justify-content: ${props => (props.active === props.index ? 'justify-content: flex-start;' : '')} */
    }

    button {
      display: flex;
    }
    
  }

  .exercise_name {
    display: block;
    flex: 3;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 0px 0px 0px 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .exercise_sets_reps {
    /* display: flex; */
    align-self: center;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .5px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    box-sizing: border-box;
    text-align: right;
    margin-right: ${props => (props.isActive ? '50px' : '0px')};
    /* span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    } */
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .exercise_name {
      font-size: 13px!important;
    }
    .exercise_sets_reps {
      /* font-size: 11px!important; */
    }
  }
  @media screen and (max-width: 768px) {
  ${props => ((props.activeScreens !== 1) && (props.activeScreens !== 2 && isPortrait) ? (
    `
      .exercise_text {
        width: 100%;
        flex-direction: column;
        overflow: hidden;
      }

      .exercise_sets_reps {
        font-size: 10px;
        margin-left: 18px;
        color: #888888;
        align-self: flex-start;
        white-space: nowrap;
      }

      .exercise_name {
        font-size: 13px!important;
      }

      &:before {
        height: 26px;
        margin-top: -21px;
      }
      
    `
  ) : ('')
  )}
  }
`;

const SubHeader = styled('div')`
`;

const MainLink = styled('a')`
  display: flex;
  /* width: 50%; */
  flex: 1 1 auto;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const TotalTicker = styled('div')`
  display: block;
  background-color: ${ColorGreen};
  color: white;
  position: fixed;
  padding: 8px 18px;
  border-radius: 20px;
  box-shadow: ${BoxShadow};
  bottom: 30px;
  z-index: 10000;
  left: 50%;
  margin-left: -50px;
`;


class Workout extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleOptClick = this.handleOptClick.bind(this);
    this.handleInfoClick = this.handleInfoClick.bind(this);
  }


  handleClick(e) {
    // window.navigator.vibrate(200);
    const { handleActiveWorkout } = this.props;
    handleActiveWorkout(
      parseInt(e.currentTarget.dataset.id, 10),
      parseInt(e.currentTarget.dataset.index, 10),
      parseInt(e.currentTarget.dataset.exerid, 10),
      e.currentTarget.dataset.exertype,
    );
  }

  handleOptClick() {
    const { handleOptOut, exerId } = this.props;
    handleOptOut('opt-out', exerId);
  }

  handleInfoClick() {
    const { handleOpenModal } = this.props;
    handleOpenModal('info');
  }

  render() {
    const {
      activeScreens,
      complete,
      exerId,
      exerType,
      grouping,
      hasDescription,
      id,
      index,
      isActive,
      isTotalScienceEntries,
      name,
      subHeader,
      scienceLength,
      totalFormikValues,
      type,
      workoutInner,
    } = this.props;

    let { icon } = this.props;

    const isScience = exerType === 'science';

    switch (icon) {
      case 'dumbbell':
        icon = 'lift';
        break;
      case 'lab':
        icon = 'science';
        break;
      case 'roundabout':
        icon = 'circuit, roundabout';
        break;
      case 'notes':
        icon = 'note';
        break;
      case 'fire':
        icon = 'warmup';
        break;
      case 'heart':
        icon = 'heart, health';
        break;
      case 'snowflake':
        icon = 'cooldown';
        break;
      case 'facetime-video':
        icon = 'video';
        break;
      case 'more-windows':
        icon = 'window, break';
        break;
      case 'clock':
        icon = 'clock, rest';
        break;
      case 'signal':
        icon = 'chart';
        break;
      default:
    }

    if (complete) {
      icon = 'checkmark';
    }

    return (
      <WorkoutContainer
        id={`workout-${id}`}
        activeScreens={activeScreens}
        isActive={isActive}
        ref={workoutInner}
        className={`
          workout-container
          ${type}
          ${isActive ? 'active' : 'not-active'}
        `}
      >
        {/* Main Link */}

        <MainLink
          data-id={id}
          data-exerid={exerId}
          data-index={index}
          data-exertype={exerType}
          data-sciencelength={exerType === 'science' ? scienceLength : 0}
          isActive={isActive}
          ref={workoutInner}
          onClick={this.handleClick}
        >
          <div
            className={`
              exercise_icon
              ${complete ? 'complete' : ''} 
              ${grouping ? `grouping_${grouping}` : ''}
            `}
          >
            <IcomoonReact
              iconSet={iconSet}
              color={complete ? ColorWhite : ColorBlack}
              size={12}
              icon={icon}
            />

            {grouping ? (
              <div className='grouping_letter'>
                {grouping}
              </div>
            )
              : ''
            }
          </div>
          <div className='exercise_text'>
            <div className='exercise_name'>
              <span>
                {name}
              </span>
            </div>
            {!isActive && (
              <SubHeader className='exercise_sets_reps'>
                <span>
                  {subHeader}
                </span>
              </SubHeader>
            )}
          </div>
        </MainLink>
        {isActive && (
          <SubHeader className='exercise_sets_reps'>
            {/* {isMainWorkoutEntry && (
              <ButtonRound
                cta={isOptedOut ? 'Opted Out' : 'Opt Out'}
                icon='opt-out'
                type='button'
                onClick={this.handleOptClick}
              />
            )} */}
            {(isScience && hasDescription) && (
              <ArrowButton
                dataFirst='info'
                handleArrowClick={this.handleInfoClick}
                icon='info'
              />
            )}
          </SubHeader>
        )}
        {isActive && isTotalScienceEntries ? (
          <TotalTicker>
            {`Total: ${totalFormikValues}`}
          </TotalTicker>
        ) : ('')}
      </WorkoutContainer>
    );
  }
}

Workout.propTypes = {
  activeScreens: PropTypes.number.isRequired,
  complete: PropTypes.bool,
  exerId: PropTypes.number.isRequired,
  exerType: PropTypes.string.isRequired,
  grouping: PropTypes.string,
  handleActiveWorkout: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleOptOut: PropTypes.func.isRequired,
  hasDescription: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  isTotalScienceEntries: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  scienceLength: PropTypes.number,
  totalFormikValues: PropTypes.number,
  type: PropTypes.string.isRequired,
  workoutInner: PropTypes.instanceOf(Object).isRequired,
};

Workout.defaultProps = {
  complete: null,
  grouping: '',
  scienceLength: 0,
  totalFormikValues: 0,
};

export default Workout;
