import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import IcomoonReact from 'icomoon-react';

import useDocuments from './components/hooks/useDocuments';
import Layout from '../../shared/components/Layout/Layout';
import Spinner from '../../shared/components/Spinner/Spinner';
import Table from '../../shared/components/Table/Table';
import DocumentSearchForm from './components/presentational/DocumentSearch';
import BigModal from './components/presentational/BigModal';
import MediumModal from './components/presentational/MediumModal';
import CustomShareDocModal from './components/presentational/CustomShareDocModal';
import CustomCreateDocumentWrapModal from './components/presentational/CustomCreateDocumentWrapModal';
import BackwardsCustomCreateDocumentWrapModal from './components/presentational/BackwardsCustomCreateDocumentWrapModal';
import CustomModalConfirmCreate from './components/presentational/CustomModalConfirmCreate';
import DeleteModal from './components/presentational/DeleteModal';
import NoHoverDeleteButton from './components/presentational/NoHoverDeleteButton';
import ModalShareDocument from './components/presentational/ModalShareDocument';
import ModalCreateDocument from './components/presentational/ModalCreateDocument';
import ModalConfirmDelete from './components/presentational/ModalConfirmDelete';
import ModalCreateForm from './components/presentational/ModalCreateForm';
import ModalUpdateDocument from './components/presentational/ModalUpdateDocument';
import ModalConfirmCreate from './components/presentational/ModalConfirmCreate';
import ActionButton from '../../shared/components/ActionButton/ActionButton';
import iconSet from '../../shared/images/teambuildr-selection.json';
import useWindowSize from '../../shared/hooks/useWindowSize';

const CreateButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
`;

const DeleteButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 50px;
  margin-bottom: 50px;

  .modal-button {
    background-color: darkred;
  }
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100%;
`;

const Documents = ({ match }) => {
  const [currentFolder, setCurrentFolder] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [modalShareLink, setModalShareLink] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowNumber, setSelectedRowNumber] = useState(0);
  const [currentRow, setCurrentRow] = useState(null);
  const [createdDocumentType, setCreatedDocumentType] = useState('');
  const [activeSearchString, setActiveSearchString] = useState('');

  // const windowSize = useWindowSize();
  // windowSize.width <= 768 - true/false value (if true - omit = true)
  // useWindowSize - react hook that watches window size

  const {
    currentUser,
    documents,
    fetchedDocument,
    folderDocuments,
    handleFetchDocuments,
    handleFetchDocument,
    handleFetchFolder,
    isDocumentsLoading,
    handleCreateDocument,
    handleDeleteDocuments,
    handleUpdateDocument,
    handleFetchFile,
    updatedDocument,
    currentLocation,
    currentFile,
    handleSearch,
    handleFolderSearch,
    fetchDocumentError,
  } = useDocuments();

  const DotContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 30px;
  `;

  const fileFetcherDownloader = async (accountCode, docId) => {
    await handleFetchFile({ accountCode, docId });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    const pathArray = currentLocation.split('/');
    const pathLength = pathArray.length;
    if (accountCode !== undefined) {
      handleFetchDocuments(accountCode);
    }
    if (!currentFolder && pathLength === 3 && currentUser) {
      const docId = parseInt(pathArray[2], 10);
      if (accountCode) {
        handleFetchDocument({ accountCode, docId });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && currentFolder) {
      const folderId = currentFolder.id;
      handleFetchFolder({ accountCode, folderId });
    }
  }, [currentFolder]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined) {
      handleFetchDocuments(accountCode);
    }
  }, [updatedDocument]);

  useEffect(() => {
    if (currentFile) {
      window.open(currentFile.url, '_blank');
    }
  }, [currentFile]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined) {
      if (fetchedDocument.type === 1 || fetchedDocument.type === 2) {
        const docId = fetchedDocument.id;
        const { parentFolderId } = fetchedDocument;
        if (fetchedDocument.type === 1) {
          fileFetcherDownloader(accountCode, docId, fetchedDocument.name);
        }
        if (fetchedDocument.type === 2) {
          window.open(fetchedDocument.url, '_blank');
        }
        if (parentFolderId !== 0) {
          handleFetchDocument({ accountCode, docId: parentFolderId });
          dispatch(push(`/documents/${parentFolderId}`));
        } else {
          dispatch(push('/documents'));
        }
      }
    }
  }, [fetchedDocument]);



  useEffect(() => {
    if (fetchDocumentError) {
      dispatch(push('/documents'));
    }
  }, [fetchDocumentError]);

  const handleRowClicked = (row) => {
    if (row.type === 0) {
      setSelectedRows([]);
      setCurrentFolder(row);
      setActiveSearchString('');
      dispatch(push(`/documents/${row.id}`));
      const accountCode = currentUser !== undefined && currentUser.accountCode;
      handleSearch({ accountCode, values: { search: '' } });
    }
    if (row.type === 1) {
      const { accountCode } = currentUser;
      const docId = row.id;
      fileFetcherDownloader(accountCode, docId, row.name);
    }
    if (row.type === 2) {
      window.open(row.url, '_blank');
    }
  };

  const columns = [
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      cell: (row) => {
        let returnOb;
        if (row.type === 1) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='file'
            />
          );
        } else if (row.type === 0) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='folder'
            />
          );
        } else if (row.type === 2) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='globe'
            />
          );
        }
        return returnOb;
      },
      style: {
        'margin-left': '5px',
      },
      width: '65px',

    },
    // {
    //   cell: (row) => {
    //     if (row.type === 1) {
    //       return (
    //         <Text onClick={() => {
    //           setModalShareLink(`http://doc.tbldr.co/${row.id}`);
    //           setActiveModal('share_modal');
    //         }}
    //         >
    //           Share
    //         </Text>
    //       );
    //     }
    //     return null;
    //   },
    // },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      style: {
        'font-weight': 'bold',
        'margin-left': '-5px',
      },
    },
    {
      name: 'Description',
      selector: (row => (row.description ? row.description : '-')),
      center: true,
    },
    {
      name: 'Access',
      selector: ((row) => {
        if (row.userGroupAccess.length) {
          const groupNames = [];
          row.userGroupAccess.forEach((group, idx) => {
            if (idx < row.userGroupAccess.length - 1) {
              groupNames.push(`${group.name}, `);
            }
            if (idx === row.userGroupAccess.length - 1) {
              groupNames.push(`${group.name}`);
            }
          });
          return groupNames;
        }
        return 'Everyone';
      }
      ),
      sortable: true,
      omit: !currentUser.admin,
      center: true,
      style: {
        'margin-left': '-15px',
      },
    },
    {
      name: 'Uploaded',
      selector: 'dateAdded',
      sortable: true,
      right: true,
    },
    {
      cell: (row) => {
        if (!currentUser.admin) {
          return (
            <dotContainer
              onClick={() => {
                handleRowClicked(row);
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='right-arrow'
              />
            </dotContainer>
          );
        }
        return (
          <DotContainer onClick={() => {
            setCurrentRow(row);
            setActiveModal('update_doc_modal');
          }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='dots'
            />
          </DotContainer>
        );
      },
      right: true,
      width: '65px',
    },
  ];

  const closeModal = () => {
    setActiveModal(false);
  };

  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'share_modal') {
      returnModal = (
        <CustomShareDocModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalShareDocument
            link={modalShareLink}
            onRequestClose={closeModal}
          />
        </CustomShareDocModal>
      );
    } else if (activeModal === 'create_doc_modal') {
      returnModal = (
        <CustomCreateDocumentWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          currentFolder={currentFolder}
        >
          <ModalCreateDocument
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            onRequestClose={closeModal}
          />
        </CustomCreateDocumentWrapModal>
      );
    } else if (activeModal === 'backwards_create_doc_modal') {
      returnModal = (
        <BackwardsCustomCreateDocumentWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalCreateDocument
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            onRequestClose={closeModal}
          />
        </BackwardsCustomCreateDocumentWrapModal>
      );
    } else if (activeModal === 'delete_docs_modal') {
      returnModal = (
        <DeleteModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalConfirmDelete
            handleDeleteDocuments={handleDeleteDocuments}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            currentUser={currentUser}
            selectedRowNumber={selectedRowNumber}
            setSelectedRowNumber={setSelectedRowNumber}
            setActiveModal={setActiveModal}
          />
        </DeleteModal>
      );
    } else if (activeModal === 'update_doc_modal') {
      returnModal = (
        <MediumModal
          isOpen={activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalUpdateDocument
            setActiveModal={setActiveModal}
            handleUpdateDocument={handleUpdateDocument}
            currentUser={currentUser}
            document={currentRow}
            onRequestClose={closeModal}
            handleFetchDocuments={handleFetchDocuments}
            currentFolder={currentFolder}
          />
        </MediumModal>
      );
    } else if (activeModal === 'create_folder_modal' || activeModal === 'upload_file_modal' || activeModal === 'add_link_modal') {
      returnModal = (
        <BigModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateForm
            currentUser={currentUser}
            modalType={activeModal}
            createDocument={handleCreateDocument}
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            setCreatedDocumentType={setCreatedDocumentType}
          />
        </BigModal>
      );
    } else if (activeModal === 'confirm_create_modal') {
      returnModal = (
        <CustomModalConfirmCreate
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalConfirmCreate
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            documentType={createdDocumentType}
          />
        </CustomModalConfirmCreate>
      );
    }
    return returnModal;
  };

  const createDocumentButton = () => {
    if (currentUser.admin) {
      return (
        <ActionButton
          icon='plus'
          onClick={() => {
            setActiveModal('create_doc_modal');
          }}
        />
      );
    }
    return null;
  };

  const deleteDocumentsButton = () => {
    if (selectedRows && selectedRows.length > 0) {
      return (
        <NoHoverDeleteButton
          bottom
          fullWidth
          cta='Delete'
          className='modal-button'
          large
          noBorder
          primary
          noHover
          square
          onClick={() => (setActiveModal('delete_docs_modal'))}
        />
      );
    }
    return null;
  };

  const handleSelectedRowChange = (rows) => {
    const newArray = [];
    let counter = 0;
    rows.selectedRows.forEach((row) => {
      newArray.push(row.id);
      counter += 1;
    });
    setSelectedRowNumber(counter);
    setSelectedRows(newArray);
  };

  const pathWatcher = () => {
    const pathArray = currentLocation.split('/');
    const pathLength = pathArray.length;
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (currentFolder && pathArray < 3) {
      handleFolderSearch({ accountCode, values: { search: '' }, currentFolder });
      setCurrentFolder(false);
      setActiveSearchString('');
    }
    if (!currentFolder && fetchedDocument && pathLength === 3) {
      if (fetchedDocument.type === 0) {
        setCurrentFolder(fetchedDocument);
      }
    }
    if (pathLength < 3 && currentFolder) {
      handleFolderSearch({ accountCode, values: { search: '' }, currentFolder });
      setCurrentFolder(false);
      setActiveSearchString('');
    }
  };

  return (
    <Layout>
      <Switch>
        {/* Child Path with content */}
        <Route path={`${match.path}/:documentId`}>
          <DocumentSearchForm
            currentFolder={currentFolder}
            directoryName={currentFolder.name || 'Loading..'}
            activeSearchString={activeSearchString}
            setActiveSearchString={setActiveSearchString}
          />
          {isDocumentsLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Table
              columns={columns}
              data={folderDocuments}
              selectableRows={!!currentUser.admin}
              highlightOnHover
              pointerOnHover
              noHeader
              onSelectedRowsChange={rows => handleSelectedRowChange(rows)}
              onRowClicked={row => (handleRowClicked(row))}
            />
          )}
        </Route>
        {/* // Parent route with content */}
        <Route path={match.path}>
          {isDocumentsLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <React.Fragment>
              <DocumentSearchForm
                currentFolder={currentFolder}
                directoryName='Documents and Links'
                activeSearchString={activeSearchString}
                setActiveSearchString={setActiveSearchString}
              />
              <Table
                columns={columns}
                data={documents}
                selectableRows={!!currentUser.admin}
                highlightOnHover
                pointerOnHover
                onRowClicked={row => (handleRowClicked(row))}
                noHeader
                onSelectedRowsChange={rows => handleSelectedRowChange(rows)}
              />
            </React.Fragment>
          )
          }
        </Route>
      </Switch>
      {modalHandler()}
      <CreateButtonWrapper>
        {createDocumentButton()}
      </CreateButtonWrapper>
      <DeleteButtonWrapper>
        {deleteDocumentsButton()}
      </DeleteButtonWrapper>
      {pathWatcher()}
    </Layout>
  );
};

Documents.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default Documents;
