import React from 'react';
import styled from '@emotion/styled';
import { Gutter } from '../GlobalStyles';
import { useTheme } from 'emotion-theming';
import { Formik, Form, Field } from 'formik';

import Button from './Button/Button';
import HeaderText from './HeaderText/HeaderText';
import Text from './Text/Text';
import useLayout from './Layout/hooks/useLayout';

const ModalWrapper = styled('div')`
  display: block;
`;

const FormGroup = styled('div')`
  margin-bottom: 0px;
  padding: 5px 0px;
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  textarea {
    min-height: 130px;
  }
  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

export const ModalContentContainer = styled('div')`
  padding: 30px 40px 60px 40px;
  flex-grow: 1;
  background-color: ${props => props.theme.backgroundColorL2};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    /* .form-group {
      padding: 15px 0px 5px 0px;
    } */
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }

`;

const ModalTitle = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: ${props => props.theme.colorPrimary};
`;

const ModalSupport = ({
  path,
}) => {
  const { handleSupportSubmission } = useLayout();
  const theme = useTheme();

  const handleSubmit = (values) => {
    console.log(values);
    handleSupportSubmission(values);
  };

  return (
    <ModalWrapper>
      <ModalTitle>
        <HeaderText
          fontSize={theme.headerFontSizes[3]}
          color={theme.isPrimaryDarkTheme ? '#444444' : 'white'}
        >
          Support
        </HeaderText>
      </ModalTitle>
      <ModalContentContainer>
        <Formik
          initialValues={{
            subject: '',
            body: '',
            page: path,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleSubmit(values);
              setSubmitting(false);
            });
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
          }) => (
            <Form>
              <Text
                fontWeight='600'
                fontSize={theme.textFontSizes[2]}
              >
                Have a question or suggestion? Fill out the form below and send it to us!
              </Text>
              <Text
                fontSize={theme.textFontSizes[1]}
                className='d-none d-sm-block'
              >
                Also, be sure to check out our
                <a
                  href='https://support.teambuildr.com/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  &nbsp;Knowledge Base&nbsp;
                </a>
                for some quick tips and walkthroughs.
              </Text>
              <FormGroup className='form-group'>
                <label>
                  <Text>Subject</Text>
                  <Field
                    className='form-control'
                    id='subject-field'
                    name='subject'
                    placeholder='Support Question / Suggestion Subject'
                  />
                </label>
              </FormGroup>
              <FormGroup className='form-group'>
                <label>
                  <Text>Message</Text>
                  <textarea
                    // disabled={hasJournalEntry}
                    onChange={handleChange}
                    name='body'
                    className='form-control'
                    placeholder='Describe your problem or suggestion here...'
                    value={values.body}
                  />
                </label>
              </FormGroup>
              <Button
                bottom
                fullWidth
                cta='Submit'
                className='modal-button'
                large
                noBorder
                primary
                square
                onClick={handleSubmit}
              />
            </Form>
          )}
        />
      </ModalContentContainer>
    </ModalWrapper>
  );
};

export default ModalSupport;
