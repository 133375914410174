// modal that pops up after document is created, showing links to create other documents

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100;
  margin-top: -10px;
  padding-right: 30px;
  padding-left: 30px;

  .icon-text-wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
`;

const ItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 75%;
  min-width: 400px;
  max-width: 550px;
`;

const CreateObjectWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  .create-doc-text {
    margin-top: 25px;
    font-weight: bolder;
    font-size: 20px;
  }
`;

const TextAndObjectsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const TextWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -50px;
  margin-bottom: 60px;

  .success-text {
    font-size: 16px;
    font-weight: bold;
    color: green;
    margin-bottom: 15px;
  }

  .question-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: -20px;
  }
`;

export const ModalContentContainer = styled('div')`
  padding: 30px 40px 60px 40px;
  flex-grow: 1;
  background-color: ${props => props.theme.backgroundColorL2};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    /* .form-group {
      padding: 15px 0px 5px 0px;
    } */
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
`;

const ModalConfirmCreate = ({
  setActiveModal,
  currentFolder,
  documentType,
}) => {
  const createItemClicked = (clickedItem) => {
    setActiveModal(clickedItem);
  };

  const createFolderObjectRenderer = () => {
    if (!currentFolder) {
      return (
        <CreateObjectWrapper onClick={() => createItemClicked('create_folder_modal')}>
          <IcomoonReact
            iconSet={iconSet}
            size={75}
            icon='folder'
          />
          <Text className='create-doc-text'>
            Create folder
          </Text>
        </CreateObjectWrapper>
      );
    }
    return null;
  };

  return (
    <ModalWrapper>
      <TextAndObjectsWrapper>
        <TextWrapper>
          <Text className='success-text'>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='checkmark'
              color='green'
            />
            {' '}
            {documentType}
            {' '}
            successfully added!
          </Text>
          <Text className='question-text'>Need to add anything else?</Text>
        </TextWrapper>
        <ItemsWrapper>
          {createFolderObjectRenderer()}
          <CreateObjectWrapper onClick={() => createItemClicked('upload_file_modal')}>
            <IcomoonReact
              iconSet={iconSet}
              size={75}
              icon='save'
            />
            <Text className='create-doc-text'>
              Upload file
            </Text>
          </CreateObjectWrapper>
          <CreateObjectWrapper onClick={() => createItemClicked('add_link_modal')}>
            <IcomoonReact
              iconSet={iconSet}
              size={75}
              icon='globe'
            />
            <Text className='create-doc-text'>
              Add a link
            </Text>
          </CreateObjectWrapper>
        </ItemsWrapper>
      </TextAndObjectsWrapper>
    </ModalWrapper>
  );
};

ModalConfirmCreate.propTypes = {
  setActiveModal: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
};
export default ModalConfirmCreate;
