import axios from 'axios';
import Cookies from 'js-cookie';
import Logger from 'js-logger';
// import { push } from 'connected-react-router'
import getAllUrlParams from '../../../shared/utils/getAllUrlParams';

import { store } from '../../../redux_setup/store';
import encode from '../../../shared/utils/encodeToUrl';
import { axiosAuthed, axiosLogin } from '../../../shared/utils/setCommonHeaders';

import {
  CLEAR_USER,
  SUBMIT_LOGIN_START,
  SUBMIT_LOGIN_FULFILLED,
  SUBMIT_LOGIN_REJECTED,
  SUBMIT_LOGOUT_FULFILLED,
  SUBMIT_GOOGLE_START,
  SUBMIT_GOOGLE_FULFILLED,
  SUBMIT_GOOGLE_REJECTED,
  SUBMIT_REFRESH_START,
  SUBMIT_REFRESH_FULFILLED,
  SUBMIT_REFRESH_REJECTED,
  SUBMIT_V2EXCHANGE_START,
  SUBMIT_V2EXCHANGE_FULFILLED,
  SUBMIT_V2EXCHANGE_REJECTED,
  FETCH_USER_START,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED,
  FETCH_V2USER_START,
  FETCH_V2USER_FULFILLED,
  FETCH_V2USER_REJECTED,
} from './actionTypes';

const saveAuthTokens = (v2Token, accessToken, refreshToken, expiresIn) => {
  Cookies.set('v2token', v2Token, {
    // secure: false,
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.set('accessToken', accessToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.set('refreshToken', refreshToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });

  const now = new Date();
  const tokenExpiration = new Date(now.getTime() + (expiresIn * 1000)).getTime();

  Cookies.set('tokenExpiration', tokenExpiration, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
};

const deleteAuthTokens = (v2Token, accessToken, refreshToken, expiresIn) => {
  Cookies.remove('v2token', v2Token, {
    // secure: false,
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.remove('accessToken', accessToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.remove('refreshToken', refreshToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });

  const now = new Date();
  const tokenExpiration = new Date(now.getTime() + (expiresIn * 1000)).getTime();

  Cookies.remove('tokenExpiration', tokenExpiration, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
};

// Fetch /me endpoint to get v1 session ids and session keys with login.
export const fetchLogin = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_START });
    axiosAuthed.get('/me')
      .then((response) => {
        Cookies.set('session_id', response.data.sessionId, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
        });
        Cookies.set('session_key', response.data.sessionKey, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
        });
        dispatch({
          type: FETCH_USER_FULFILLED,
          payload: response.data,
        });
        if (process.env.ENVIRONMENT === 'LOCAL') {
          window.location = 'http://localhost:8080/weightroom-view';
        }
        if (process.env.ENVIRONMENT !== 'LOCAL') {
          const url = getAllUrlParams(window.location.href);
          const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
          window.location = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' ? 'beta' : 'app'}.teambuildr.com/${url.rel === undefined ? 'dashboard' : url.rel}`;
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_REJECTED,
          payload: err,
        });
      });
  }
);

// Fetch /me endpoint to get v1 session ids and session keys with login.
export const fetchUser = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_START });
    axiosAuthed.get('/me')
      .then((response) => {
        Cookies.set('session_id', response.data.sessionId, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
        });
        Cookies.set('session_key', response.data.sessionKey, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
        });
        dispatch({
          type: FETCH_USER_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_REJECTED,
          payload: err,
        });
      });
  }
);

// Submit google auth token to get back v2 token.
export const submitGoogleAuth = values => (
  (dispatch) => {
    dispatch({ type: SUBMIT_GOOGLE_START });
    axiosLogin.post('/oauth/token', values)
      .then((response) => {
        const { data } = response;

        setTimeout(() => {
          dispatch({
            type: SUBMIT_GOOGLE_FULFILLED,
            payload: response.data,
          });
        }, 800);
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_GOOGLE_REJECTED,
          payload: err,
        });
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 !== null) {
          auth2.signOut().then(
            Logger.debug('Google has been signed out'),
            auth2.disconnect(),
          );
        }
      });
  }
);

// Submit login details to get oauth token.
export const submitLogin = values => (
  (dispatch) => {
    dispatch({ type: SUBMIT_LOGIN_START });
    axiosLogin.post('/oauth/token', values)
      .then((response) => {
        const { data } = response;

        setTimeout(() => {
          dispatch({
            type: SUBMIT_LOGIN_FULFILLED,
            payload: response.data,
          });
        }, 800);
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_LOGIN_REJECTED,
          payload: err,
        });
        console.log(err);
      });
  }
);

// Log Out
export const logOut = () => (
  (dispatch) => {
    dispatch({ type: SUBMIT_LOGOUT_FULFILLED });
    deleteAuthTokens();
    Cookies.remove('session_id', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('session_key', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('tokenExpiration', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    console.log('cookies should be clear');
    const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
    window.location = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}app.teambuildr.com/assets/log_action.php?act=logout`;
  }
);

// Return Axios promise to have access to token in Axios interceptor.
export const submitRefreshToken = () => (
  (dispatch) => {
    const refreshBody = {
      grant_type: 'refresh_token',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      refresh_token: Cookies.get('refreshToken'),
    };
    dispatch({ type: SUBMIT_REFRESH_START });
    return axiosLogin.post('/oauth/token', encode(refreshBody))
      .then((response) => {
        const { data } = response;
        dispatch({
          type: SUBMIT_REFRESH_FULFILLED,
          payload: response.data,
        });
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        return response.data.access_token;
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_REFRESH_REJECTED,
          payload: err,
        });
      });
  }
);

// Set isAuthenticated to false if there are no cookies on initial load
export const clearUser = () => ({
  type: CLEAR_USER,
});

// Submit v2 token to get an accessToken, return Axios promise to
// have access to token in Axios interceptor.

export const submitV2Exchange = values => (
  (dispatch) => {
    dispatch({ type: SUBMIT_V2EXCHANGE_START });
    return axiosLogin.post('/oauth/token', encode(values))
      .then((response) => {
        const { data } = response;
        dispatch({
          type: SUBMIT_V2EXCHANGE_FULFILLED,
          payload: response.data,
        });
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_V2EXCHANGE_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchV2User = () => (
  (dispatch) => {
    dispatch({ type: FETCH_V2USER_START });
    return axios.get('/users/me', { crossDomain: true })
      .then((response) => {
        dispatch({ type: FETCH_V2USER_FULFILLED, payload: response.data });
        return response.data.result.user_info.email;
      })
      .catch((err) => {
        dispatch({ type: FETCH_V2USER_REJECTED, payload: err });
      });
  }
);


// Request Interceptor for setting tokens before requests,
// it will also get a new auth token if the token is expired before requests are sent,
// as well as getting a new auth token if we only have a v2token.

export const authProvider = () => {
  axiosAuthed.interceptors.request.use((config) => {
    const originalRequest = config;
    const currentDate = new Date().getTime();
    const v2token = Cookies.get('v2token');
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const tokenExpiration = Cookies.get('tokenExpiration');
    const isTokenExpired = currentDate > tokenExpiration;

    // Check if there is a v2token and no authToken, if so try to exchange the v2token
    // for an authToken. Requires getting the user email from v2 /me api request.
    if ((accessToken === undefined || refreshToken === undefined) && (v2token !== undefined && v2token !== '0')) {
      return store.dispatch(fetchV2User()).then((username) => {
        const v2ExchangeValues = {
          grant_type: 'v2token',
          client_id: process.env.CLIENT_ID,
          client_secret: process.env.CLIENT_SECRET,
          username,
          v2token,
          scope: 'offline_access',
        };

        // Submit v2 token exchange for a new auth token.
        return store.dispatch(submitV2Exchange(v2ExchangeValues)).then(() => {
          originalRequest.headers.common.Authorization = `Bearer ${Cookies.get('accessToken')}`;
          return originalRequest;
        });
      });
    }

    // Check if authToken is expired.
    if (isTokenExpired) {
      // Submit refresh token.
      return store.dispatch(submitRefreshToken()).then((token) => {
        originalRequest.headers.common.Authorization = `Bearer ${token}`;
        return originalRequest;
      });
    }

    // Set Auth Token to request header
    originalRequest.headers.common.Authorization = `Bearer ${Cookies.get('accessToken')}`;

    return originalRequest;
  }, err => (
    Promise.reject(err)
  ));
};

authProvider();

// export const submitRefreshToken = () => (
//   async (dispatch) => {
//     const refreshBody = {
//       grant_type: 'refresh_token',
//       client_id: process.env.CLIENT_ID,
//       client_secret: process.env.CLIENT_SECRET,
//       refresh_token: Cookies.get('refreshToken'),
//     };
//     dispatch({ type: SUBMIT_REFRESH_START });
//     try {
//       const data = await axiosLogin.post('oauth/token', encode(refreshBody));
//       const token = await data.access_token;
//       if (token) {
//         console.log(token);
//         dispatch({ type: SUBMIT_REFRESH_FULFILLED });
//         Cookies.set('accessToken', token, {
//           secure: !process.env.ENVIRONMENT === 'DEVELOPMENT' && true,
//           domain: process.env.ENVIRONMENT === 'DEVELOPMENT' ? 'localhost' : '.teambuildr.com',
//         });
//         Cookies.set('refreshToken', token, {
//           secure: !process.env.ENVIRONMENT === 'DEVELOPMENT' && true,
//           domain: process.env.ENVIRONMENT === 'DEVELOPMENT' ? 'localhost' : '.teambuildr.com',
//         });
//         return token;
//       }
//     } catch (err) {
//       dispatch({ type: SUBMIT_REFRESH_REJECTED });
//       console.log(err);
//     }
//     return 'nope';
//   }
// );

// axiosAuthed.interceptors.response.use((response) => {
//   if (response.status === 200) {
//     console.log('You Cool!');
//   }
//   return response;
// }, (err) => {
//   if (err.status === 401) {
//     console.log('You are not authorized!, lets try to get a new token');
//     return store.dispatch(submitRefreshToken())
//       .then((token) => {
//         response.headers.common['Authorization'] = `Bearer ${token}`;

//       })
//   }
// });
