import React, { Fragment, useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring, useChain } from 'react-spring';
import { useSelector, useDispatch } from 'react-redux';
import Logger from 'js-logger';

import { setModalOpen } from './ducks/leaderboardActions';
import Layout from '../../shared/components/Layout/Layout';
import LeaderboardDefaultView from './components/LeaderboardDefaultView';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import Button from '../../shared/components/Button/Button';
import Modal from '../../shared/components/Modal/Modal';
// import PodiumCard from '../../shared/components/PodiumCard/PodiumCard';

// import Bella from '../../shared/images/Bella.png';

import LeaderboardModal from './components/LeaderboardModal';
import useLeaderboard from './components/hooks/useLeaderboard';
import useWindowSize from '../../shared/hooks/useWindowSize';
import Board from './components/Board';

const HeaderRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;

  h1 {
    text-transform: capitalize;
  }
`;

const BoardWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .view-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &.default {
      z-index: ${props => (props.isLeaderboardsEmpty ? 10 : -1)}
    }

    &.boards {
      z-index: ${props => (props.isLeaderboardsEmpty ? -1 : 10)}
    }
  }
`;

const Leaderboard = () => {
  // const {
  //   leaderboards,
  //   handleSetModalOpen,
  // } = useLeaderboard();

  const leaderboards = useSelector(state => state.leaderboard.data.leaderboards);
  const dispatch = useDispatch();
  
  const windowSize = useWindowSize();

  const handleSetModalOpen = (modalOpen, modal, boardId) => {
    dispatch(setModalOpen(modalOpen, modal, boardId));
  };

  const leaderboardsSelector = boards => (
    Object.keys(boards).map(boardKey => boards[boardKey])
  );

  const isLeaderboardsEmpty = leaderboardsSelector(leaderboards).length === 0;

  const defaultViewTransition = useSpring({
    opacity: isLeaderboardsEmpty ? 1 : 0,
  });

  const boardsViewTransition = useSpring({
    opacity: !isLeaderboardsEmpty ? 1 : 0,
  });

  const addBoard = () => {
    handleSetModalOpen(true, 'Add Leaderboard');
  };

  console.log(windowSize.width >= 540);

  return (
    <Layout>
      <PageWrapper
        isLeaderboardsEmpty={isLeaderboardsEmpty}
      >
        {/* <animated.div style={defaultViewTransition} className='view-container default'> */}
        {isLeaderboardsEmpty ? (
          <LeaderboardDefaultView
            addBoard={addBoard}
            leaderboardsSelector={leaderboardsSelector}
          />
        ) : (
          // <animated.div style={boardsViewTransition} className='view-container boards'>
            <Fragment>
              <HeaderRow>
                <HeaderText
                  fontWeight='300'
                  letterSpacing='normal'
                >
                  Leaderboard
                </HeaderText>
                {((leaderboardsSelector(leaderboards).length !== 3) && (windowSize.width >= 540)) && (
                  <Button
                    cta='Add Board'
                    customColor='transparent'
                    onClick={addBoard}
                  />
                )}
              </HeaderRow>
              <BoardWrapper>
                {leaderboardsSelector(leaderboards).map(board => (
                  <Board
                    activeBoards={leaderboardsSelector(leaderboards).length}
                    id={board.id}
                    key={board.id}
                  />
                ))}
              </BoardWrapper>
            </Fragment>
          // </animated.div>
        )}

        {/* </animated.div> */}

        <LeaderboardModal />
      </PageWrapper>
    </Layout>
  );
};

const areEqual = (prevProps, nextProps) => {
  Logger.debug('Previous Leaderboard Props:', prevProps.leaderboards);
  Logger.debug('Next Leaderboard Props:', prevProps.leaderboards);
  return (prevProps.leaderboards === nextProps.leaderboards);
};

export default React.memo(Leaderboard, areEqual);
