import { batch } from 'react-redux';
import moment from 'moment';

import download from 'downloadjs';

import {
  CLOSE_BOARD,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_LEADERBOARD_START,
  FETCH_LEADERBOARD_FULFILLED,
  FETCH_LEADERBOARD_REJECTED,
  EDIT_LEADERBOARD_START,
  EDIT_LEADERBOARD_FULFILLED,
  EDIT_LEADERBOARD_REJECTED,
  FETCH_LEADERBOARD_CSV_START,
  FETCH_LEADERBOARD_CSV_FULFILLED,
  FETCH_NEXT_PAGE_START,
  FETCH_NEXT_PAGE_FULFILLED,
  FETCH_NEXT_PAGE_REJECTED,
  UPDATE_SETTING,
  SET_MODAL_OPEN,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchExercises = (accountCode, exerciseType) => (
  (dispatch) => {
    dispatch({ type: FETCH_EXERCISES_START });
    axiosAuthed.get(`/accounts/${accountCode}/exercises?type=${exerciseType}`)
      .then((response) => {
        dispatch({
          type: FETCH_EXERCISES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EXERCISES_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchGroups = accountCode => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?leaderboard=1`)
      .then((response) => {
        dispatch({
          type: FETCH_GROUPS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_GROUPS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchLeaderboard = (values, actionType, id) => (
  (dispatch, getState) => {
    const {
      accountCode,
      compareTo,
      compareDates,
      exerciseId,
      exerciseName,
      gender,
      groups,
      mustBeInAllGroups,
      rangeType,
      rangeDates,
    } = values;

    const currentPage = actionType !== 'loadMore' ? 1 : getState().leaderboard.data.leaderboards[id].page;
    const page = actionType !== 'loadMore' ? 1 : currentPage + 1;

    const accountString = `/accounts/${accountCode}`;
    const exerciseQuery = `/exercises/${exerciseId}/`;
    const leaderboardQuery = `leaderboard?page=${page}&pageSize=50`;
    const genderQuery = `${gender && `&gender=${gender}`}`;
    const groupsQuery = `${groups.map(group => `&groupIds[]=${group.id}`).join('')}`;
    const allGroups = `${groups.length !== 0 ? `&mustBeInAllGroups=${mustBeInAllGroups}` : ''}`;
    const rangeQuery = `${rangeType ? (`&rangeType=${rangeType}`) : ''}`;
    const rangeMinDate = moment(rangeDates.startDate).format('YYYY-MM-DD');
    const rangeMaxDate = moment(rangeDates.endDate).format('YYYY-MM-DD');
    const rangeDatesQuery = `${(rangeDates && rangeType === 4) ? `&rangeMinDate=${rangeMinDate}&rangeMaxDate=${rangeMaxDate}` : ''}`;
    const compareToQuery = `${compareTo ? (`&compareTo=${compareTo}`) : ''}`;
    const compareMinDate = moment(compareDates.startDate).format('YYYY-MM-DD');
    const compareMaxDate = moment(compareDates.endDate).format('YYYY-MM-DD');
    const compareDatesQuery = `${(compareDates && compareTo === 4) ? `&compareMinDate=${compareMinDate}&compareMaxDate=${compareMaxDate}` : ''}`;
    const downloadQuery = `${actionType === 'download' ? '&format=csv' : ''}`;

    // Default ID parameter if undefined in Add Board,
    // otherwise use namespace used in Edit Board.
    id = (id !== undefined) ? id : 0;

    if (actionType === 'add') {
      dispatch({ type: FETCH_LEADERBOARD_START });
    }
    if (actionType === 'edit') {
      dispatch({ type: EDIT_LEADERBOARD_START });
    }
    if (actionType === 'download') {
      dispatch({ type: FETCH_LEADERBOARD_CSV_START });
    }
    if (actionType === 'loadMore') {
      dispatch({
        type: FETCH_NEXT_PAGE_START,
        payload: {
          id,
        },
      });
    }

    return axiosAuthed.get(`${accountString}${exerciseQuery}${leaderboardQuery}${genderQuery}${groupsQuery}${allGroups}${rangeQuery}${rangeDatesQuery}${compareToQuery}${compareDatesQuery}${downloadQuery}`)
      .then((response) => {
        if (actionType === 'add') {
          dispatch({
            type: FETCH_LEADERBOARD_FULFILLED,
            payload: {
              data: response.data,
              exerciseName,
              values,
            },
          });
        }
        if (actionType === 'edit') {
          dispatch({
            type: EDIT_LEADERBOARD_FULFILLED,
            payload: {
              id,
              data: response.data,
              exerciseName,
              values,
            },
          });
        }
        if (actionType === 'download') {
          dispatch({
            type: FETCH_LEADERBOARD_CSV_FULFILLED,
            payload: response.data,
          });
          download(response.data, `Teambuildr ${exerciseName} Leaderboard.csv`, 'text/csv');
        }
        if (actionType === 'loadMore') {
          setTimeout(() => {
            dispatch({
              type: FETCH_NEXT_PAGE_FULFILLED,
              payload: {
                id,
                data: response.data,
                exerciseName,
                page: response.data.length === 0 ? currentPage : page,
              },
            });
          }, 800);
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_LEADERBOARD_REJECTED,
          payload: err,
        });
      });
  }
);

export const closeBoard = id => (
  (dispatch) => {
    batch(() => {
      dispatch({
        type: CLOSE_BOARD,
        payload: {
          id,
        },
      });
      dispatch({ type: CLOSE_BOARD });
    });
  }
);

export const updateSetting = (id, key, value) => ({
  type: UPDATE_SETTING,
  payload: {
    id,
    setting: key,
    value,
  },
});

export const setModalOpen = (isModalOpen, activeModal, activeBoardId) => ({
  type: SET_MODAL_OPEN,
  payload: {
    isModalOpen,
    activeModal,
    activeBoardId: !activeBoardId ? 0 : activeBoardId,
  },
});
