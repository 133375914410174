import React, { Fragment, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Form } from 'formik';
import { animated, useTransition } from 'react-spring';

import useLeaderboard from './hooks/useLeaderboard';

import FormHandler from '../../../shared/components/FormHandler/FormHandler';
import Button from '../../../shared/components/Button/Button';
import BoardFormFirstSlide from './BoardFormFirstSlide';
import BoardFormSecondSlide from './BoardFormSecondSlide';
import { TransitionGlobal } from '../../../shared/GlobalStyles';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${props => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 30px;
  margin: 0 auto;
  margin-top: 30px;
`;

const NavCircle = styled('div')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const FormAddBoard = ({
  closeModal,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();

  const {
    currentUser,
    exercises,
    groups,
    handleFetchExercises,
    handleFetchLeaderboard,
    isLoadingExercises,
    isLoadingGroups,
  } = useLeaderboard();

  const { accountCode } = currentUser;

  // Retrieve height from absolute positioned slide component with slideRef,
  // and store it in slideDimensions state object for later use.
  useLayoutEffect(() => {
    if (slideRef.current) {
      setSlideDimensions({
        height: slideRef.current.clientHeight,
        width: slideRef.current.clientWidth,
      });
    }
  }, [activeSlide]);

  // Initialize slide transition animation.
  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(-25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(25%, 0, 0)' },
  });

  const fetchExercises = (exerciseType) => {
    handleFetchExercises(accountCode, exerciseType);
  };

  return (
    <Fragment>
      <FormHandler
        initialValues={
          {
            accountCode,
            category: '',
            compareTo: 0,
            compareDates: {},
            exerciseId: 0,
            exerciseName: '',
            groups: [],
            mustBeInAllGroups: false,
            gender: '',
            rangeType: 0,
            rangeDates: {},
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleFetchLeaderboard(values, 'add');
            closeModal();
          });
        }}
      >
        {/* Import formik props as an argument in a function. */}
        {formProps => (
          <Form>
            {/* Form Slider container that dynamically changes height
                based on height calculated in slideDimensions. */}
            <SlideContainer
              height={slideDimensions.height}
            >
              {/* Slider transition that mounts the active slide
                  based on the state object, activeSlide. */}
              {slideTransition.map(({ item, props }) => (
                item === 0 ? (
                  <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                    <BoardFormFirstSlide
                      isExercisesDisabled={exercises.length === 0}
                      isGroupsDisabled={groups.length === 0}
                      exercises={exercises}
                      groups={groups}
                      fetchExercises={fetchExercises}
                      formProps={formProps}
                      isLoadingExercises={isLoadingExercises}
                      isLoadingGroups={isLoadingGroups}
                    />
                  </animated.div>
                ) : (
                  <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                    <BoardFormSecondSlide formProps={formProps} />
                  </animated.div>
                )))
              }
            </SlideContainer>

            <SliderNav>
              <NavCircle
                className={activeSlide === 0 ? 'active' : ''}
                onClick={() => {
                  const defaultOption = exercises.filter(exercise => exercise.id === formProps.values.exercise);
                  console.log('yo', { ...defaultOption[0] });
                  setActiveSlide(0);
                }}
              />
              <NavCircle
                className={activeSlide === 1 ? 'active' : ''}
                onClick={() => setActiveSlide(1)}
              />
            </SliderNav>

            {activeSlide !== 0 && (
              <Button
                cta='Add Board'
                type='submit'
                fullWidth
                noBorder
                large
                square
                primary
                bottom
              />
            )}
          </Form>
        )}
      </FormHandler>
      {activeSlide === 0 && (
        <Button
          onClick={() => setActiveSlide(1)}
          cta='next'
          type='button'
          fullWidth
          noBorder
          large
          square
          primary
          bottom
        />
      )}
    </Fragment>
  );
};

FormAddBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default FormAddBoard;
