import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import { Gutter } from '../GlobalStyles';
import HeaderText from './HeaderText/HeaderText';
import Button from './Button/Button';
import useLayout from './Layout/hooks/useLayout';
import Text from './Text/Text';

const ModalWrapper = styled('div')`
  display: block;
`;

export const ModalContentContainer = styled('div')`
  padding: 40px;
  flex-grow: 1;
  background-color: ${props => props.theme.colors.background[4]};
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .form-group {
    padding: ${Gutter} calc(${Gutter} * 3);
  }
  .form-control {
    min-height: 150px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    .form-group {
      padding: 20px 15px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
  @media only screen and (max-device-width: 767px) {
    padding: 15px;
  }
`;

const ModalTitle = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: ${props => props.theme.colorPrimary};
`;

const AccountContainer = styled('div')`
  display: flex;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  align-items: center;
  background-color: ${props => props.theme.colors.background[0]};
  padding: 10px;
  box-shadow: ${props => props.theme.colors.boxShadow};
  
  button {
    justify-self: flex-end;
    margin-left: auto;
  }

  p {
    margin-bottom: 0px;
    margin-left: 20px;
  }
  @media only screen and (max-device-width: 767px) {
    p {
      font-size: 11pt;
    }
  }
`;

const AccountImageContainer = styled('div')`
  display: flex;
  width: 55px;
  height: 55px;
  background-color: ${props => props.background};
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
  }
`;

const ModalAccountSwitch = ({
  organizationId,
}) => {
  const theme = useTheme();
  const { isAccountLoading, currentUserAccounts, handleAccountSwitch } = useLayout();

  const switchAccount = (accountCode) => {
    handleAccountSwitch(accountCode);
  };

  return (
    <ModalWrapper>
      <ModalTitle>
        <HeaderText
          fontSize={theme.headerFontSizes[3]}
          color={theme.isPrimaryDarkTheme ? '#444444' : 'white'}
        >
          Switch Account
        </HeaderText>
      </ModalTitle>
      <ModalContentContainer>
        {currentUserAccounts.map((account, index) => (
          <AccountContainer>
            <AccountImageContainer
              background={account.primaryColor}
            >
              <img src={account.logo} alt={account.name} />
            </AccountImageContainer>
            <Text
              fontSize={theme.textFontSizes[2]}
              fontWeight='600'
            >
              {account.name}
            </Text>
            <Button
              cta={account.id === organizationId ? 'current' : 'switch'}
              onClick={() => (switchAccount(account.id))}
              complete={account.id === organizationId}
            />
          </AccountContainer>
        ))}
      </ModalContentContainer>
    </ModalWrapper>
  );
};

export default ModalAccountSwitch;
