import update from 'immutability-helper';

import {
  FETCH_DOCUMENTS_FULFILLED,
  FETCH_DOCUMENTS_REJECTED,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_FOLDER_FULFILLED,
  FETCH_FOLDER_REJECTED,
  FETCH_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_SEARCH_DOCUMENTS_REJECTED,
  CREATE_DOCUMENT_FULFILLED,
  CREATE_DOCUMENT_REJECTED,
  UPLOAD_DOCUMENT_FULFILLED,
  UPLOAD_DOCUMENT_REJECTED,
  DELETE_DOCUMENTS_FULFILLED,
  DELETE_DOCUMENTS_REJECTED,
  UPDATE_DOCUMENT_FULFILLED,
  UPDATE_DOCUMENT_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  FETCH_FILE_FULFILLED,
  FETCH_FILE_REJECTED,
  FETCH_FOLDERS_FULFILLED,
  FETCH_FOLDERS_REJECTED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED,
} from './actionTypes';

const initialState = {
  documents: [],
  folderDocuments: [],
  documentsError: '',
  uploadDocument: '',
  fetchDocumentError: '',
  folders: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case FETCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_DOCUMENT_FULFILLED: {
      return {
        ...state,
        document: action.payload,
      };
    }
    case FETCH_DOCUMENT_REJECTED: {
      return {
        ...state,
        fetchDocumentError: action.payload,
      };
    }
    case FETCH_FOLDER_FULFILLED: {
      return {
        ...state,
        folderDocuments: action.payload,
      };
    }
    case FETCH_FOLDER_REJECTED: {
      return {
        ...state,
        folderError: action.payload,
      };
    }
    case FETCH_SEARCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case FETCH_SEARCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case CREATE_DOCUMENT_FULFILLED: {
      let returnOb;
      if (action.payload.parentFolderId) {
        const currentFolderLevelDocs = state.folderDocuments;
        // I have no clue why, but taking out the below filter function
        // causes the auto merge to stop working.  So here it stays.
        const newFolderLevelDocs = currentFolderLevelDocs.filter(doc => action.payload.id !== doc.id);
        newFolderLevelDocs.push(action.payload);
        newFolderLevelDocs.sort((a, b) => a.name.localeCompare(b.name));
        returnOb = update(
          state, {
            folderDocuments: {
              $set: newFolderLevelDocs,
            },
          },
        );
      } else {
        const currentTopLevelDocs = state.documents;
        // I have no clue why, but taking out the below filter function
        // causes the auto merge to stop working.  So here it stays.
        const newTopLevelDocs = currentTopLevelDocs.filter(doc => action.payload.id !== doc.id);
        newTopLevelDocs.push(action.payload);
        newTopLevelDocs.sort((a, b) => a.name.localeCompare(b.name));
        if (action.payload.type === 0) {
          returnOb = update(
            state, {
              documents: {
                $set: newTopLevelDocs,
              },
              folders: {
                $push: [action.payload],
              },
            },
          );
        } else {
          returnOb = update(
            state, {
              documents: {
                $set: newTopLevelDocs,
              },
            },
          );
        }
      }
      return returnOb;
    }
    case CREATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case UPLOAD_DOCUMENT_FULFILLED: {
      return {
        ...state,
        uploadDocument: action.payload,
        uploadDocumentError: '',
      };
    }
    case UPLOAD_DOCUMENT_REJECTED: {
      return {
        ...state,
        uploadDocumentError: action.payload,
      };
    }
    case DELETE_DOCUMENTS_FULFILLED: {
      const currentTopLevelDocs = state.documents;
      const newTopLevelDocs = currentTopLevelDocs.filter(doc => !action.payload.successful.includes(doc.id));
      const currentFolderLevelDocs = state.folderDocuments;
      const newFolderLevelDocs = currentFolderLevelDocs.filter(doc => !action.payload.successful.includes(doc.id));
      const currentFolders = state.folders;
      const newFolders = currentFolders.filter(doc => !action.payload.successful.includes(doc.id));
      return update(
        state, {
          documents: {
            $set: newTopLevelDocs,
          },
          folderDocuments: {
            $set: newFolderLevelDocs,
          },
          folders: {
            $set: newFolders,
          },
        },
      );
    }
    case DELETE_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case UPDATE_DOCUMENT_FULFILLED: {
      const currentTopLevelDocs = state.documents;

      const newTopLevelDocs = [];
      currentTopLevelDocs.forEach((doc) => {
        if (doc.id !== action.payload.id) {
          newTopLevelDocs.push(doc);
        }
      });

      if (action.payload.parentFolderId === 0) {
        newTopLevelDocs.push(action.payload);
      }

      newTopLevelDocs.sort((a, b) => a.name.localeCompare(b.name));

      const currentFolderLevelDocs = state.folderDocuments;

      const newFolderLevelDocs = currentFolderLevelDocs.filter(doc => doc.id !== action.payload.id);
      if (action.payload.parentFolderId) {
        newFolderLevelDocs.push(action.payload);
      }
      newFolderLevelDocs.sort((a, b) => a.name.localeCompare(b.name));

      let folderTrue = false;
      let newFolders;
      if (action.payload.type === 0) {
        const currentFolders = state.folders;
        newFolders = currentFolders.filter(doc => doc.id !== action.payload.id);
        newFolders.push(action.payload);
        folderTrue = true;
      }

      let updateOb;
      if (folderTrue) {
        updateOb = update(
          state, {
            documents: {
              $set: newTopLevelDocs,
            },
            folderDocuments: {
              $set: newFolderLevelDocs,
            },
            folders: {
              $set: newFolders,
            }
          },
        );
      } else {
        updateOb = update(
          state, {
            documents: {
              $set: newTopLevelDocs,
            },
            folderDocuments: {
              $set: newFolderLevelDocs,
            },
          },
        );
      }

      return updateOb;
    }
    case UPDATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FILE_FULFILLED: {
      return {
        ...state,
        file: action.payload,
      };
    }
    case FETCH_FILE_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FOLDERS_FULFILLED: {
      return {
        ...state,
        folders: action.payload,
      };
    }
    case FETCH_FOLDERS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        folderDocuments: action.payload,
      };
    }
    case FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
