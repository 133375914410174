export const SUBMIT_LOGIN_START = 'SUBMIT_LOGIN_START';
export const SUBMIT_LOGIN_FULFILLED = 'SUBMIT_LOGIN_FULFILLED';
export const SUBMIT_LOGIN_REJECTED = 'SUBMIT_LOGIN_REJECTED';

export const SUBMIT_LOGOUT_START = 'SUBMIT_LOGOUT_START';
export const SUBMIT_LOGOUT_FULFILLED = 'SUBMIT_LOGOUT_FULFILLED';
export const SUBMIT_LOGOUT_REJECTED = 'SUBMIT_LOGOUT_REJECTED';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED';

export const FETCH_V2USER_START = 'FETCH_V2USER_START';
export const FETCH_V2USER_FULFILLED = 'FETCH_V2USER_FULFILLED';
export const FETCH_V2USER_REJECTED = 'FETCH_V2USER_REJECTED';

export const SUBMIT_REFRESH_START = 'SUBMIT_REFRESH_START';
export const SUBMIT_REFRESH_FULFILLED = 'SUBMIT_REFRESH_FULFILLED';
export const SUBMIT_REFRESH_REJECTED = 'SUBMIT_REFRESH_REJECTED';

export const SUBMIT_GOOGLE_START = 'SUBMIT_GOOGLE_START';
export const SUBMIT_GOOGLE_FULFILLED = 'SUBMIT_GOOGLE_FULFILLED';
export const SUBMIT_GOOGLE_REJECTED = 'SUBMIT_GOOGLE_REJECTED';

export const SUBMIT_V2EXCHANGE_START = 'SUBMIT_V2EXCHANGE_START';
export const SUBMIT_V2EXCHANGE_FULFILLED = 'SUBMIT_V2EXCHANGE_FULFILLED';
export const SUBMIT_V2EXCHANGE_REJECTED = 'SUBMIT_V2EXCHANGE_REJECTED';

export const CLEAR_USER = 'CLEAR_USER';
